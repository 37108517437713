import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../state/hooks";
import { handleMissingMetamask } from "../../../networking/user";
import { updateModalState } from "../../../state/reducers/modal/modalReducer";
import { MODAL_TYPE } from "../../../models/modal";
import { login } from "../../../networking/user";
import { logout } from "../../../networking/user";

import { faUser } from "@fortawesome/pro-regular-svg-icons"

import LucidAILogoLarge from "../../logo/lucidAILargeLogo";
import TokenomicsCardSection from "../tokenomicsCards";
import PresaleTokenChart from "./presaleTokenChart";
import { getAddress } from "../../../networking/user/authentication/ipVerification";
import HandleAlert, { errorAlert } from "../../../utils/handleAlert";
import { purchaseSlot } from "../../../networking/presale/presalePurchase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";

import { useEffect } from "react";
import { PresalePurchasingStatus } from "../../../state/reducers/presale/presaleReducer";
import { validatePresaleDataForUser } from "../../../networking/presale/presaleData";

export enum PRESALE_SLOT {
    ONE="ONE",
    TWO="TWO",
    THREE="THREE"
}

export default function TokenPresaleHome() {
    const networkState = useAppSelector(state => state.network);
    const presaleState = useAppSelector(state => state.presaleState);
    
    const dispatch = useDispatch();

    // initial load
    useEffect(() => {
        async function fetchPresaleUserData() {
            if (!presaleState.initialLoad && networkState.selectedAccount !== "") {
                await validatePresaleDataForUser(networkState, dispatch);
            }
        }
        fetchPresaleUserData();
    }, [dispatch, networkState])

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    return (
        <div className="bg-space-gray mx-auto max-w-8xl md:flex min-h-screen">
            <div className="w-full">
                <Header/>
                <PruchaseSection/>
                <TokenomicsHeader/>
                <TokenomicsCardSection/>
            </div>
        </div>
    )

    function Header() {
        return (
            <div className="flex w-full justify-center items-center">
                <div className="w-full">
                    <div>
                        {LucidAILogoLarge(isFirefox)}
                    </div>
                    <div className="flex w-full justify-center items-center my-2">
                        <h1 className="font-secondary text-xl md:text-2xl text-white">Official Token Presale</h1>
                    </div>
                    <div className="flex w-full justify-center items-center my-2">
                        <h1 className="font-third text-sm md:text-md text-white">DESKTOP USERS ONLY - NO REFUNDS - FINAL</h1>
                    </div>
                    {
                        presaleState.initialLoad ? 
                        ( 
                        <div className="flex w-full justify-center items-center my-2">
                            <h1 className="font-third text-sm md:text-md text-white">REMAINING TOKENS AVAILABLE: </h1>
                            <h1 className="font-secondary text-animate font-secondary bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black"> {presaleState.totalRemainingAmount}</h1>
                        </div> 
                        ) 
                    : ( 
                        <div className="flex w-full justify-center items-center my-2">
                            <h1 className="font-third text-sm md:text-md text-white">REMAINING TOKENS AVAILABLE: sign in first</h1>
                        </div>
                    )
                    }  
                </div>
            </div>
        )
    }

    function TokenomicsHeader() {
        return (
            <div className="flex w-full justify-center items-center mt-4">
                <h1 className="font-secondary text-lg text-white">Tokenomics on Launch</h1>
            </div>
        )
    }

    function PruchaseSection() {
        return (
            <div className="flex bg-space-gray w-full justify-center md:justify-start items-center">
                <div className="w-full">
                    <div className="flex justify-center item-center my-6">
                        <div className="relative">
                            <div className="absolute top-0 left-0 h-full w-full rounded-full border-4 border-white">
                                <PresaleChartDataSection/>
                            </div>
                            <div className="h-64 w-64">
                                <PresaleTokenChart/>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-center my-4">
                        <PresaleStatusMessage/>
                    </div>
                    <div className="flex w-full items-center justify-center">
                        <PresaleButtonFlow/>
                    </div>
                </div>
            </div>
        )
    }

    function PresaleChartDataSection() {
        if (networkState.selectedAccount === "") {
            return (
                <div className="flex items-center justify-center w-full h-full">
                    <h1 className="text-white text-2xl font-secondary">Sign In</h1>
                </div>
            )
        } else if (!presaleState.initialLoad) {
            return (
                <LoadingPanel/>
            )
        } else {
            return (
                <div className="flex items-center justify-center w-full h-full">
                    <h1 className="text-white text-2xl font-secondary mr-2 mt-4">{presaleState.totalParticipants}</h1>
                    <FontAwesomeIcon icon={faUser} color='white' size='2x'/>
                </div>
            )
        }
    }

    function PresaleButtonFlow() {
        if (networkState.jwtToken !== "") {
            if (presaleState.initialLoad && presaleState.presaleStatus.status === PresalePurchasingStatus.STANDBY) {
                return (
                    <PurchaseButtons/>
                )
            } else if (presaleState.initialLoad && presaleState.presaleStatus.status === PresalePurchasingStatus.ALREADY_PARTICIPATED) {
                if (presaleState.airdroptokenAmount > 0) {
                    return (
                        <h1 className="text-center text-animate font-secondary bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-md sm:text-lg">YOUR AIRDROP AMOUNT: {presaleState.airdroptokenAmount}</h1>
                    )
                } else {
                    return (
                        <></>
                    )
                }
            } else {
                return (
                    <LoadingPanel/>
                )
            }
        } else {
            return (
                <SignInButton/>
            )
        }
    }

    function PurchaseButtons() {
        return (
            <div className='flex w-full justify-center items-center'>
                <div className="lg:flex justify-center items-center">
                    <div className="w-32 mx-2">
                        <button onClick={() => handlePurchase(PRESALE_SLOT.ONE)} className="w-full hover:cursor-pointer bg-gradient-to-r
                            from-white via-purple-300 to-teal-300 hover:from-green-500 hover:via-teal-300 hover:to-blue-500
                            background-animate p-[2px] rounded-lg">
                            <div className="flex items-center justify-center bg-black w-full h-full rounded-lg">
                                <div className="rounded-lg">
                                    <h1 className="bg-black text-white py-2 px-2 rounded-lg text-lg font-secondary">0.5% Supply</h1>
                                </div>
                            </div>
                        </button>
                        <div className="flex justify-center items-center w-full rounded-lg my-2">
                            <div className="flex justify-center items-center rounded-lg py-3 px-2 bg-black">
                                <h1 className="text-white font-third text-md mr-1">0.05</h1>
                                <FontAwesomeIcon icon={faEthereum} color='white' size="lg"/>
                                <h1 className="text-white font-third text-md ml-1">+ gas</h1>
                            </div>
                        </div>
                    </div>
                    <div className="w-32 mx-2">
                        <button onClick={() => handlePurchase(PRESALE_SLOT.TWO)} className="w-full hover:cursor-pointer bg-gradient-to-r
                            from-white via-purple-300 to-teal-300 hover:from-green-500 hover:via-teal-300 hover:to-blue-500
                            background-animate p-[2px] rounded-lg">
                            <div className="flex items-center justify-center bg-black w-full h-full rounded-lg">
                                <div className="rounded-lg">
                                    <h1 className="bg-black text-white py-2 px-2 rounded-lg text-lg font-secondary">1.0% Supply</h1>
                                </div>
                            </div>
                        </button>
                        <div className="flex justify-center items-center w-full rounded-lg my-2">
                            <div className="flex justify-center items-center rounded-lg py-3 px-2 bg-black">
                                <h1 className="text-white font-third text-md mr-1">0.1</h1>
                                <FontAwesomeIcon icon={faEthereum} color='white' size="lg"/>
                                <h1 className="text-white font-third text-md ml-1">+ gas</h1>
                            </div>
                        </div>
                    </div>
                    <div className="w-32 mx-2">
                        <button onClick={() => handlePurchase(PRESALE_SLOT.THREE)} className="w-full hover:cursor-pointer bg-gradient-to-r
                            from-white via-purple-300 to-teal-300 hover:from-green-500 hover:via-teal-300 hover:to-blue-500
                            background-animate p-[2px] rounded-lg">
                            <div className="flex items-center justify-center bg-black w-full h-full rounded-lg">
                                <div className="rounded-lg">
                                    <h1 className="bg-black text-white py-2 px-2 rounded-lg text-lg font-secondary">2.0% Supply</h1>
                                </div>
                            </div>
                        </button>
                        <div className="flex justify-center items-center w-full rounded-lg my-2">
                            <div className="flex justify-center items-center rounded-lg py-3 px-2 bg-black">
                                <h1 className="text-white font-third text-md mr-1">0.2</h1>
                                <FontAwesomeIcon icon={faEthereum} color='white' size="lg"/>
                                <h1 className="text-white font-third text-md ml-1">+ gas</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function SignInButton() {
        return (
            <button onClick={() => handleSignInClick()} className="hover:cursor-pointer bg-gradient-to-r
                from-white via-purple-300 to-teal-300 hover:from-green-500 hover:via-teal-300 hover:to-blue-500
                background-animate p-[2px] rounded-lg">
                <div className="flex items-center justify-center bg-black w-full h-full rounded-lg">
                    <div className="rounded-lg">
                        <h1 className="bg-black text-white py-2 px-4 rounded-lg text-lg font-secondary">Sign In To Join</h1>
                    </div>
                </div>
            </button>
        )
    }

    function handleSignInClick() {
        // @ts-ignore
        if (window.ethereum === undefined) {
            handleMissingMetamask(dispatch);
        // @ts-ignore
        } else if (window.ethereum !== undefined && networkState.selectedAccount === "") {
            dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: true}));
        } else {
            logout(dispatch);
        }
      }

    async function handlePurchase(presaleSlot: PRESALE_SLOT) {
        if (presaleSlot === PRESALE_SLOT.ONE && presaleState.totalRemainingAmount < 5000000) {
            HandleAlert(errorAlert("Slot is unavailable. Check remaining available tokens."), dispatch);
            return;
        }

        if (presaleSlot === PRESALE_SLOT.TWO && presaleState.totalRemainingAmount < 10000000) {
            HandleAlert(errorAlert("Slot is unavailable."), dispatch);
            return;
        }

        if (presaleSlot === PRESALE_SLOT.THREE && presaleState.totalRemainingAmount < 20000000) {
            HandleAlert(errorAlert("Slot is unavailable."), dispatch);
            return;
        }

        let user = await getAddress();
        if (user) {
            await purchaseSlot(presaleSlot, user, networkState, dispatch);
        } else {
            HandleAlert(errorAlert("Issue verifying user. Refresh, log in, try again."), dispatch);
        }
    }

    function LoadingPanel() {
        return (
            <div className="flex w-full h-full justify-center items-center">
                <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                    <div className="h-12 w-12 rounded-full bg-space-gray"></div>
                </div>
            </div> 
        )
    }

    function PresaleStatusMessage() {
        if (presaleState.presaleStatus.status === PresalePurchasingStatus.STANDBY) {
            return (
                <h1 className="text-white text-center font-third text-sm md:text-md">ONE SLOT PER USER</h1>
            )
        }
        if (presaleState.presaleStatus.status === PresalePurchasingStatus.ESTABLISHING_CONNECTION) {
            return (
                <h1 className="text-white text-center font-third text-sm md:text-md">ESTABLISHING WEB3 CONNECTION</h1>
            )
        }
        if (presaleState.presaleStatus.status === PresalePurchasingStatus.ESTIMATING_PURCHASE_GAS) {
            return (
                <h1 className="text-white text-center font-third text-sm md:text-md">ESTIMATING GAS</h1>
            )
        }
        if (presaleState.presaleStatus.status === PresalePurchasingStatus.AWAITING_PURCHASE_CONFIRMTION) {
            return (
                <h1 className="text-white text-center font-third text-sm md:text-md">AWAITING PURCHASE CONFIRMATION - SEE WALLET EXTENSION</h1>
            )
        }
        if (presaleState.presaleStatus.status === PresalePurchasingStatus.AWAITING_PURCHASE_RECEIPT) {
            return (
                <h1 className="text-white text-center font-third text-sm md:text-md">AWAITING TRANSACTION RECEIPT</h1>
            )
        }
        if (presaleState.presaleStatus.status === PresalePurchasingStatus.ERROR) {
            return (
                <div>
                    <h1 className="text-white text-center font-third text-sm md:text-md">ERROR:</h1>
                    <h1 className="text-center font-third text-sm md:text-md text-red-500 mx-4">{presaleState.presaleStatus.errorMessage}</h1>
                </div>
            )
        }
        if (presaleState.presaleStatus.status === PresalePurchasingStatus.ALREADY_PARTICIPATED) {
            return (
                <div>
                    <h1 className="text-white text-center font-third text-sm md:text-md">YOU HAVE ALREADY PURCHASED A SLOT</h1>
                    <h1 className="text-center font-third text-sm md:text-md text-red-500 mx-4">USING A DIFFERENT WALLET ADDRESSES / ACCOUNT DOES NOT MATTER</h1>
                </div>
            )
        }
    }
}