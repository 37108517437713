import { useAppSelector } from "../../../state/hooks";
import ProfileLinkSection from "./profileLink";
import { CHAIN_ID } from "../../../models";

function ProfileHeader() {
    
    const networkState = useAppSelector(state => state.network);
    const profileCollectionState = useAppSelector(state => state.profileCollection);
    const address = networkState.selectedAccount.substring(0,6) + "..." + networkState.selectedAccount.substring(networkState.selectedAccount.length - 5,networkState.selectedAccount.length);
    const scannerUrl = (networkState.selectedChain === CHAIN_ID.MATIC ? "https://polygonscan.com/address/" : "https://etherscan.io/address/") + networkState.selectedAccount;

    return (
        <div className="max-w-8xl mx-auto bg-space-gray">
            <HeaderText/>
            <div className="md:flex w-full p-4">
                <div className="md:flex w-full justify-center items-center">
                    <div>
                        <ProfileLinkSection {...profileCollectionState}/>
                    </div>
                </div>
            </div>
        </div>
    )

    function HeaderText() {
        return (
            <>
                <div className="flex pb-4 pt-8 w-full justify-center items-center">
                    <h1 className="font-primary font-bold text-4xl md:text-6xl
                    text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">PROFILE</h1>
                </div>
                <div className="flex py-2 justify-center items-center hover:cursor">
                    <a href={scannerUrl} target="_" className="text-white font-primary font-bold text-1xl">{address}</a>
                </div>
            </>
        )
    }
}

export default ProfileHeader;