import axios from "axios";
import { TierLevel } from "../../models/tier/tier";
import { updateTierCommunityLevels } from "../../state/reducers/tier/tierReducer";

const TIER_INFO_ROUTE = "https://webhook.lucidai.art/tiers";

export async function fetchAndUpdateTiers(dispatch: any) {

    let communityTierLevels: TierLevel[] = []
    let response = null;
    try {
        response = await axios.get(TIER_INFO_ROUTE);
    } catch (error) {
        console.log("Error fetching tiers: " + error);
        return;
    }

    if (response === null) {
        return;
    }

    communityTierLevels = parseCommunityTierLevels(response.data.community);
    dispatch(updateTierCommunityLevels({communnityTierLevels: communityTierLevels}));
}

function parseCommunityTierLevels(tierLevelJson: any): TierLevel[] {
    let results: TierLevel[] = []
    for (const tier of tierLevelJson) {
        if (tier) {
            results.push({
                name: tier.name,
                price: tier.price,
                months: tier.months,
                generations: tier.generations
            })
        }
    }
    return results;
}

