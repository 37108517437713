import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PromptTag from "../../models/art/promptTag";
import { useAppSelector } from "../../state/hooks";
import { updateIsSlidingPromptState, updateNegativePromptTagStrength, updatePromptTagStrength } from "../../state/reducers/artCreation/artCreationFilterReducer";
import { TagBoxType } from "../../state/reducers/tag/tagSearchReducer";


function RangeSlider(props: PromptTag) {
    
    const currentPromptState = useAppSelector(state => state.artCreationFilter);
    
    const [rangeval, setRangeval] = useState(props.strength);
    const dispatch = useDispatch();
    
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {  
        setRangeval(parseFloat(event.target.value));
    }

    function handlePress() {
        dispatch(updateIsSlidingPromptState(true));
    }

    function handleChangeEnd() {
        if (props.type === TagBoxType.PROMPT) {
          dispatch(updatePromptTagStrength({id: props.id, strength: rangeval}))
          dispatch(updateIsSlidingPromptState(false));
        } else {
          dispatch(updateNegativePromptTagStrength({id: props.id, strength: rangeval}))
          dispatch(updateIsSlidingPromptState(false));
        }
    }

    return (
      <div>
        <input 
            type="range" 
            className="w-full font-third accent-gray-300"
            value={currentPromptState.isSliding ? rangeval : getPromptTagDispalyStrength()} 
            min={0.8}
            max={2.0}
            step={0.1}
            onPointerDown={handlePress}
            onPointerUp={handleChangeEnd}
            onChange={handleChange}      
         />
        <h4>The range value is {rangeval}</h4>
      </div>
    );

    function getPromptTagDispalyStrength() {
      if (props.type === TagBoxType.PROMPT) {
        return currentPromptState.prompt.find((tag => tag.id === props.id))?.strength
      } else {
        return currentPromptState.negativePrompt.find((tag => tag.id === props.id))?.strength
      }
    }
  };
  
  export default RangeSlider;