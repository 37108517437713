import { createSlice } from "@reduxjs/toolkit";

export enum ART_TOOL {
    ERASER,
    BRUSH
}

export interface ArtCreationCanvasReducer {
    lines: any[],
    toolInUse: ART_TOOL
}

// keep track of canvas user interations & state
export const initialCanvasState: ArtCreationCanvasReducer = {
    lines: [],
    toolInUse: ART_TOOL.BRUSH
}

const artCreationCanvasSlice = createSlice({
    name: "artCreationFilters",
    initialState: initialCanvasState,
    reducers: {
        setLines: (state, action) => {
            state.lines = action.payload
        },
        handleMouseMoveState: (state, action) => {
            let lastLine: any = state.lines[state.lines.length - 1];
            if(lastLine) {
                lastLine.points = lastLine.points.concat([action.payload.point.x, action.payload.point.y]);
                state.lines.splice(state.lines.length - 1, 1, lastLine);
                state.lines.concat();
            }
        },
        clearLines: (state) => {
            state.lines = [];
        },
        undoLine: (state) => {
            state.lines.splice(-1);
        }
    }
});

export default artCreationCanvasSlice.reducer;
export const { 
    setLines,
    handleMouseMoveState,
    undoLine,
    clearLines
} = artCreationCanvasSlice.actions;