export enum MODEL_VISIBILITY {
    PRIVATE,
    PUBLIC,
    BASE,
}

export enum MODEL_TYPE {
    BASE,
    LORA
}

export enum GENERATIVE_TYPE {
    IMAGE,
    LLM,
    VIDEO
}

export interface ArtModel {
    owner: string,
    name:string,
    ticker: string,
    nsfw: boolean,
    description: string,
    imageUrl: string,
    communityUrl: string,
    telegramUrl: string,
    dextoolsUrl: string,
    modelVisibility: MODEL_VISIBILITY,
    modelType: MODEL_TYPE
    generativeType: GENERATIVE_TYPE
}