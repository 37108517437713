import { useDispatch } from "react-redux";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { useAppSelector } from "../../state/hooks";
import removeChannel from "../../networking/user/channel/removeChannelRegistration";

function ChannelConfirmationModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const networkState = useAppSelector(state => state.network);
    const profileState = useAppSelector(state => state.profileCollection);
    
    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.REMOVE_CHANNEL} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-8 md:mx-16 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div>
                                    <div className="flex justify-center items-center w-full">
                                        <h1 className="text-white text-lg md:text-xl text-center font-third p-8">{getDisplayText()}</h1>
                                    </div>
                                    <div className="flex justify-center items-center w-full">
                                        <div className="">
                                            <RemoveChannelView/>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function getDisplayText() {
        return "Remove Channel Registration? Channel: " + modalState.selectedChannel.name
    }

    function RemoveChannelView() {
        return (
            <div className="flex items-center justify-center p-4">
                <button onClick={() => removeChannelHelper()} 
                className="text-white rounded-lg bg-black border-2 border-white text-lg md:text-xl
                focus:outline-none focus:ring-0 px-2 py-1">
                    Yes
                </button>
            </div>
        )
    }

    async function removeChannelHelper() {
        await removeChannel(modalState.selectedChannel, profileState.linkedTelegramUsername, networkState, dispatch);
    }

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.REMOVE_CHANNEL, show: false}));
    }
}

export default ChannelConfirmationModal;