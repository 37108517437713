import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../state/hooks";
import { toggleSelectedModelTab } from "../../../../state/reducers/user/userProfileReducer";
import { MODEL_VISIBILITY } from "../../../../models/art/artModel";

import BaseModelSection from "./modelSections/baseModelSection";
import PrivateModelSection from "./modelSections/privateModelSection";
import CommunityModelSection from "./modelSections/communityModelSection";


export default function ProfileModelSection() {

    const profileState = useAppSelector(state => state.profileCollection);
    const dispatch = useDispatch();

    return (
        <>
            <div className="md:flex pt-2 md:pt-5 md:pb-2 max-w-8xl">
                <div className="flex justify-center items-center md:justify-start md:mr-2">
                    <h1 className="text-2xl text-center md:text-left font-secondary 
                    text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">3. Manage Channel Models</h1>
                </div>
            </div>
            <div className="flex max-w-8xl justify-center sm:justify-start">
                <button onClick={() => toggleMoelTabSelected(MODEL_VISIBILITY.BASE)} className={returnCollectionSelectorButton(MODEL_VISIBILITY.BASE)}>Base</button>
                <button onClick={() => toggleMoelTabSelected(MODEL_VISIBILITY.PUBLIC)} className={returnCollectionSelectorButton(MODEL_VISIBILITY.PUBLIC)}>Community</button>
                <button onClick={() => toggleMoelTabSelected(MODEL_VISIBILITY.PRIVATE)} className={returnCollectionSelectorButton(MODEL_VISIBILITY.PRIVATE)}>Private</button>
            </div>
            <div className="flex w-full justify-center sm:justify-start">
                <ReturnModelData/>
            </div>
        </>
    )

    function ReturnModelData() {
        if (profileState.registeredTgChats.length === 0) {
            return (
                <div className="flex w-full justify-center items-center my-8">
                    <h1 className="text-white text-2xl font-third">Register a channel</h1>
                </div>
            )
        } else if (profileState.selectedModelSectionType === MODEL_VISIBILITY.BASE) {
            return (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4">
                    {BaseModelSection()}
                </div>
            )
        } else if (profileState.selectedModelSectionType === MODEL_VISIBILITY.PRIVATE) {
            return (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4">
                    {PrivateModelSection()}
                </div>
            )
        } else {
            return (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4">
                    {CommunityModelSection()}
                </div> 
            )
        }
    }

    function toggleMoelTabSelected(selectedTab: MODEL_VISIBILITY) {
        dispatch(toggleSelectedModelTab({ selectedModelSectionType: selectedTab }))
    }

    function returnCollectionSelectorButton(selectedTab: MODEL_VISIBILITY) {
        if (profileState.selectedModelSectionType === selectedTab) {
            return "w-16 sm:w-auto md:w-auto font-secondary mx-2 h-12 md:text-2xl text-center md:text-left font-bold underline underline-offset-8 underline-white text-white"
        } else {
            return "w-16 sm:w-auto md:w-auto font-secondary mx-2 h-12 md:text-2xl text-center md:text-left font-bold hover:underline underline-offset-8 underline-white text-white"
        }
    }
}