import { Network } from "../../../models";
import { pushCustomModelApplication, updateApplicationNetworkStatus } from "../../../state/reducers/user/userProfileReducer";
import axios from "axios";
import HandleAlert, { errorAlert, successAlert } from "../../../utils/handleAlert";

const SUBMIT_APPLICATION = "https://webhook.lucidai.art/submitCustomModel";

export async function submitCustomModelApplication(telegramName: string, dispatch: any, network: Network) {
    let body = {
        publicAddress: network.selectedAccount,
        telegramName: telegramName
    }

    const headers = {
        'Authorization': `Bearer ${network.jwtToken}`
    };

    let response = null;
    try {
        response = await axios.post(SUBMIT_APPLICATION, body, { headers: headers });
    } catch (error) {
        dispatch(updateApplicationNetworkStatus("Error submitting application. Please try again."));
        HandleAlert(errorAlert("Error submitting application."), dispatch);
    }

    if (response && response.data && response.data.error) {
        dispatch(updateApplicationNetworkStatus(response.data.error));
        HandleAlert(errorAlert("Error submitting application."), dispatch);
    } else if (response && response.data && response.data.telegramName) {
        dispatch(pushCustomModelApplication({ telegramName: response.data.telegramName, status: response.data.status }))
        dispatch(updateApplicationNetworkStatus("Successfully submitted application. Please wait for a team member to reach out."))
        HandleAlert(successAlert("Successfully submitted application"), dispatch)
    } else {
        dispatch(updateApplicationNetworkStatus("Error submitting application. Please try again."));
        HandleAlert(errorAlert("Error submitting application."), dispatch);
    }
    return;
}