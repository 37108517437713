
import { useEffect } from "react";

//@ts-ignore
const useOutsideClick = (ref, callback) => {
  //@ts-ignore
  const handleClick = e => {
    if (ref) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useOutsideClick;