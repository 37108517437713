import { useDispatch } from "react-redux";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { useAppSelector } from "../../state/hooks";
import { TagBoxType } from "../../state/reducers/tag/tagSearchReducer";
import RangeSlider from "./tagAdjustmentSlider";

function TagAdjustmentModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const currentPromptState = useAppSelector(state => state.artCreationFilter.prompt);
    const currentNegativePromptState = useAppSelector(state => state.artCreationFilter.negativePrompt);
    
    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.TAG_ADJUSTMENT} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-8 md:mx-16 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div>
                                    <div className="flex justify-center items-center w-full">
                                        <h1 className="text-white">{getDisplayText()}</h1>
                                    </div>
                                    <div className="flex justify-center items-center w-full">
                                        
                                        <div className="">
                                            <TagAdjustmentView/>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function getDisplayText() {
        if (modalState.promptTag.type === TagBoxType.PROMPT) {
            return currentPromptState.find((tag => tag.id === modalState.promptTag.id))?.displayText
        } else {
            return currentNegativePromptState.find((tag => tag.id === modalState.promptTag.id))?.displayText
        }
    }

    function TagAdjustmentView() {
        return (
            <div className="flex items-center justify-center h-96 w-72 md:w-96">
                <div className="w-full px-12">
                    <div className="w-full">
                        <RangeSlider{...modalState.promptTag}/>
                    </div>
                    <div className="flex justify-between w-full">
                        <h1 className="w-1/4 text-white font-third text-lg">0.8</h1>
                        <h1 className="text-white font-third text-lg">2.0</h1>
                    </div>

                </div>
                
            </div>
        )
    }

    

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.TAG_ADJUSTMENT, show: false}));
    }
}



export default TagAdjustmentModal;

