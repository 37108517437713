import { Switch } from '@headlessui/react';
import { useAppSelector } from "../../../../state/hooks";
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APLICATION_STATUS } from '../../../../models/application/customModelApplication';

export default function ChannelSettings() {
    
    const profileState = useAppSelector(state => state.profileCollection);

    if (!profileState.initialProfileLoad) {
        return (
            <LoadingPanel/>
        )
    } else if (profileState.initialProfileLoad && profileState.registeredTgChats.length > 0) {
        return (
            <ChannelSettingsPanel/>
        )
    } else if (profileState.initialProfileLoad && profileState.registeredTgChats.length === 0) {
        return (
            <RegisterNewChannelPanel/>
        )
    }

    function ChannelSettingsPanel() {
        return (
            <div className="flex items-start justify-start w-full h-full">
                <div>
                    <div className="mb-2 mt-2">
                        <h1 className="text-white text-lg font-third">Channel: {profileState.selectedChannelName === "" ? "None selected" : profileState.selectedChannelName}</h1>
                    </div>
                    <div className="mb-2 mt-2">
                        <h1 className="text-white text-lg font-third">Application: {applicationStatusText()}</h1>
                    </div>                    
                </div>
            </div>
        )
    }

    function applicationStatusText() {
        let application = findApplication();
        if (application === undefined || application === null) {
            return "None"
        } else if (application.status === APLICATION_STATUS.OPEN) {
            return "Open"
        } else if (application.status === APLICATION_STATUS.CLOSED) {
            return "Closed"
        } else {
            return ""
        }
    }

    function findApplication() {
        return profileState.customModelApplications.find((application) => application.telegramName.toLowerCase() === profileState.selectedChannelName.toLowerCase())
    }

    function RegisterNewChannelPanel() {
        return (
            <div className="flex justify-center items-center w-full h-full">
                <h1 className="text-md font-third text-white">No Channels Registered</h1>
            </div>
        )
    }

    function LoadingPanel() {
        return (
            <div className="flex w-full h-full justify-center items-center">
                <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                    <div className="h-12 w-12 rounded-full bg-black"></div>
                </div>
            </div> 
        )
    }

    // to be implemented in future
    function ReturnSwitches() {
        return (
            <>
            <div className="flex mb-2">
                <div className="text-lg font-third text-white mr-2">
                    <h1>Disable NSFW</h1>
                </div>
                <Switch
                    className="relative inline-flex h-6 w-12 items-center rounded-full bg-black border-2 border-white"
                    >
                    <span>
                        <FontAwesomeIcon icon={faCheck} color='#FFFFFF' size="xs" className="absolute inset-y-0 left-0 mt-[4px] ml-[7px]"/>
                        <FontAwesomeIcon icon={faXmark} color='#FFFFFF' size="xs" className="absolute inset-y-0 right-0 mt-[4px] mr-[7px]"/>
                    </span>
                    <span className="inline-block h-4 w-4 transform rounded-full bg-white transition ui-checked:translate-x-6 ui-not-checked:translate-x-1" />
                </Switch>
            </div>
            <div className="flex mb-2">
                <div className="text-lg font-third text-white mr-2">
                    <h1>Disable Img2Img</h1>
                </div>
                <Switch
                    className="relative inline-flex h-6 w-12 items-center rounded-full bg-black border-2 border-white"
                    >
                    <span>
                        <FontAwesomeIcon icon={faCheck} color='#FFFFFF' size="xs" className="absolute inset-y-0 left-0 mt-[4px] ml-[7px]"/>
                        <FontAwesomeIcon icon={faXmark} color='#FFFFFF' size="xs" className="absolute inset-y-0 right-0 mt-[4px] mr-[7px]"/>
                    </span>
                    <span className="inline-block h-4 w-4 transform rounded-full bg-white transition ui-checked:translate-x-6 ui-not-checked:translate-x-1" />
                </Switch>
            </div>
            </>
        )
    }
}