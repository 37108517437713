export function DaysSinceISODateString(isoDateString: string) {
    // Convert the ISO string to a Date object
    const date = new Date(isoDateString);
    // Calculate the difference between the two dates in milliseconds
    const diffInMs = Date.now() -  date.getTime();
    // Convert the difference to days
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    if (diffInDays < 1) {
        
        return (
            <h1 className="text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text 
            text-transparent font-black text-xs md:text-sm font-secondary">New!</h1>
        )
    } else {
        return (
            <h1 className="text-xs md:text-sm font-secondary text-white">{diffInDays}D</h1>
        )   
    }
}

//<h1 className="text-white font-secondary text-sm">{diffInDays}D</h1>