import { useDispatch } from "react-redux";
import { updateModalState } from "../../../../state/reducers/modal/modalReducer";
import { MODAL_TYPE } from "../../../../models/modal";
import { useAppSelector } from "../../../../state/hooks";

function ProfilePremiumSection() {

    const dispatch = useDispatch();
    const profileState = useAppSelector(state => state.profileCollection);

    return (
        <div className="flex justify-center md:justify-start py-2 md:py-0">
            <div className="flex justify-center items-center">
                <h1 className="font-third text-white text-xl mr-2">Tier: {profileState.userTierInfo.currentTier === "" ? "None" : profileState.userTierInfo.currentTier}</h1>
            </div>
            <div className="flex justify-center items-center">
                <button onClick={() => handlePremiumPurchase()} className="p-2 hover:cursor-pointer bg-gradient-to-r
                        from-white via-purple-300 to-teal-300 hover:from-pink-500 hover:via-red-300 hover:to-red-600
                        background-animate p-[2px] rounded-lg">
                        <div className="flex items-center justify-center bg-black w-full h-full rounded-lg">
                            <div className="rounded-lg">
                                <h1 className="bg-black text-white font-third py-1 px-2 rounded-lg">upgrade</h1>
                            </div>
                        </div>
                </button>  
            </div>      
        </div>
    )

    function handlePremiumPurchase() {
        dispatch(updateModalState({type: MODAL_TYPE.PURCHASE_TIER, show: true}));
    }
}

export default ProfilePremiumSection;