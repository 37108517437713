import { configureStore } from '@reduxjs/toolkit';
import alertReducer from "./reducers/alert/alertReducer";
import artModelReducer from './reducers/artModel/artModelReducer';
import modalReducer from './reducers/modal/modalReducer';
import userAuthFlowReducer from './reducers/user/userAuthFlowReducer';
import networkReducer from './reducers/network/networkReducer';
import artCreationFilterReducer from './reducers/artCreation/artCreationFilterReducer';
import artCreationCanvasReducer from './reducers/artCreation/artCreationCanvasReducer';
import artCreationSubmissionReducer from './reducers/artCreation/artCreationSubmissionReducer';
import profileCollectionReducer from './reducers/user/userProfileReducer';
import artModalReducer from './reducers/modal/artModalReducer';
import tagSearchReducer from './reducers/tag/tagSearchReducer';
import homeCollectionReducer from './reducers/collection/homeCollectionReducer';
import tierReducer from './reducers/tier/tierReducer';
import presaleReducer from './reducers/presale/presaleReducer';

const store = configureStore({
    reducer: {
        alert: alertReducer,
        artModels: artModelReducer,
        artCreationFilter: artCreationFilterReducer,
        artCreationCanvas: artCreationCanvasReducer,
        artModelState: artModelReducer,
        artCreationSubmission: artCreationSubmissionReducer,
        profileCollection: profileCollectionReducer,
        homeCollection: homeCollectionReducer,
        modal: modalReducer,
        artModalSelection: artModalReducer,
        userAuthFlow: userAuthFlowReducer,
        network: networkReducer,
        tagSearchState: tagSearchReducer,
        tierState: tierReducer,
        presaleState: presaleReducer
    }
});

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch