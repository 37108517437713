import safeAdjectives from "../assets/dictionary/sfw/adjectives.json";
import safeAdverbs from "../assets/dictionary/sfw/adverb.json";
import safeAnime from "../assets/dictionary/sfw/anime.json";
import safeArticles from "../assets/dictionary/sfw/articles.json";
import safeConjunctions from "../assets/dictionary/sfw/conjunctions.json";
import safeInterjections from "../assets/dictionary/sfw/interjections.json";
import safeMovies from "../assets/dictionary/sfw/movie.json";
import safeNouns from "../assets/dictionary/sfw/nouns.json";
import safePrepositions from "../assets/dictionary/sfw/preposition.json";
import safePronouns from "../assets/dictionary/sfw/pronouns.json";
import safeVerbs from "../assets/dictionary/sfw/verbs.json";
import safeVideogames from "../assets/dictionary/sfw/videogame.json";

import nsfwAdjectives from "../assets/dictionary/nsfw/adjectives.json";
import nsfwAdverbs from "../assets/dictionary/nsfw/adverb.json";
import nsfwAnime from "../assets/dictionary/nsfw/anime.json";
import nsfwTags from "../assets/dictionary/nsfw/final_form2.json";
import nsfwArticles from "../assets/dictionary/nsfw/articles.json";
import nsfwConjunctions from "../assets/dictionary/nsfw/conjunctions.json";
import nsfwInterjections from "../assets/dictionary/nsfw/interjections.json";
import nsfwMovies from "../assets/dictionary/nsfw/movie.json";
import nsfwNouns from "../assets/dictionary/nsfw/nouns.json";
import nsfwPrepositions from "../assets/dictionary/nsfw/preposition.json";
import nsfwPronouns from "../assets/dictionary/nsfw/pronouns.json";
import nsfwVerbs from "../assets/dictionary/nsfw/verbs.json";
import nsfwVideogames from "../assets/dictionary/nsfw/videogame.json";

export enum CATEGORY_TYPE {
    ADULT,
    ANIME,
    VIDEOGAMES,
    MOVIES,
    NOUNS,
}

const dictionary = {
    words: ['hello','helium','world','car','carpet','test','this','that','those','working','is', 'case', 'care', 'can', 'call','californiaaaaa', 'cannnotnotnotnon', 'cannn','cannnotno']
}

export default function getDictionary() {
    const merged = dictionary.words.concat(
        safeAdjectives, 
        safeAdverbs,
        safeAnime, 
        safeArticles,
        safeConjunctions, 
        safeInterjections, 
        safeMovies, 
        safePrepositions, 
        safePronouns, 
        safeVerbs, 
        safeVideogames, 
        safeNouns
    );
    return merged;
}

export function getNSFWDictionary() {
    const merged = dictionary.words.concat(
        nsfwTags,
        nsfwAnime,
        nsfwNouns,
        nsfwArticles,
        nsfwAdjectives,
        nsfwAdverbs,
        nsfwPrepositions,
        nsfwPronouns,
        nsfwInterjections,
        nsfwConjunctions,
        nsfwMovies,
        nsfwVideogames,
        nsfwVerbs
    );
    return merged;
}

export function createRandomPrompt() {
    const possibleNounArrays = [safeAnime, safeMovies, safeVideogames];
    let randomIndex = Math.floor(Math.random() * possibleNounArrays.length);
    const randomFirstNoun = getRandomWords(possibleNounArrays[randomIndex], 1);

    const randomFirstAdverb = getRandomWords(safeAdverbs, 1);
    const randomFirstVerb = getRandomWords(safeVerbs, 1);
    const randomFirstAdjective = getRandomWords(safeAdjectives, 2);
    const randomFirstPreposition = getRandomWords(safePrepositions, 2);

    const possibleSecondNounArrays = [safeNouns];
    randomIndex = Math.floor(Math.random() * possibleSecondNounArrays.length);
    const possibleSecondNoun = getRandomWords(possibleSecondNounArrays[randomIndex], 3);

    return (randomFirstNoun[0] + " " + randomFirstVerb[0] + " " + 
            randomFirstAdjective[0] + " " + possibleSecondNoun[0] + " " + 
            randomFirstPreposition[0] + " " + possibleSecondNoun[1] + " " + 
            randomFirstPreposition[1] + " " + randomFirstAdjective[1] + " " + 
            possibleSecondNoun[2] + " " + randomFirstAdverb[0]);
}

export function createRandomNSFWPrompt() {
    const possibleNounArrays = [nsfwAnime, nsfwMovies, nsfwVideogames];
    let randomIndex = Math.floor(Math.random() * possibleNounArrays.length);
    const randomFirstNoun = getRandomWords(possibleNounArrays[randomIndex], 1);

    const randomFirstAdverb = getRandomWords(nsfwAdverbs, 1);
    const randomFirstVerb = getRandomWords(nsfwVerbs, 1);
    const randomFirstAdjective = getRandomWords(nsfwAdjectives, 2);
    const randomFirstPreposition = getRandomWords(nsfwPrepositions, 2);

    const possibleSecondNounArrays = [nsfwTags];
    randomIndex = Math.floor(Math.random() * possibleSecondNounArrays.length);
    const possibleSecondNoun = getRandomWords(possibleSecondNounArrays[randomIndex], 3);

    return (randomFirstNoun[0] + " " + randomFirstVerb[0] + " " + 
            randomFirstAdjective[0] + " " + possibleSecondNoun[0] + " " + 
            randomFirstPreposition[0] + " " + possibleSecondNoun[1] + " " + 
            randomFirstPreposition[1] + " " + randomFirstAdjective[1] + " " + 
            possibleSecondNoun[2] + " " + randomFirstAdverb[0]);
}

export function getRandomWords(array: string[], count:number) {
    const result = [];
    const maxIndex = array.length - 1;
    for (let i = 0; i < count; i++) {
      const randomIndex = Math.floor(Math.random() * (maxIndex + 1));
      result.push(array[randomIndex]);
    }
    return result;
}

export function existsInCategory(word: string, type: CATEGORY_TYPE) {
    if (CATEGORY_TYPE.MOVIES === type) {
        return safeMovies.includes(word) || nsfwMovies.includes(word)
    }
    if (CATEGORY_TYPE.ADULT === type) {
        return nsfwTags.includes(word)
    }
    if (CATEGORY_TYPE.ANIME === type) {
        return safeAnime.includes(word) || nsfwAnime.includes(word)
    }
    if (CATEGORY_TYPE.NOUNS === type) {
        return safeNouns.includes(word) || nsfwNouns.includes(word)
    }
    if (CATEGORY_TYPE.VIDEOGAMES === type) {
        return safeVideogames.includes(word) || nsfwVideogames.includes(word)
    }
}





