import axios from "axios";
import { ArtModel, GENERATIVE_TYPE, MODEL_TYPE, MODEL_VISIBILITY } from "../../models/art/artModel";
import { updateArtModelState } from "../../state/reducers/artModel/artModelReducer";

const BASE_URL = "https://api.lucidai.art/";
const PUBLIC_MODELS_ROUTE = "models"

// LOADS all public base and lora models
export async function loadPubliclyVisibleModels(dispatch: any) {
    try {
        console.log(BASE_URL + PUBLIC_MODELS_ROUTE);
        const response = await axios.get(BASE_URL + PUBLIC_MODELS_ROUTE);
        const public_model_results = response.data.data.public_models;
        const base_model_results = response.data.data.base_models;

        let models = [...formatModels(base_model_results), ...formatModels(public_model_results)];
        console.log("Found models")
        dispatch(updateArtModelState(models));

    } catch (error) {
        console.log("Error loading publicly visible models: " + error)
    }
}

function loadPrivateModels() {

}

function formatModels(data: any): ArtModel[] {
    const results = [] as ArtModel[]
    for (const modelJson of data) {
        results.push(formatModelJSON(modelJson));
    }
    return results;
}

function formatModelJSON(modelJson: any) {
    return {
        owner: modelJson.owner,
        name:modelJson.name,
        ticker: modelJson.ticker,
        nsfw: modelJson.nsfw,
        description: modelJson.description,
        imageUrl: modelJson.imageUrl,
        communityUrl: modelJson.communityUrl,
        telegramUrl: modelJson.telegramUrl,
        dextoolsUrl: modelJson.dextoolsUrl,
        modelVisibility: modelVisibilityHelper(modelJson.modelVisibility),
        modelType: modelTypeHelper(modelJson.modelType),
        generativeType: generativeTypeHelper(modelJson.generativeType)
    }
}

function modelVisibilityHelper(jsonString: string) {
    if (jsonString && jsonString.toLowerCase() === 'base') {
        return MODEL_VISIBILITY.BASE
    } else if (jsonString && jsonString.toLowerCase() === 'public') {
        return MODEL_VISIBILITY.PUBLIC
    } else {
        return MODEL_VISIBILITY.PRIVATE
    }
}

function modelTypeHelper(jsonString: string) {
    if (jsonString && jsonString.toLowerCase() === 'lora') {
        return MODEL_TYPE.LORA
    } else {
        return MODEL_TYPE.BASE
    }
}

function generativeTypeHelper(jsonString: string) {
    if (jsonString && jsonString.toLowerCase() === 'image') {
        return GENERATIVE_TYPE.IMAGE
    } else {
        return GENERATIVE_TYPE.IMAGE
    }
}