import { TagBoxType } from "../../state/reducers/tag/tagSearchReducer"

export default interface PromptTag {
    id: string;
    strength: number;
    displayText: string;
    rawText: string;
    type: TagBoxType;
}

export function updateTagStrengthRawText(rawText: string, newStrength: number): string {
    const pattern = /\(\d+(\.\d+)?\)$/;
    if (pattern.test(rawText)) {
        return `(${extractWordsBeforeLast(rawText).join(" ")}:${newStrength})`
    } else {
        return `(${rawText}:${newStrength})`
    }
}

export function updateTagStrengthDisplayText(displayText: string, newStrength: number): string {
    // Use regular expression to match the "(number)" pattern
    // The "()" part is used to group the matched pattern
    const regex = /\((\d+(\.\d+)?)\)/;

    if (newStrength === 0) {
        // If newNumber is 0, return the rawText part without the (number) pattern
         return displayText.replace(regex, '').trim();
        
    } else {
        // Use the replace function to update the number
        return `${displayText.replace(regex, '').trim()} (${newStrength})`
    }
}

export function containsParenthesis(input: string): boolean {
    return input.includes("(") || input.includes(")");
}

function countDigitsInString(input: string) {
    const digits = input.match(/\d/g);
    return digits ? digits.length : 0;
}

export function containsSpecialCharactersOrDigits(inputText: string): boolean {
    // Use regular expression to check for special characters in the input text
    const regex = /[^a-zA-Z0-9\s]/;
    return regex.test(inputText) || countDigitsInString(inputText) > 2;
}

export function containsSpecialCharactersButIgnoreUnderscores(input: string): boolean {
    // Regular expression to match special characters excluding underscores
    const regex = /[!@#$%^&*()+\-=[\]{};':"\\|,.<>?/`~]/;

    // Use the test method of the regular expression to check if the string contains any matches
    return regex.test(input);
}

  export function containsOnlyModifierCharacters(inputText: string): boolean {
    if (inputText.match('[_().:]')) {
        return true;
    }
    return false;
  }

export function containsOnlySpaces(inputText: string): boolean {
    // Use regular expression to check if the entire string contains only spaces
    const regex = /^\s*$/;
    return regex.test(inputText);
}

export function endsOrBeginsWithUnderscore(inputText: string): boolean {
    // Use regular expression to check if the entire string contains only spaces
    if (inputText.length < 1 && !inputText.includes("_")) {
        return false;
    }
    return inputText.charAt(0) === "_" || inputText.charAt(inputText.length - 1) === "_";
}

export function extractWordOrPhraseFromPattern(inputText: string): string | undefined {
    console.log(inputText);
    // Use regular expression to match the valid pattern "(word:0.1)" or "(test phrase with tag_test:0.1)"
    const regex = /^\(\s*((?:[^():\d]*[a-zA-Z_]+[^():\d]*)|(?:[^():\d]*[a-zA-Z_ ]+[^():\d]*))\s*(:\d+(\.\d+)?)?\s*\)$/;

    // Try to find a match in the input text exmaple: // ['(test:0.1)', 'test', ':0.1', '.1', index: 0, input: '(test:0.1)', groups: undefined]
    const match = inputText.match(regex);

    if (match && match[2] === undefined) {
        return undefined
    }
    // If a match is found and it exactly matches the inputText, return the extracted phrase, otherwise return undefined
    return match && match[0] === inputText ? match[1].trim() : undefined;
}

export function extractDecimalStrengthFromTag(inputString: string): number | undefined {
    const regex =  /\d+([\/.]\d+)?/g;
    const match = inputString.match(regex);
    if (match) {
        return parseFloat(match[0]);
    }
    return undefined;
}

export function extractTagStrengthDisplayText(inputText: string): string {
    // Use regular expression to match the "(word:0.1)" pattern
    const regex = /\(([^():]+:\d+(\.\d+)?)\)/;
  
    // Try to find a match in the input text
    const match = inputText.match(regex);
  
    // If a match is found, extract the phrase and strength, and return them in the desired format
    if (match) {
      const [phraseWithStrength] = match;
      const parts = phraseWithStrength.slice(1, -1).split(":");
      if (parts.length === 2) {
        const phrase = parts[0].trim();
        const strength = parts[1].trim();
        return `${phrase} (${strength})`;
      }
    }
  
    // If no match is found or the format is incorrect, return the original input text as-is
    return inputText;
}

export function extractWordsBeforeLast(prompt: string): string[] {
    // Split the sentence into an array of words using regular expression to handle multiple spaces
    const words = prompt.trim().split(/\s+/);
  
    // Get the index of the last word
    const lastIndex = words.length - 1;
  
    // Extract the words before the last word
    const wordsBeforeLast = words.slice(0, lastIndex);
  
    return wordsBeforeLast;
}

export function getLastWord(prompt: string): string {
    // Split the sentence into an array of words using regular expression to handle multiple spaces
    const words = prompt.trim().split(/\s+/);

    // Return the last word from the array
    return words[words.length - 1];
}
