import { Tier, TierType } from "../../models/tier/tier";
import { useDispatch } from "react-redux";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { useAppSelector } from "../../state/hooks";
import { useEffect } from "react";

import { fetchAndUpdateTiers } from "../../networking/tier/fetchTiers";

import usdc_logo from "../../assets/svg/usdc.svg";

export default function PricingBody() {
  const dispatch = useDispatch();
  let tierState = useAppSelector(state => state.tierState);

  useEffect(() => {
    async function fetchTierData() {
        if (!tierState.initialLoad) {
          await fetchAndUpdateTiers(dispatch);
        }
    }
    fetchTierData();
  }, [dispatch, tierState])

  return (
    <div className="bg-white md:pt-4 pb-32">
      <div className="mx-auto max-w-7xl py-4 px-6 text-center lg:px-8">
        <div className="w-full flex justify-center">
            <h1 className="mt-1 text-4xl font-bold font-primary tracking-tight sm:text-5xl lg:text-6xl 
              text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text 
              text-transparent font-black drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              PRICING
          </h1>
        </div>
      </div>
      <div className="mx-auto max-w-8xl p-4 px-6 lg:px-32 xl:px-64">
        <div className="flex w-full justify-center items-center">
          <div className="w-full grid sm:grid-cols-2 gap-4 sm:gap-6">
            {tierState.tiers.map((tier) => (
              <div className="flex bg-gradient-to-r
              from-blue-200 via-purple-300 to-teal-300
              background-animate p-[2px] rounded-lg shadow-xl">
                <div key={tier.type === TierType.COMMUNITY ? "Community" : "Individual"} className="flex-grow h-full divide-y divide-gray-200 rounded-lg bg-white shadow-sm">
                  <div className="p-6">
                    <h2 className="text-lg font-secondary leading-6 font-secondary text-black">{tier.type === TierType.COMMUNITY ? "Community" : "Individual"}</h2>
                    <p className="mt-4 text-lg font-third text-black h-24">{tier.description}</p>
                    <div className="flex items-center md:mt-4">
                      <img className="rounded-full h-8 w-8 mr-2 -mt-1" alt="" src={usdc_logo}></img>
                      <span className="text-4xl font-bold font-secondary text-lg tracking-tight text-black mr-1">{0.02 + ' / '}</span>{' '}
                      <span className="text-4xl font-secondary text-lg tracking-tight text-black">{' generation'}</span>{' '}
                    </div>
                    {tierPurchasButton(tier)}
                  </div>
                  <div className="px-6 pt-6 pb-8">
                    <h3 className="text-lg font-bold text-black font-secondary">What's included</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {tier.includedFeatures.map((feature) => (
                        <li key={feature} className="flex space-x-3">
                          <span className="text-sm md:text-md text-black font-third">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                </div>
            ))}
          </div>
        </div>
        
      </div>
  </div>
  )

  function tierPurchasButton(tier: Tier) {
    if (tier.type === TierType.INDIVIDUAL) {
      return (
        <button
          disabled={true}
          className="mt-8 block w-full rounded-md py-2 text-center text-sm font-semibold bg-gray-300 text-white"
        >
          Coming Soon
        </button>
      )
    } else {
      return (
        <button
        onClick={() => displayTierPruchaseModal()}
        className="mt-8 block w-full rounded-md border border-space-gray bg-black py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
      >
        {'Buy ' + (tier.type === TierType.COMMUNITY ? "Community" : "Individual")}
      </button>
      )
    }
  }

  function displayTierPruchaseModal() {
    dispatch(updateModalState({type: MODAL_TYPE.PURCHASE_TIER, show: true}))
  }
}