import { faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { UserArt } from "../../../models";
import { MODAL_TYPE } from "../../../models/modal";
import { useAppSelector } from "../../../state/hooks";
import { pushToNsfwIds } from "../../../state/reducers/collection/homeCollectionReducer";
import { updateArtModalState, updateArtModalSelection, updateModalState} from "../../../state/reducers/modal/modalReducer";
import Masonry from 'react-masonry-css'

export interface HomeCollectionProps {
    collection: UserArt[]
}
function HomeCollection() {
    
    const collectionState = useAppSelector(state => state.homeCollection);
    const networkState = useAppSelector(state => state.network);

    const dispatch = useDispatch();

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
      };
    
    if (collectionState.filteredCollection.length === 0) {
        return (
            <div className="flex min-h-screen w-full justify-center items-start bg-space-gray">
                <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                    <div className="h-12 w-12 rounded-full bg-space-gray"></div>
                </div>
            </div>
        )
    } else {
        return ( 
            <div className="flex w-full bg-space-gray pt-2 px-4 pb-24 md:pb-4">
                <div className="flex w-full">
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {collectionState.filteredCollection.map((art) => (
                            CollectionCardPreview(art)
                        ))}
                    </Masonry>
                </div>
            </div>
        )
    }
/*
<ul className="flex w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                        {collectionState.filteredCollection.map((art) => (
                            CollectionCardPreview(art)
                        ))}
                    </ul>

*/
    function CollectionCardPreview(props: UserArt) {
        if (props.s3ImageUrls.length > 0) {
            return (
                <div onClick={() => artSelected(props)} className="w-full cursor-pointer">
                    <div className="relative overflow-hidden h-min w-full rounded-lg">
                        {props.s3ImageUrls && props.s3ImageUrls.length > 0 ? NSFWCardFilter(props) : <></>}
                    </div>
                </div>     
            )
        }
    }
    
    function NSFWCardFilter(props: UserArt) {
        if (props.nsfw && !collectionState.nfswFlag && collectionState.nsfwIds.includes(props.jobId)) {
            return (
                <img src={props.s3ImageUrls[0]} alt="" className="w-full h-auto object-cover rounded-lg"/>
            )
        }
        if (props.nsfw && !collectionState.nfswFlag && !collectionState.nsfwIds.includes(props.jobId)) {
            return (
                <>
                <img src={props.s3ImageUrls[0]} alt="" className="w-full h-auto object-cover rounded-lg blur"/>
                    <div className="absolute inset-0">
                        <div className="flex w-full h-full justify-center items-center">
                            <FontAwesomeIcon icon={faEyeSlash} className="mx-1" size="1x" color="white"/>
                            <h1 className="text-sm font-third text-white mx-1">18+</h1>
                        </div>
                    </div>
                </>
            )
        }
        if (props.nsfw && collectionState.nfswFlag) {
            return (
                <img src={props.s3ImageUrls[0]} alt="" className="w-full h-auto object-cover rounded-lg"/>
            )
        }
        if (!props.nsfw) {
            return (
                <img src={props.s3ImageUrls[0]} alt="" className="w-full h-auto object-cover rounded-lg"/>
            )
        }
    }

    function artSelected(props: UserArt) {
        if (props.nsfw && networkState.selectedAccount !== "" && collectionState.nfswFlag === false) {
            dispatch(pushToNsfwIds(props.jobId));
        }
        if (props.nsfw && networkState.selectedAccount === "") {
            dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: true, signInMessage: "Sign in to generate and view NSFW content"}));
        } else {
            dispatch(updateArtModalSelection(0));
            dispatch(updateArtModalState({type: MODAL_TYPE.ART, show: true, userArt: props}));
        } 
    }
}

export default HomeCollection;