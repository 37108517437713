import axios from "axios";
import { updateProfileCollection, updateProfileInfo } from "../../state/reducers/user/userProfileReducer";
import { UserArt } from "../../models/art/userArt";
import { ArtJobObjectHelper, JobState, updateSubmission } from "../../state/reducers/artCreation/artCreationSubmissionReducer";
import { 
    clearHomeCollection, 
    pushToHomeCollection, 
    updateGenerationStatistic 
} from "../../state/reducers/collection/homeCollectionReducer";
import { TagBoxType } from "../../state/reducers/tag/tagSearchReducer";
import PromptTag from "../../models/art/promptTag";
import { Network } from "../../models";
import { CHANNEL_TYPE, RegisteredChannel } from "../../models/collection/registeredChannel";
import { UserTierInfo } from "../../models/tier/tier";
import { APLICATION_STATUS, CustomModelApplication } from "../../models/application/customModelApplication";

const BASE_URL = "https://api.lucidai.art/"
const HOME_ROUTE = "results?"
const STATISTICS_ROUTE = "statistics"

const USER_INFO_ROUTE = "https://webhook.lucidai.art/userInfo"
const JOB_INFO_ROUTE = "https://webhook.lucidai.art/jobInfo"

export interface HomeCollectionResults {
    collection: UserArt[],
    newCollectionIds: string[],
    lastPage: boolean,
}

export async function fetchAndUpdateUserArtState(dispatch: any, selectedAccount: string, jwtToken: string) {
    
    try {
        if (jwtToken === "") {
            return;
        }
        const queryParams = {
            publicAddress: selectedAccount
        };
          
        const headers = {
            'Authorization': `Bearer ${jwtToken}`
        };
        const response = await axios.get(USER_INFO_ROUTE, {params: queryParams, headers: headers});
        const user = response.data.user;

        if (user) {
            dispatch(updateProfileInfo({telegramUsername: user.telegramUsername, discordUsername: user.discordUsername}));
        }
        let savedCollection = []
        let collection = []
        let registeredTgChats = []
        let applications: CustomModelApplication[] = []
        let userTierInfo = {
            currentTier: "",
            lastDepositTime: "",
            lastDepositValue: 0,
            totalDepositValue: 0,
            generationsLeft: 0
        } as UserTierInfo
        collection = formatCollection(response.data.collection);
        savedCollection = formatCollection(response.data.savedCollection) 
        registeredTgChats = formatTGChannels(response.data.registerdChannels)
        if (response.data.userTier && response.data.userTier.currentTier) {
            userTierInfo = formatUserTierInfo(response.data.userTier)
        }
        if (response.data.applications) {
            applications = formatCustomModelApplications(response.data.applications);
        }
    
        dispatch(updateProfileCollection({
                initialLoad: true, 
                profileStatus: false, 
                collection: collection, 
                savedCollection: savedCollection, 
                registeredTgChats: registeredTgChats, 
                userTierInfo: userTierInfo,
                customModelApplications: applications
            }
        ));

        // check for any pending jobs
        const hasEmptyImageUrl = collection.some((art) => art.s3ImageUrls.length === 0);
        return hasEmptyImageUrl;

    } catch (error) {
        console.log("An error occured fetching data for user: " + error);
    }
}

export async function waitForUserJobCompletion(dispatch: any, network: Network, jobId: string) {
    let intervalId = setInterval(async () => {
        try {
            const queryParams = {
                publicAddress: network.selectedAccount
            };
              
            const headers = {
                'Authorization': `Bearer ${network.jwtToken}`
            };
            const response = await axios.get(USER_INFO_ROUTE, {params: queryParams, headers: headers} );
            
            const collection = formatCollection(response.data.collection);
            if (collection.length > 0) {
                dispatch(updateProfileCollection({initialLoad: true, profileStatus: false, collection: collection}))
                if (collection[collection.length - 1].jobId === jobId && collection[collection.length - 1].s3ImageUrls.length !==0) {
                    dispatch(updateSubmission(ArtJobObjectHelper(jobId, JobState.COMPLETED, [collection[collection.length - 1]])));
                    dispatch(updateProfileCollection({initialProfileLoad: true, profileStatus: false, collection: collection}));
                    clearInterval(intervalId);
                }
            }   
            console.log("listening for completed job updates...");
        } catch (error) {
            console.log("An error occured fetching data for user: " + error);
        }

    }, 2000)

    setTimeout(() => {
        clearInterval(intervalId);
    }, 300000);
}

export async function waitForFreeJobCompletion(dispatch: any, jobId: string) {
    let intervalId = setInterval(async () => {
        try {
            const queryParams = {
                jobId: jobId
            };
              
            const response = await axios.get(JOB_INFO_ROUTE, {params: queryParams} );
            const collection = formatCollection(response.data.collection);
            
            if (collection.length > 0 && collection[collection.length - 1].jobId === jobId && collection[collection.length - 1].s3ImageUrls.length !==0) {
                dispatch(updateSubmission(ArtJobObjectHelper(jobId, JobState.COMPLETED, [collection[collection.length - 1]])));
                clearInterval(intervalId);
            }
        } catch (error) {
            console.log("An error occured fetching data for user: " + error);
        }

    }, 2000)

    setTimeout(() => {
        clearInterval(intervalId);
    }, 300000);
}

export async function loadHomeCollection(dispatch: any, lastTimestamp: string, asc: boolean, clear: boolean) {
    const ascParameter = "asc=" + asc.toString();
    const timestampParameter = "lastTimestamp=" + lastTimestamp;
    if (clear) {
        dispatch(clearHomeCollection());
    }
    try {
        // initial load
        if (lastTimestamp === "") {
            const response = await axios.get(BASE_URL + HOME_ROUTE + ascParameter);
            const results = formatCollection(response.data.data);
            pushToHomeCollectionHelper(dispatch, results);
        // scrolling loads
        } else {
            const response = await axios.get(BASE_URL + HOME_ROUTE + ascParameter + "&" + timestampParameter);
            const results = formatCollection(response.data.data);
            pushToHomeCollectionHelper(dispatch, results);
        }
    } catch (error) {
        console.log("An error occured updating home collection: " + error);
    }
}

export async function loadModels() {
    console.log("loading models...")
}

export async function loadTiers() {
    console.log("loading tiers...")
}

export async function pushToHomeCollectionHelper(dispatch: any, results: UserArt[]) {
    dispatch(pushToHomeCollection(results));
}

export function formatTGChannels(data: any): RegisteredChannel[] {
    const results = [] as RegisteredChannel[];
    for (const channelJson of data) {
        results.push(tgChannelHelper(channelJson));
    }
    return results;
}

function tgChannelHelper(channelJson: any) {
    return {
        name: channelJson.channelName,
        chatId: channelJson.chatId,
        chatUsername: channelJson.chatUsername,
        blacklistedModels: channelJson.blacklistedModels,
        currentNumGenerationsLeft: channelJson.currentNumGenerationsLeft,
        totalNumGenerations: channelJson.totalNumGenerations,
        usedTrial: channelJson.usedTrial,
        isModifying: false,
        type: CHANNEL_TYPE.TELEGRAM
    }
}

export function formatUserTierInfo(data: any): UserTierInfo {
    return {
        currentTier: data.currentTier,
        lastDepositTime: data.lastDepositTime,
        lastDepositValue: data.lastDepositValue,
        totalDepositValue: data.totalDepositValue,
        generationsLeft: data.generationsLeft
    }
}

export function formatCustomModelApplications(data: any): CustomModelApplication[] {
    const results = [] as CustomModelApplication[];
    for (const appJson of data) {
        results.push(userApplicationHelper(appJson))
    }
    return results;
}

function userApplicationHelper(appJson: any) {
    return {
        telegramName: appJson.telegramName,
        status: appJson.status === APLICATION_STATUS.OPEN ? APLICATION_STATUS.OPEN : APLICATION_STATUS.CLOSED
    } as CustomModelApplication
}

export function formatCollection(data: any): UserArt[] {
    const results = [] as UserArt[];
    for (const eventJson of data) {
        results.push(userArtHelper(eventJson));
    }
    return results;
}

function userArtHelper(eventJson: any) {
    return {
        jobId: eventJson.jobId,
        dateCreated: eventJson.timestamp,
        userAddress: eventJson.userAddress,
        tokenPromoAddress: "", // for now 
        s3ImageUrls: eventJson.s3Urls,
        ipfsImageUrls: eventJson.ipfsUrls,
        advertisementAddress: "", // for now
        minted: false,
        model: eventJson.model,
        loras: [], // for now
        numImages: eventJson.numImages,
        generationType: "prompt", // for now
        prompt: parseRawPrompt(eventJson.prompt, TagBoxType.PROMPT),
        nsfw: eventJson.nsfw,
        promptStrength: parseInt(eventJson.strength),
        steps: parseInt(eventJson.steps),
        negativePrompt: parseRawPrompt(eventJson.negativePrompt,TagBoxType.NEGATIVE_PROMPT),
        width: parseInt(eventJson.width),
        height: parseInt(eventJson.height),
        chainId: parseInt(eventJson.chainId)
    }
}

export function parseRawPrompt(tags: string[], type: TagBoxType) {
    const results = [] as PromptTag[];
    for (const tag of tags) {
        results.push({
            id: "",
            strength: extractStrengthFromParentheses(tag),
            rawText: tag,
            displayText: tag,
            type: type
        })
    }
    return results;
}

export async function loadStatistics(dispatch: any) {
    try {
        console.log(BASE_URL + STATISTICS_ROUTE)
        const response = await axios.get(BASE_URL + STATISTICS_ROUTE);
        const numberOfGenerations = response.data.data;     
        dispatch(updateGenerationStatistic(numberOfGenerations));
    } catch (error) {
        console.log("An error occured fetching statistics" + error);
    }
}

export function extractStrengthFromParentheses(str: string): number {
    const regex = /\((\d+(\.\d+)?)\)/;
    const match = str.match(regex);
  
    if (match && match[1]) {
      return parseFloat(match[1]);
    } else {
        return 0
    }
}



