import { ArtCreationFilter } from "../../../models/art/artCreationFilter";
import { Network } from "../../../models/network";
import { createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";
import HandleAlert, { errorAlert, successAlert } from "../../../utils/handleAlert";
import { UserArt } from "../../../models/art/userArt";
import { waitForFreeJobCompletion, waitForUserJobCompletion } from "../../../networking/art/userNetworking";
import PromptTag from "../../../models/art/promptTag";
import axios from "axios";

export enum JobState {
    READY,
    TRX_PENDING,
    QUEUED,
    GENERATING,
    COMPLETED
}

export interface ArtCreationJob {
    jobId: string
    jobLoading: JobState,
    statusMessage: string,
    imageResults: UserArt[]
}

const initialJobSubmissionState: ArtCreationJob = {
    jobId: "",
    statusMessage: "",
    jobLoading: JobState.READY,
    imageResults: [],
}


const FREE_JOB_ROUOTE = "https://webhook.lucidai.art/submit-webapp-free-job"
const PAID_JOB_ROUOTE = "https://webhook.lucidai.art/submit-webapp-auth-job"

const artCreationJobReducer = createSlice({
    name: "artJob",
    initialState: initialJobSubmissionState,
    reducers: {
        updateSubmission: (state, action) => {
            return {...action.payload}
        },
        updateStatusMessage: (state, action) => {
            state.statusMessage = action.payload;
        }
    },
});

export default artCreationJobReducer.reducer;
export const { updateSubmission, updateStatusMessage } = artCreationJobReducer.actions;

export interface JobParams {
    nsfw: boolean,
    id: string,
    strength: number,
    steps: number,
    width: number,
    height: number,
    numImages: number,
    user: string,
    model: string,
    lora: string[],
    prompt: string[],
    negativePrompt: string[]
}

export async function submitArtJob(dispatch: any, artCreationState: ArtCreationFilter, networkState: Network, paid: boolean) {
    
    const headers = paid ? { 'Authorization': `Bearer ${networkState.jwtToken}` } :  {}
    const url = paid ? PAID_JOB_ROUOTE : FREE_JOB_ROUOTE
    
    try {
        let response = await axios.post(url, constructJobParams(artCreationState, networkState), {headers: headers});
        console.log(response);
        if (response && response.data.jobId !== undefined) {
            HandleAlert(successAlert("Received job, do not refresh"), dispatch);
            dispatch(updateSubmission(ArtJobObjectHelper(response.data.jobId, JobState.GENERATING, [])));
        } else {
            //TODO: error handling
            return;
        }

        if (networkState.selectedAccount !== "" && networkState.jwtToken !== "") {
            waitForUserJobCompletion(dispatch, networkState, response.data.jobId);
        } else {
            waitForFreeJobCompletion(dispatch, response.data.jobId);
        }
    } catch (error) {
        console.log(error);
        HandleAlert(errorAlert("Job not submited"), dispatch);
        dispatch(updateSubmission(ArtJobObjectHelper("", JobState.READY, [])));
    }  
}

export async function waitTransactionReceipt(transactionHash: any) {
    while (true) {
        //@ts-ignore
        const receipt = await window.ethereum.request({
            method: 'eth_getTransactionReceipt',
            params: [transactionHash],
        });

        if (receipt && receipt.blockNumber) {
            return receipt;
        }
        // Wait for 2 seconds before checking again
        await new Promise((resolve) => setTimeout(resolve, 2000));
    }
}

export async function estimateJobGasFee(web3Connection: Web3, params: any) {
    try {
        return await web3Connection.eth.estimateGas(params)
    } catch (e) {
        console.log("Issue estimating gas: ", e);
        return null;
    }
}

export async function getLatestMaticFeeRate(contractInstance: any) {
    return await contractInstance.methods.getLatestGenerationFeeInGwei().call({from: '0x172ea33d611606DaBEd57a6fB9D0bb064D95Fce9'});
}

export async function getLatestEthFeeRate(contractInstance: any) {
    return await contractInstance.methods.getLatestGenerationFeeInGwei().call({from: '0x172ea33d611606DaBEd57a6fB9D0bb064D95Fce9'});
}

export function ArtJobObjectHelper(jobId: string, loadingState: JobState, imageResults: UserArt[]) {
    return {
        jobId: jobId,
        jobLoading: loadingState,
        imageResults: imageResults.length !== 0 ? imageResults : [],
    } as ArtCreationJob
}

export function constructJobParams(artCreationState: ArtCreationFilter, networkState: Network) {
    return {
        nsfw: artCreationState.nsfw,
        user: networkState.selectedAccount,
        strength: artCreationState.promptStrength,
        steps: artCreationState.steps,
        width: artCreationState.width,
        height: artCreationState.height,
        numImages: artCreationState.numImages,
        model: artCreationState.model.replaceAll(" ", "_").toLowerCase(),
        lora: artCreationState.loras.length === 0 ? [] : artCreationState.loras.map(item => item.replace(/ /g, "_").toLowerCase()),
        prompt: getRawPromptTags(artCreationState.prompt),
        negativePrompt: getRawPromptTags(artCreationState.negativePrompt)
    }
}

export function getRoute() {
     
}


export function getRawPromptTags(tags: PromptTag[]): string[] {
  const result: string[] = [];
  for (const tag of tags) {
    result.push(tag.rawText);
  }
  return result;
}