import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartPieSimple, faWaterArrowUp, faLock, faGlassWater, faWhale, faWallet } from "@fortawesome/pro-solid-svg-icons";

const ETHER_SCAN_URL = ""
const MARKETING_WALLET = ""
const LIQUIDITY_LOCK = ""
const LIQUIDITY_POOL = ""

export default function TokenomicsCardSection() {
    return (
      <div className="w-full my-4 pb-8 md:pb-16">
          <div className="flex w-full justify-center items-center">
            <a href={ETHER_SCAN_URL} target="_" className="rounded-lg bg-gradient-to-r
              from-white via-purple-300 to-teal-300
              background-animate p-1 m-2 shadow-xl transform transition-all hover:scale-105">
                <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                  <div className="w-full">
                    <div className="hidden sm:flex justify-center items-center py-2 w-full">
                      <FontAwesomeIcon icon={faChartPieSimple} size="3x" color="white" className="fa-beat slow-beat"/>
                    </div>
                    <div className="sm:hidden justify-center items-center py-2 w-full flex">
                      <FontAwesomeIcon icon={faChartPieSimple} size="2x" color="white" className="fa-beat slow-beat"/>
                    </div>
                    <div className="sm:flex items-center justify-center w-full py-2 sm:py-4">
                      <h1 className="w-full sm:w-auto h-4 my-1 sm:my-0 sm:h-auto font-secondary text-white text-xs sm:text-sm sm:mr-1 text-center sm:text-auto">1B</h1>
                      <h1 className="w-full sm:w-auto text-center sm:text-auto text-animate font-secondary bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">SUPPLY</h1>
                    </div>
                  </div>
                </div>
            </a>
            <a href={LIQUIDITY_LOCK} target="_" className="rounded-lg bg-gradient-to-r
              from-white via-purple-300 to-teal-300
              background-animate p-1 m-2 shadow-xl cursor-pointer transform transition-all hover:scale-105">
                  <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                    <div className="w-full">
                      <div className="hidden sm:flex justify-center items-center py-2 w-full">
                        <FontAwesomeIcon icon={faGlassWater} size="3x" color="white" className="fa-beat slow-beat"/>
                      </div>
                      <div className="sm:hidden justify-center items-center py-2 w-full flex">
                        <FontAwesomeIcon icon={faGlassWater} size="2x" color="white" className="fa-beat slow-beat"/>
                      </div>
                      <div className="sm:flex items-center justify-center w-full py-2 sm:py-4">
                          <FontAwesomeIcon className="sm:hidden w-full sm:w-auto h-4 pb-[2px]" icon={faLock} color="white"/>
                          <FontAwesomeIcon className="hidden sm:flex mr-2 pb-[2px]" icon={faLock} size="1x" color="white"/>
                          <h1 className="w-full sm:w-auto text-center sm:text-auto font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">FOREVER</h1>
                      </div>
                    </div>
                  </div>
              </a>
          </div>

          <div className="flex w-full justify-center items-center">
            <a href={LIQUIDITY_POOL} target="_" className="rounded-lg bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate p-1 m-2 shadow-xl cursor-pointer transform transition-all hover:scale-105">
                  <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                    <div className="w-full">
                      <div className="hidden sm:flex justify-center items-center py-2 w-full">
                        <FontAwesomeIcon icon={faWaterArrowUp} size="3x" color="white" className="fa-beat slow-beat"/>
                      </div>
                      <div className="sm:hidden justify-center items-center py-2 w-full flex">
                        <FontAwesomeIcon icon={faWaterArrowUp} size="2x" color="white" className="fa-beat slow-beat"/>
                      </div>
                      <div className="sm:flex  items-center justify-center w-full py-2 sm:py-4">
                        <h1 className="w-full sm:w-auto h-4 my-1 sm:my-0 sm:h-auto font-secondary text-white text-xs sm:text-sm sm:mr-1 text-center sm:text-auto">1%</h1>
                        <h1 className="w-full sm:w-auto text-center sm:text-auto text-animate font-secondary bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">LIQUIDITY</h1>
                      </div>
                    </div>
                  </div>
              </a>
              <a href={MARKETING_WALLET} target="_" className="rounded-lg bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate p-1 m-2 shadow-xl cursor-pointer transform transition-all hover:scale-105">
                  <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                    <div className="w-full">
                        <div className="hidden sm:flex justify-center items-center py-2 w-full">
                          <FontAwesomeIcon icon={faWhale} size="3x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:hidden justify-center items-center py-2 w-full flex">
                          <FontAwesomeIcon icon={faWhale} size="2x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:flex items-center justify-center w-full py-2 sm:py-4">
                          <FontAwesomeIcon className="sm:hidden w-full sm:w-auto h-4 pb-[2px]" icon={faWallet} color="white"/>
                          <FontAwesomeIcon className="hidden sm:flex mr-2 pb-[2px]" icon={faWallet} size="1x" color="white"/>
                          <h1 className="w-full sm:w-auto h-4 my-1 sm:my-0 sm:h-auto font-secondary text-white text-xs sm:text-sm sm:mr-1 text-center sm:text-auto">2%</h1>
                          <h1 className="w-full sm:w-auto text-center sm:text-auto text-animate font-secondary bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">CAP</h1>
                        </div>
                    </div>
                  </div>
                </a>
            </div>
      </div>
    )
  }