import { useDispatch } from "react-redux";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { MODEL_VISIBILITY } from "../../models/art/artModel";
import { APLICATION_STATUS, CustomModelApplication } from "../../models/application/customModelApplication";
import { RegisteredChannel } from "../../models/collection/registeredChannel";
import { submitCustomModelApplication } from "../../networking/user/custom/submitCustomModelApplication";

export default function CustomModelRequestModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const profileState = useAppSelector(state => state.profileCollection);
    const networkState = useAppSelector(state => state.network);

    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.MODEL_REQUEST} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-8 md:mx-32 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 mx-4 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div className="flex w-full justify-center p-4">
                                    <Header/>
                                </div>
                                <div className="flex w-full justify-center px-4">
                                    <SubmissionSection/>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function SubmissionSection() {
        let openApplication = findAnyOpenApplication();
        let selectedRegisteredChannel = findSelectedChannelObject();
        if (profileState.linkedTelegramUsername === "") {
            return (
                <div className="flex justify-center items-center h-64 w-64 md:w-72">
                    <h1 className="text-white font-secondary border-2 border-white rounded-lg p-2">Please link your Telegram account.</h1>
                </div>
            )
        } else if (profileState.registeredTgChats.length === 0) {
            return (
                <div className="flex justify-center items-center h-64 w-64 md:w-72">
                    <h1 className="text-white font-secondary border-2 border-white rounded-lg p-2">Please register a community to apply for a custom model. See tutorials: {}</h1>
                </div>
            )
        } else if (profileState.userTierInfo.currentTier === "") {
            return (
                <div className="flex justify-center items-center h-64 w-64 md:w-72">
                    <h1 className="text-white font-secondary border-2 border-white rounded-lg p-2">Purchase a tier in order to request a custom model for your community. See tutorials: {}</h1>
                </div>
            )
        } else if (openApplication !== undefined && (selectedRegisteredChannel && openApplication.telegramName === selectedRegisteredChannel.name)) {
            return (
                <div className="flex justify-center items-center h-64 w-64 md:w-72">
                    <h1 className="text-white font-thid border-2 border-white rounded-lg p-2">You have already applied for a custom model for the: <span className="font-secondary">{openApplication.telegramName} </span> community.</h1>
                </div>
            )
        } else if (selectedRegisteredChannel === undefined) {
            return (
                <div className="flex justify-center items-center h-64 w-64 md:w-72 ">
                    <h1 className="text-white font-third border-2 border-white rounded-lg p-2">Please select a registered community from to apply.</h1>
                </div>
            )
        } else if (selectedRegisteredChannel !== undefined) {
            return (
                <div className="w-full flex justify-center items-center">
                    <div className="w-full">
                        <div className={profileState.customModelApplicationNetworkStatus === "" ? 'hidden flex justify-center my-4' : 'w-full flex justify-center my-4'}>
                            <h1 className="text-white font-third border-2 border-white rounded-lg p-2 w-48">{profileState.customModelApplicationNetworkStatus}</h1>
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <button onClick={() => submitCustomModelApplication((selectedRegisteredChannel !== undefined ? selectedRegisteredChannel.name : ""), dispatch, networkState)}
                                className="my-4 hover:cursor-pointer bg-gradient-to-r
                                from-white via-purple-300 to-teal-300
                                background-animate p-[2px] rounded-lg focus:outline-none border-transparent focus:border-transparent focus:ring-0">
                                    <div className="bg-black rounded-lg text-white font-third text-lg p-2">
                                        Submit Request
                                    </div>
                            </button>
                        </div>
                    </div> 
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function findAnyOpenApplication(): CustomModelApplication | undefined {
        return profileState.customModelApplications.find((application) => application.status === APLICATION_STATUS.OPEN)
    }

    function findSelectedChannelObject(): RegisteredChannel | undefined {
        let index = profileState.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === profileState.selectedChannelName.toLowerCase());
        if (index !== -1) {
            return profileState.registeredTgChats[index]
        } else {
            return undefined;
        }
    }

    function Header() {
        return (
            <div className="flex w-screen justify-center">
                <HeaderTextDiv/>
            </div>
        )
    }

    function HeaderTextDiv() {
        if (profileState.selectedModelSectionType === MODEL_VISIBILITY.BASE) {
            return (
                <div>
                    <h1 className="text-2xl font-secondary text-white my-1">{title()}</h1>
                    <h1 className="text-lg font-third text-gray-500">{disclaimer()}</h1>
                </div>            
            )
        } else if (profileState.selectedModelSectionType === MODEL_VISIBILITY.PUBLIC) {
            return (
                <div>
                    <h1 className="text-2xl font-secondary text-white">{title()}</h1>
                    <h1 className="text-lg font-third text-gray-500">{disclaimer()}</h1>
                </div>            
            )
        } else {
            return (
                <div>
                    <h1 className="text-2xl font-secondary text-white my-1">{title()}</h1>
                    <h1 className="text-lg font-third text-gray-500">{disclaimer()}</h1>
                </div>            
            )
        }
    }

    function title() {
        if (profileState.selectedModelSectionType === MODEL_VISIBILITY.BASE) {
            return "Request New Open Source Base Model"
        } else if (profileState.selectedModelSectionType === MODEL_VISIBILITY.PUBLIC) {
            return "Request Community Model"
        } else {
            return "Request Private Model"
        }
    }

    function disclaimer() {
        if (profileState.selectedModelSectionType === MODEL_VISIBILITY.BASE) {
            return "Once Approved all users and channels will have access."
        } else if (profileState.selectedModelSectionType === MODEL_VISIBILITY.PUBLIC) {
            return "Please note: these models can be made private after published."
        } else {
            return "Please note: these models can be made public after published."
        }
    }

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.MODEL_REQUEST, show: false}));
    }
}