export enum CHANNEL_TYPE {
    TELEGRAM,
}

export interface RegisteredChannel {
    chatId: number
    name: string
    chatUsername: string
    blacklistedModels: string[]
    type: CHANNEL_TYPE
    totalNumGenerations: number
    currentNumGenerationsLeft: number
    usedTrial: boolean
    isModifying: boolean
}

export function modifyChannelName(newName: string, channel: RegisteredChannel): RegisteredChannel {
    channel.name = newName;
    return channel;
}