import Web3 from "web3";
import presaleABI from "../../abis/test_Presale.json"
import { PRESALE_CONTRACT_ADDRESS, alreadyParticipated } from "./presalePurchase";
import { AbiItem } from "web3-utils";
import { Network } from "../../models";
import { getAddress } from "../user/authentication/ipVerification";
import { PresalePurchasingStatus, updatePresaleStatus, updatePresaleUserData } from "../../state/reducers/presale/presaleReducer";


export async function validatePresaleDataForUser(network: Network, dispatch: any) {
    
    let presaleContractInstance = null;
    let web3Connection = null;
    let participant = null;
    let totalParticipants = 0;
    let airdropAmount = 0;
    let totalRemainingAmount = 0;
    let exists = false;

    try {
        web3Connection = new Web3((network.web3Host));
        presaleContractInstance = new web3Connection.eth.Contract(presaleABI as AbiItem[], PRESALE_CONTRACT_ADDRESS);
        participant = await presaleContractInstance.methods.getParticipantsForAddress(network.selectedAccount).call();
        totalParticipants = parseInt(await presaleContractInstance.methods.getParticipantCountView().call());
        totalRemainingAmount = parseInt(await presaleContractInstance.methods.totalRemainingAirdropSupply().call());
    } catch (error) {
        dispatch(updatePresaleStatus({status: PresalePurchasingStatus.ERROR, errorMessage: "Error verifying you. Refresh, login, and try again."}));
        return;
    }

    if (participant) {
        airdropAmount = parseInt(participant.chosenSlot.airdropAmount)
    }

    let user = await getAddress();
    if (user && !exists) {
        exists = await alreadyParticipated(user, network.jwtToken);
    }

    if (exists) {
        dispatch(updatePresaleStatus({status: PresalePurchasingStatus.ALREADY_PARTICIPATED, errorMessage: "Already participated"}));
        dispatch(updatePresaleUserData({airdroptokenAmount: airdropAmount, totalParticipants: totalParticipants, totalRemainingAmount: totalRemainingAmount}))
        return;
    } else {
        dispatch(updatePresaleStatus({status: PresalePurchasingStatus.STANDBY, errorMessage: ""}));
        dispatch(updatePresaleUserData({airdroptokenAmount: 0, totalParticipants: totalParticipants, totalRemainingAmount: totalRemainingAmount}))
        return;
    }
}

export async function getPresaleChartData() {
    let presaleContractInstance = null;
    let web3Connection = null;
    try {
        web3Connection = new Web3(("https://eth.drpc.org"));
        presaleContractInstance = new web3Connection.eth.Contract(presaleABI as AbiItem[], PRESALE_CONTRACT_ADDRESS);
        return await presaleContractInstance.methods.totalRemainingAirdropSupply().call();
    } catch (error) {
        console.log("error fetching presale chart: " + error);
        return 0;
    }
}