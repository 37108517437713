import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { login } from '../../../networking/user';
import { useEffect } from 'react';
import { fetchAndUpdateUserArtState } from '../../../networking/art/userNetworking';
import Lottie from "lottie-react";
import ProfileHeader from '../header/profileHeader';
import connectWallet from "../../../lottieJson/connectWallet.json"
import { JobState } from '../../../state/reducers/artCreation/artCreationSubmissionReducer';
import { verifyIP } from '../../../networking/user/authentication/ipVerification';
import { useNavigate } from 'react-router-dom';
import { updateVerificationStatus } from '../../../state/reducers/network/networkReducer';
import GenerationCollectionSection from "../collections/gnerationCollectionSection";
import ProfileManagementSection from "./management/profileManagementSection";
import ProfileModelSection from './models/profileModelBody';

function ProfileBody() {

    const networkState = useAppSelector(state => state.network);
    const profileCollectionState = useAppSelector(state => state.profileCollection);
    const jobState = useAppSelector(state => state.artCreationSubmission);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        // This function will be called only once on component mount
        verifyUser();
      }, []); // Empty dependency array
    
    const verifyUser = async () => {
    // Your method logic here
        if (!networkState.ipVerificationChecked) {
            const result = await verifyIP(dispatch, networkState);
            if (result) {
                navigate("/unverified");
                dispatch(updateVerificationStatus(true));
            }   
        }
    };
    
    useEffect(() => {
        async function listenForCompletedJobs() {
            let intervalId = setInterval(async () => {
                try {
                    console.log("pending jobs found, listening for completion");
                    const pendingJobs = await fetchAndUpdateUserArtState(dispatch, networkState.selectedAccount, networkState.jwtToken);
                    if (jobState.jobLoading === JobState.GENERATING || !pendingJobs) {
                        clearInterval(intervalId);
                    }
                } catch (error) {
                    console.log("An error occured fetching data for user: " + error);
                }
        
            }, 5000)
        }

        async function fetchUserData() {
            if (networkState.selectedAccount !== "" && !profileCollectionState.initialProfileLoad) {
                const pendingJobs = await fetchAndUpdateUserArtState(dispatch, networkState.selectedAccount, networkState.jwtToken);
                if (pendingJobs) {
                    listenForCompletedJobs();
                }
            } 
            if (networkState.selectedAccount !== "" && !profileCollectionState.initialRewardLoad) {
                //updateClaimableTokensState(dispatch, networkState);
            }
        }
        fetchUserData();
    }, [dispatch, networkState, profileCollectionState, jobState])

    // CHANGE THIS FOR TESTING
    if (networkState.selectedAccount === "" || networkState.jwtToken === "") {
        return (
            <div className="flex items-center bg-spacey-gray justify-center min-h-screen min-w-screen pb-64 overflow-y-hidden">
                <div className="w-full">
                    <div className="flex items-center justify-center w-full my-4 text-center px-8">
                        <h1 className="font-third text-lg md:text-2xl">Sign in to save generation history and unlock premium</h1>
                    </div>
                    <div className="flex items-center justify-center w-full mt-8 text-center">
                        <button onClick={() => connectMetaMaskWallet(dispatch)} className="h-64 w-64 bg-space-gray rounded-lg transform transition-all hover:scale-105">
                            <Lottie animationData={connectWallet} loop={true} className="h-full w-full"></Lottie>
                        </button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <ProfileHeader></ProfileHeader>
                <div className="max-w-8xl flex justify-center bg-black md:pt-2 px-4">
                    <div className="mb-32 md:mb-16">
                        <ProfileManagementSection/>
                        <ProfileModelSection/>
                        <GenerationCollectionSection/>
                    </div>
                    <div className="min-h-screen bg-black"></div>
                </div>
            </div>
        )
    }

    function connectMetaMaskWallet(dispatch: any) {
        login(dispatch);
    }
    
}

export default ProfileBody;