import { createSlice } from "@reduxjs/toolkit";
import { ArtCreationFilter } from "../../../models/art/artCreationFilter";
import { TagBoxType } from "../tag/tagSearchReducer";
import { updateTagStrengthDisplayText, updateTagStrengthRawText } from "../../../models/art/promptTag";
   

// state to keep track of user art creation attribute filters
export const initialFilterState: ArtCreationFilter = {
    generationType: "prompt",
    model: "Default",
    loras: [],
    gasFees: "",
    loadingGasFees: false,
    maxTagLength: 50,
    nsfw: false,
    lastTagBoxSelected: TagBoxType.PROMPT,
    isTyping: false,
    isSliding: false,
    prompt: [],
    negativePrompt: [],
    promptStrength: 5,
    width: 512,
    height: 512,
    steps: 100,
    numImages: 1,
    lastNumImages: 1
}

const artCreationFilterSlice = createSlice({
    name: "artCreationFilters",
    initialState: initialFilterState,
    reducers: {
        updateArtWidth: (state, action) => {
            state.width = action.payload
            state.isTyping = false;
        },
        updateArtHeight: (state, action) => {
            state.height= action.payload
            state.isTyping = false;
        },
        updateGenerationType: (state, action) => {
            state.generationType = action.payload
            state.isTyping = false;
        },
        updateModelSelection: (state, action) => {
            state.model = action.payload
            state.isTyping = false;
        },
        updateLoraSelection: (state, action) => {
            if (state.loras.includes(action.payload.toLowerCase())) {
                state.loras = state.loras.filter(item => !item.includes(action.payload.toLowerCase()))
            } else {
                state.loras.push(action.payload.toLowerCase())
            }
            state.isTyping = false;
        },
        updateNumImages: (state, action) => {
            state.numImages = action.payload
            state.isTyping = false;
        },
        updateLastNumImages:(state, action) => {
            state.lastNumImages = action.payload
            state.isTyping = false;
        },
        
        pushToPromptTags: (state, action) => {
            state.prompt.push(action.payload)
            state.lastTagBoxSelected = TagBoxType.PROMPT
            state.isTyping = true;
        },
        overwritePromptTags:(state, action) => {
            state.prompt = action.payload;
        },
        pushToNegativePromptTags: (state, action) => {
            state.negativePrompt.push(action.payload)
            state.lastTagBoxSelected = TagBoxType.NEGATIVE_PROMPT
            state.isTyping = true;
        },
        overwriteNegativePromptTags:(state, action) => {
            state.negativePrompt = action.payload;
        },
        // updating prompt tag strengths
        updatePromptTagStrength: (state, action) => {
            const item = state.prompt.find((promptTag) => promptTag.id === action.payload.id);
            if (item != null) {
                item.strength = action.payload.strength;
                item.rawText = updateTagStrengthRawText(item.displayText, action.payload.strength);
                item.displayText = updateTagStrengthDisplayText(item.displayText, action.payload.strength);
            }            
        },
        updateNegativePromptTagStrength: (state, action) => {
            const item = state.negativePrompt.find((promptTag) => promptTag.id === action.payload.id);
            if (item != null) {
                item.strength = action.payload.strength;
                item.rawText = updateTagStrengthRawText(item.displayText, action.payload.strength);
                item.displayText = updateTagStrengthDisplayText(item.displayText, action.payload.strength);
            }   
        },
        updateIsSlidingPromptState: (state, action) => {
            state.isSliding = action.payload;
        },
        // removing prompt tags
        removePromptTag: (state, action) => {
            state.prompt = state.prompt.filter(value => value.id !== action.payload);
            state.lastTagBoxSelected = TagBoxType.PROMPT
        },
        removeNegativePromptTag: (state, action) => {
            state.negativePrompt = state.negativePrompt.filter(value => value.id !== action.payload);
            state.lastTagBoxSelected = TagBoxType.NEGATIVE_PROMPT  
        },
        removeLastPromptTag: (state) => {
            if (state.prompt.length > 0) {
                state.prompt.pop();
            }
            state.lastTagBoxSelected = TagBoxType.PROMPT
        },
        removeLastNegativePromptTag: (state) => {
            if (state.negativePrompt.length > 0) {
                state.negativePrompt.pop();
            }
            state.lastTagBoxSelected = TagBoxType.NEGATIVE_PROMPT
        },
        // clear
        clearPromptTags: (state) => {
            state.prompt = []
            state.isTyping = false;
        },
        // div focus help
        clearNegativePromptTags: (state) => {
            state.negativePrompt = []
            state.isTyping = false;
        },
        updateIsTypingPromptState: (state, action) => {
            state.isTyping = action.payload;
        },
        // attributes
        updatePromptStrength: (state, action) => {
            state.promptStrength = action.payload
            state.isTyping = false;
        },
        updateInferenceSteps: (state, action) => {
            state.steps = action.payload
            state.isTyping = false;
        },
        // nsfw
        updateNSFWStatus: (state, action) => {
            state.nsfw = action.payload
            state.loras = []
            state.isTyping = false;
        },
        // gas fees
        updateGasFees: (state, action) => {
            state.gasFees = action.payload;
        },
        toggleLoadingGasFees: (state, action) => {
            state.loadingGasFees = action.payload;
        }
    }
})

export default artCreationFilterSlice.reducer;
export const { 
    updateArtWidth,
    updateArtHeight,
    updateNumImages,
    updateGenerationType,
    updateModelSelection,
    updateLoraSelection,
    updatePromptStrength,
    updateInferenceSteps,
    pushToPromptTags,
    pushToNegativePromptTags,
    removePromptTag,
    removeNegativePromptTag,
    clearPromptTags,
    clearNegativePromptTags,
    removeLastNegativePromptTag,
    removeLastPromptTag,
    updateNSFWStatus,
    updateLastNumImages,
    updateIsTypingPromptState,
    updateGasFees,
    updateIsSlidingPromptState,
    toggleLoadingGasFees,
    updateNegativePromptTagStrength,
    updatePromptTagStrength,
    overwritePromptTags,
    overwriteNegativePromptTags
} = artCreationFilterSlice.actions;