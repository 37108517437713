import { useDispatch } from "react-redux";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { 
    updateArtModalInitialLoad, 
    updateArtModalSelection, 
    updateModalState 
} from "../../state/reducers/modal/modalReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DaysSinceISODateString } from "../../utils/date";
import { TagBoxType } from "../../state/reducers/tag/tagSearchReducer";
import { faCopy, faHeart as outlineHeart } from "@fortawesome/pro-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/pro-solid-svg-icons";
import { toggleArtSave } from "../../networking/user/art/saveArt";
 
function ArtModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const networkState = useAppSelector(state => state.network);
    const profileState = useAppSelector(state => state.profileCollection);

    const width = modalState.userArt.art.width.toString();
    const height = modalState.userArt.art.height.toString();
    
    const dynamicStyle = {
        width: width + "px",
        height: height + "px"
    }

    const dynamicWidth = {
        width: width + "px",
    }

    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.ART} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-8 md:mx-16 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div>
                                    <div className="flex justify-center items-center w-full">
                                        <div className="">
                                            <MainImage/>
                                            <TimeBar/>
                                            <ImageSelectionBar/>
                                            <InfoBar/>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function MainImage() {
        return (
            <div className="m-2">
                <div className={`hidden md:flex rounded-lg`} style={dynamicStyle}>

                    <img src={modalState.userArt.art.s3ImageUrls[modalState.userArt.selectedImageIndex]}
                    alt="" 
                    className="w-full object-cover rounded-lg"
                    />
                    
                </div>
                <div className={`flex md:pt-4 md:px-2 rounded-lg md:hidden`}>

                    <img src={modalState.userArt.art.s3ImageUrls[modalState.userArt.selectedImageIndex]}
                    alt="" 
                    className="w-full rounded-lg"
                    />
                    
                </div>
                
            </div>
        )
    }

    function ImageSelectionBar() {
        if (modalState.userArt.art.numImages > 1) {
            return (
                <>
                <div className="m-2">
                     <div className={"hidden md:flex justify-start items-center"} style={dynamicWidth}>
                    {modalState.userArt.art.s3ImageUrls.map((result, index) => {
                        return ImageSelector(result, index);
                    })}
                    </div>
                </div>
                <div className="m-2">
                     <div className="flex w-full justify-start items-center md:hidden">
                    {modalState.userArt.art.s3ImageUrls.map((result, index) => {
                        return ImageSelector(result, index);
                    })}
                    </div>
                </div>
                </>
               
            )
        } else {
            return (
                <></>
            )
        }
    }

    function ImageSelector(imageUrl: string, index: number) {
        return (
            <div
                className={`flex w-1/4 rounded-lg p-2 ${index === modalState.userArt.selectedImageIndex ? 
                    "bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px]" : 
                    "bg-black p-[2px]"}`}
            >
                <button onClick={() => updateSelectedImage(index)} className="flex w-full">
                    <img src={imageUrl} alt="" className="w-full rounded-lg" />
                </button>
            </div>
        )
    }

    function TimeBar() {
        return (
            <div className="flex items-center justify-between w-full px-2 py-1">
                <div className="flex items-center px-2">
                    <h1 className="text-xs md:text-sm text-white font-secondary ">
                        {modalState.userArt.art.width} (W) x {modalState.userArt.art.height} (L)
                     </h1>
                </div>
                <div className="flex items-center">
                    <div className="flex items-center pr-1">
                        <FavoriteButton/>
                    </div>
                    <div className="flex items-center pl-1 pr-2">
                        {DaysSinceISODateString(modalState.userArt.art.dateCreated)}
                    </div>
                </div>
            </div>
        )
    }

    function FavoriteButton() {
        if (profileState.savedCollection && profileState.savedCollection.length > 0 && profileState.savedCollection.some((art) => art.jobId === modalState.userArt.art.jobId)) {
            return (
                <FontAwesomeIcon onClick={() => handleArtSaving(false)} icon={solidHeart}size='lg' color='white' className="cursor-pointer px-1"/>
            )
        } else {
            return (
                <FontAwesomeIcon onClick={() => handleArtSaving(true)} icon={outlineHeart}size='lg' color='white' className=" cursor-pointer px-1"/>
            )
        }
    }

    function InfoBar() {
        return (
            <div className="flex items-center w-full px-2 md:pt-4">
                <div>
                    <div className="w-full">
                        <div className="flex">
                            <h1 className="mx-2 pt-1 pb-2 text-left text-sm text-white font-primary underline underline-offset-8">
                                Prompt
                            </h1>
                            <button onClick={() => handleCopyToClipboard(TagBoxType.PROMPT)} className="transform transition-all hover:scale-105 focus:ring-0 focus:outline-none">
                                <FontAwesomeIcon icon={faCopy}size='lg' color='white' className="px-1"/>
                            </button>
                        </div>
                        {Prompt(TagBoxType.PROMPT)}
                    </div>
                    {
                    modalState.userArt.art.negativePrompt.length !== 0 ? 
                        <div>
                            <div className="w-full">
                                <div className="flex">
                                    <h1 className="mx-2 pt-1 pb-2 text-left text-sm text-white font-primary underline underline-offset-8">
                                        Negative Prompt
                                    </h1>
                                    <button onClick={() => handleCopyToClipboard(TagBoxType.NEGATIVE_PROMPT)} className="transform transition-all hover:scale-105">
                                        <FontAwesomeIcon icon={faCopy}size='lg' color='white' className="px-1"/>
                                    </button>
                                </div>
                                {Prompt(TagBoxType.NEGATIVE_PROMPT)}
                            </div>
                        </div>
                    :
                        <></>
                    }
                    <div className="w-full px-2">
                        <div className="w-1/2">
                            <div className="w-full">
                                <h1 className="pt-1 pb-2 text-left text-sm text-white font-primary underline underline-offset-8">
                                    Details
                                </h1>
                            </div>
                            <div className="w-full">
                                <div className="w-full py-1">
                                    <h1 className="w-full h-full text-xs md:text-sm text-white font-third">
                                        Steps: {modalState.userArt.art.steps}
                                    </h1>
                                </div>
                                <div className="w-full py-1">
                                    <h1 className="w-full h-full text-xs md:text-sm text-white font-third">
                                        Strength: {modalState.userArt.art.promptStrength}
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 pb-4">
                            <div className="w-full">
                                <h1 className="pt-1 pb-2 text-left text-sm text-white font-primary underline underline-offset-8">
                                    Model
                                </h1>
                            </div>
                            <div className="w-full py-1">
                                <h1 className="w-full h-full text-xs md:text-sm text-white font-third">
                                    {modalState.userArt.art.model.replaceAll("_", " ")}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        )
    }

    function Prompt(type: TagBoxType) {
        const tags = type === TagBoxType.PROMPT ? modalState.userArt.art.prompt : modalState.userArt.art.negativePrompt
        return (
            <>
            <ul className="flex flex-wrap w-full my-1 md:hidden">
                {tags.map(tag => {
                    return (
                        TagItem(tag.displayText)
                    )
                })} 
            </ul>
            <ul className="hidden md:flex flex-wrap w-full my-1" style={dynamicWidth}>
                {tags.map(tag => {
                    return (
                        TagItem(tag.displayText)
                    )
                })} 
            </ul>
            </>
        )
        
    }

    function TagItem(text: string) {
        return (
            <li  className="bg-gradient-to-r
            from-white via-purple-300 to-teal-300 background-animate p-[1px] mx-1 my-1 rounded-lg text-sm md:text-lg font-third">
            <p className="w-full h-full px-2 py-1 rounded-lg bg-black text-white">{displayTag(text)}</p>
        </li>
        )
    }


    function updateSelectedImage(index: number) {
        dispatch(updateArtModalSelection(index));
    }

    function handleArtSaving(isSaving: boolean) {
        if (networkState.jwtToken !== "") {
            toggleArtSave(isSaving, dispatch, modalState.userArt.art, networkState);
        }
    }

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.ART, show: false}));
        dispatch(updateArtModalInitialLoad(false));
    }

    function handleCopyToClipboard(type: TagBoxType) {
        if (type === TagBoxType.PROMPT) {
            navigator.clipboard.writeText(modalState.userArt.art.prompt.map(art => art.rawText).join(','));
         } else {
            navigator.clipboard.writeText(modalState.userArt.art.negativePrompt.map(art => art.rawText).join(','));
         }
    }

    function displayTag(tagText: string) {
        let str = tagText;
        str = str.replace(/_/g, " ");
        return str
    }
}

export default ArtModal;