import { useDispatch } from "react-redux";
import { UserArt } from "../../../models";
import { MODAL_TYPE } from "../../../models/modal";
import { useAppSelector } from "../../../state/hooks";
import { updateArtModalSelection, updateArtModalState } from "../../../state/reducers/modal/modalReducer";

export default function RecentCollection() {

    const profileState = useAppSelector(state => state.profileCollection);
    const dispatch = useDispatch();

    return (
        profileState.collection && profileState.collection.length > 0) ?
            (profileState.collection.map((collectionArt) => ( <CollectionCard{...collectionArt}/>)))
        : (
            <PlaceHolder/>
    )
   
    function CollectionCard(props: UserArt) {
        return (
            <div className="flex justify-center items-center cursor-pointer w-full h-full transform transition-all hover:scale-105">
                <div onClick={() => showArtSelection(props)} className="
                rounded-lg bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate p-[2px] md:p-[4px]">
                    <div className="flex items-center justify-center w-full h-full bg-black rounded-lg">
                        {
                            props.s3ImageUrls.length > 0 ?  UserArtPreview(props) : <LoadingUserArt/>
                        }
                    </div>
                </div>
            </div>
        )
    }

    function UserArtPreview(props: UserArt) {
        return (
            <img src={props.s3ImageUrls.length > 0 ? props.s3ImageUrls[0] : ""} alt="" className="w-full h-full object-none rounded-lg"></img>
        )
    }

    function LoadingUserArt() {
        return (
            <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                <div className="h-12 w-12 rounded-full bg-black"></div>
            </div>
        )
    }

    function showArtSelection(artSelection: UserArt) {
        if (artSelection.s3ImageUrls.length > 0) {
            dispatch(updateArtModalSelection(0));
            dispatch(updateArtModalState({type: MODAL_TYPE.ART, show: true, userArt: artSelection}));
        }
    }

    function PlaceHolder() {
        return (
            <div className="cursor-pointer h-48 w-32 sm:h-48 sm:w-36 md:w-48 md:h-64 lg:h-72 lg:w-64 flex">
                <div className="flex items-center justify-center w-full h-full bg-black rounded-lg"></div>
            </div>
        )
    }
}