import { useAppSelector } from "../../state/hooks";
import { MODEL_TYPE, MODEL_VISIBILITY } from "../../models/art/artModel";
import StandardModelCard, { MODEL_CARD_TYPE } from "../model/standardModelCard";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadPubliclyVisibleModels } from "../../networking/models/modelNetworking";

export default function CommunityBody() {

    const artModelState = useAppSelector(state => state.artModelState);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchArtData() {
            if (!artModelState.isLoaded) {
                await loadPubliclyVisibleModels(dispatch);
            }
        }
        fetchArtData();
    }, [dispatch, artModelState.isLoaded])

    return (
        <div className="w-max-8xl min-h-screen mx-auto bg-space-gray pb-32">
            <div className="flex pb-4 pt-8 w-full justify-center items-center">
                <h1 className="font-primary font-bold text-4xl md:text-6xl
                text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">Community</h1>
            </div>
            <div className="flex justify-center items-center w-full pb-4">
                <div className="w-1/2">
                    <h1 className="font-secondary text-white text-center font-bold text-sm sm:text-md md:text-lg md:text-xl
                    ">Explore Custom AI Models Created by Crypto Communities and Users</h1>
                </div>
            </div>
            <DisplayPublicModels/>
        </div>
    )

    function DisplayPublicModels() {
        if (artModelState.isLoaded) {
            return (
                <div className="flex justify-center">
                    <div className="bg-black grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 md:p-4 rounded-lg">
                        {artModelState.models.map((model) =>  
                            (model.modelVisibility === MODEL_VISIBILITY.PUBLIC && model.modelType === MODEL_TYPE.LORA && model.communityUrl !== "") ?  StandardModelCard(model, MODEL_CARD_TYPE.COMMUNITY) : <></>    
                        )}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flex w-full justify-center items-center mt-8">
                    <LoadingCommunityModels/>
                </div>
            )
        }
        
    }

    function LoadingCommunityModels() {
        return (
            <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                <div className="h-12 w-12 rounded-full bg-space-gray"></div>
            </div>
        )
    }
}