export enum CHAIN_ID {
    ETH = 1,
    ETH_SEPOLIA = 11155111,
    MATIC = 137
}

export interface Network {
    selectedChain: CHAIN_ID
    selectedAccount: string
    jwtToken: string
    web3Host: string
    contractAddress: string
    ethTokenContractAddress: string
    tierContractAddress: string
    nftContractAddress: string
    tokenContractAddress:string
    presaleContractAddress: string
    ipVerificationChecked: boolean
    isNetworkCoolingDown: boolean
}

export interface AvailableNetworks {
    network: CHAIN_ID
}
export const availableNetworks: AvailableNetworks[] = [
    { network: CHAIN_ID.ETH_SEPOLIA }
]
