import Web3 from "web3";
import tierPurchaseABI from "../../abis/test_TierPurchase.json";
import usdcABI from "../../abis/test_USDC.json";

import { AbiItem, toHex } from "web3-utils";

import { TierLevel } from "../../models/tier/tier";
import { Network } from "../../models";
import { TierPurchasingStatus, updateTierPurchasingStatus } from "../../state/reducers/tier/tierReducer";
import HandleAlert, { errorAlert, successAlert } from "../../utils/handleAlert";
import { verifyAndUpdateUserTier } from "./updateUserTier";
import { updateUserTierNameAfterPurchase } from "../../state/reducers/user/userProfileReducer";

const USDC_CONTRACT_ADDRESS = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
const TIER_CONTRACT_ADDRESS = "0x430188250308F0F474B85B5b3D8e649D6c50fd8b"

export interface GasEstimate {
    estimate: number
    errorMessage: string
}

export interface PurchaseReceipt {
    receipt: any
    errorMessage: string
}

export interface UserVerificationResult {
    status: string
    errorMessage: string
}

export interface UserSyncResult {
    status: string
    errorMessage: string
}

export async function handleTierLevelPurchase(tierLevel: TierLevel, networkState: Network, dispatch: any) {
    let receiptObject = await purchaseCommunityTierLevel(tierLevel, networkState, dispatch);
    if (receiptObject.receipt === null) {
        let errorMessage = receiptObject.errorMessage;
        if (errorMessage == null) {
            errorMessage = "Network error, please log back in to sync your account."
        }
        updatePurchaseStatus(TierPurchasingStatus.ERROR, errorMessage, dispatch);
        HandleAlert(errorAlert("Error: " + errorMessage), dispatch);
        return;
    }

    let verification = await verifyAndUpdateUserTier(receiptObject.receipt.transactionHash, networkState, tierLevel);
    if (verification.status === null || (verification.status && verification.status === "ERROR")) {
        let errorMessage = verification.errorMessage;
        if (errorMessage === "") {
            errorMessage = "Error: verifying user purchase, please retry or log back in."
        }
        updatePurchaseStatus(TierPurchasingStatus.ERROR, verification.errorMessage, dispatch);
        HandleAlert(errorAlert("Error: verifying user purchase"), dispatch);
    } else {
        updatePurchaseStatus(TierPurchasingStatus.STANDBY, receiptObject.errorMessage, dispatch);
        dispatch(updateUserTierNameAfterPurchase(tierLevel.name));
        HandleAlert(successAlert("Successfully purchased " + tierLevel.name.toUpperCase() + " tier."), dispatch)
    }
}

export default async function purchaseCommunityTierLevel(tierLevel: TierLevel, networkState: Network, dispatch: any): Promise<PurchaseReceipt> {
    
    let tierContractInstance = null;
    let USDCContractInstance = null;
    let web3Connection = null;

    // ESTABLISHING WEB3 CONNECTIOn
    updatePurchaseStatus(TierPurchasingStatus.ESTABLISHING_CONNECTION, "", dispatch);
    try {
        //TODO: double check this
        //@ts-ignore
        web3Connection = new Web3((window.ethereum));

        tierContractInstance = new web3Connection.eth.Contract(tierPurchaseABI as AbiItem[], TIER_CONTRACT_ADDRESS);
        USDCContractInstance = new web3Connection.eth.Contract(usdcABI as AbiItem[], USDC_CONTRACT_ADDRESS);
    } catch (e) {
        return  {
            receipt: null,
            errorMessage: "An issue went wrong establishing Web3 connection."
        }
    }

    // CHECKING USDC BALANCE
    updatePurchaseStatus(TierPurchasingStatus.CHECKING_BALANCE, "", dispatch);
    let apporvalCheckError = await handleApproval(networkState, tierLevel, USDCContractInstance, dispatch);
    if (apporvalCheckError !== null) {
        return  {
            receipt: null,
            errorMessage: apporvalCheckError
        }
    }
    
    // ESTIMATING GAS
    updatePurchaseStatus(TierPurchasingStatus.ESTIMATING_PURCHASE_GAS, "", dispatch);
    let purchaseStatusAndEstimate = await estimateGasAndHandlePurchaseError(web3Connection, tierContractInstance, networkState.selectedAccount, tierLevel);
    if (purchaseStatusAndEstimate.errorMessage !== null || purchaseStatusAndEstimate.estimate === null) {
        console.log(purchaseStatusAndEstimate.errorMessage);
        return  {
            receipt: null,
            errorMessage: purchaseStatusAndEstimate.errorMessage
        }
    }

    return purchaseTier(tierLevel, networkState, tierContractInstance, purchaseStatusAndEstimate.estimate, dispatch);
}

export async function purchaseTier(tierLevel: TierLevel, networkState: Network, tierContractInstance: any, gasEstimate: any, dispatch: any): Promise<PurchaseReceipt> {
    let errorMessage = null;
    let purchaseHash = null;
    let purchaseReceipt = null;
    
    const purchaseTierTransactionObject = tierContractInstance.methods.purchasePremium(tierLevel.name).encodeABI();
    let transactionParameters = {
        from: networkState.selectedAccount,
        to: TIER_CONTRACT_ADDRESS,
        data: purchaseTierTransactionObject,
        gas: toHex(gasEstimate)
    };
    
    // PURCHASING TIER
    updatePurchaseStatus(TierPurchasingStatus.AWAITING_PURCHASE_CONFIRMTION, "", dispatch);
    try {
        //@ts-ignore
        purchaseHash = await window.ethereum.request({
            //@ts-ignore
            method: 'eth_sendTransaction',
            //@ts-ignore
            params: [transactionParameters],
        })
    } catch (error) {
        //@ts-ignore
        errorMessage = handleError(error.message);
    }

    if (purchaseHash === null || errorMessage !== null) {
        return {
            receipt: null,
            errorMessage: "Error handling purchase request. Please try again."
         } 
    }  

    // PURCHSAE RECEIPT WAITING
    updatePurchaseStatus(TierPurchasingStatus.AWAITING_PURCHASE_RECEIPT, "", dispatch);
    purchaseReceipt = await getReceipt(purchaseHash);
    if (purchaseReceipt === null) {
        return {
            receipt: null,
            errorMessage: "Error fetching receipt. Please log back in to check tier status."
        }
    }

    return {
        receipt: purchaseReceipt,
        errorMessage: null
    };
}

export async function estimateGasAndHandlePurchaseError(web3Connection: any, tierContractInstance: any, spenderAddress: string, tierLevel: TierLevel): Promise<GasEstimate> {
    const purchaseTierTransactionObject = tierContractInstance.methods.purchasePremium(tierLevel.name).encodeABI();
    let gasEstimate = null;
    let errorMessage = null;
    try {
        gasEstimate = await web3Connection.eth.estimateGas({
            from: spenderAddress,
            to: TIER_CONTRACT_ADDRESS,
            data: purchaseTierTransactionObject,
        });
    } catch (error) {
        console.log(error);
        errorMessage = handleError(error.message)
    }
    return {
        errorMessage: errorMessage,
        estimate: gasEstimate
    };
}

export async function handleApproval(networkState: Network, tierLevel: TierLevel, USDCContractInstance: any, dispatch: any): Promise<string | null> {
    let errorMessage = null;
    let alreadyHasAllowance = await hasAllowance(networkState.selectedAccount, USDCContractInstance, TIER_CONTRACT_ADDRESS, tierLevel.price);
    let approvalHash = null;
    if (!alreadyHasAllowance) {
        // AWAITING APROVAL
        updatePurchaseStatus(TierPurchasingStatus.AWAITING_APPROVAL_CONFIRMATION, "", dispatch);
        approvalHash = await approveTierAmount(tierLevel, USDCContractInstance, networkState.selectedAccount);
    }

    if (!alreadyHasAllowance && approvalHash === null) {
        errorMessage = "Hash not received for approving USDC spending."
    }
    let approvalReceipt = null;
    if (!alreadyHasAllowance && approvalHash) {
        // AWAITING APROVAL RECEIPT
        updatePurchaseStatus(TierPurchasingStatus.AWAITING_APPROVAL_RECEIPT, "", dispatch);
        approvalReceipt = await getReceipt(approvalHash);
    }

    if (!alreadyHasAllowance && approvalReceipt === null) {
        errorMessage = "Receipt not received for approving USDC spending."
    }
    return errorMessage;
}

export async function approveTierAmount(tierLevel: TierLevel, USDCContractInstance: any, spenderAddress: string) {
    let approvalHash = null;
    const approveTransactionObject = USDCContractInstance.methods.approve(TIER_CONTRACT_ADDRESS, tierLevel.price * (1e6)).encodeABI();
    let transactionParameters = {
        from: spenderAddress,
        to: USDC_CONTRACT_ADDRESS,
        data: approveTransactionObject,
        gas: toHex(300000),
    };
    try {
        //@ts-ignore
        approvalHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        }).catch((error: any) => {
            console.log(error);
        })
    } catch (e) {
        console.log("Error approving request: " + e);
    }
    return approvalHash;
}

export async function hasAllowance(spenderAddress: string, tokenContractInstance: any, spenderContract: string, tierPrice: number): Promise<boolean> {
    try {   
        let allowance = await tokenContractInstance.methods.allowance(spenderAddress, spenderContract).call();
        const allowanceBigInt = BigInt(allowance);
        const tierPriceBigInt = BigInt(tierPrice * 1e6);
        return allowanceBigInt >= tierPriceBigInt;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function getReceipt(hash: any): Promise<any> {
    let counter = 0;
    let receipt =  null;
    // wait around a minute before returning null
    while (true && counter <= 30) {
        //@ts-ignore
        receipt = await window.ethereum.request({
            method: 'eth_getTransactionReceipt',
            params: [hash],
        });
        counter++;
        // Wait for 2 seconds before checking again
        await new Promise((resolve) => setTimeout(resolve, 2000));
    }
    return receipt;
}

export function handleError(errorLog: string) {
    console.log("error log: " + errorLog);
    if (errorLog.toLowerCase().includes("you can only purchase the same tier or upgrade")) {
        return "You can only upgrade or purhcase the same tier. 30 days must past before downgrading.";
    } else if (errorLog.toLowerCase().includes("spending not approved")) {
        return "You have not approved spending USDC yet."
    } else if (errorLog.toLowerCase().includes("insufficient usdc")) {
        return "Insufficient USDC balance."
    } else if (errorLog.toLowerCase().includes("transfer of funds failed.")) {
        return "Transferring USDC failed. Please try again";
    } else if (errorLog.toLowerCase().includes("user denied transaction signature")) {
        return "User denied transaction signature."
    } else {
        return "An issue occured, please try again."
    }
}

export function updatePurchaseStatus(statusType: TierPurchasingStatus, errorMessage: string, dispatch: any) {
    dispatch(updateTierPurchasingStatus({status: statusType, errorMessage: errorMessage}));
}