

export default function LucidAILogoLarge(isFireFox: boolean) {

    let AIlogoClass = "";
    let lucidLogoClass = "";

    if (isFireFox) {
        lucidLogoClass = "text-white text-4xl md:text-6xl mr-1 md:mr-2 md:pt-[10px] font-primary"
        AIlogoClass = "text-white text-4xl md:text-6xl pr-[4px] md:pr-[5px] pl-[2px] md:pl-[3px] pt-[5px] md:pt-[10px] rounded-lg bg-black font-primary text-center"
    } else {
        lucidLogoClass ="text-white text-4xl md:text-6xl mr-1 md:mr-2 font-primary"
        AIlogoClass = "text-white text-4xl md:text-6xl py-1 md:py-3 pl-[5px] md:pl-[6px] pr-[8px] md:pr-[9px] rounded-lg bg-black font-primary"
    }

    return (
        <div className="flex items-center justify-center pb-4">
            <h1 className={lucidLogoClass}>LUCID</h1>
            <div className="text-gray-300 p-[1px] md:p-[3px] rounded-lg 
                bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate"
                >
                <h1 className={AIlogoClass}>AI</h1>
            </div>
        </div>
    )
}