import HomeCollection from '../homeCollection/homeCollection';
import NSFWHomeSwitch from './nfswHomeSwitch';
import LucidAILogoLarge from '../../logo/lucidAILargeLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimer, faAngleUp, faAngleDown } from '@fortawesome/pro-solid-svg-icons'
import { 
    faTwitter, 
    faTelegram, 
    faDiscord,
    faTiktok,
    faMedium
} from '@fortawesome/free-brands-svg-icons';
import { useRef, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { scrollIntoView } from "seamless-scroll-polyfill";
import { useAppSelector } from '../../../state/hooks';
import { useDispatch } from 'react-redux';
import { loadHomeCollection } from '../../../networking/art';
import { updateModalState } from '../../../state/reducers/modal/modalReducer';
import { MODAL_TYPE } from '../../../models/modal';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons';
import { clearSearchTags, setScrolledToBottomFlag, toggleAscendingFilter } from '../../../state/reducers/collection/homeCollectionReducer';
import { useNavigate } from 'react-router-dom';
import { verifyIP } from '../../../networking/user/authentication/ipVerification';
import { updateVerificationStatus } from '../../../state/reducers/network/networkReducer';



function HomeBody() {

    const WHITE_PAPER="https://medium.com/@lucid.ai.engineer/lucid-ai-litepaper-4f1616a423ca"
    const TELEGRAM="https://t.me/lucidAIOfficial"
    const TIKTOK="https://tiktok.com/@lucidAIArTTikTok"
    const DISCORD="https://discord.gg/pkGY3unbWA"
    const TWITTER="https://twitter.com/LucidAIArtX"

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchBarRef = useRef(null);

    const homeCollectionState = useAppSelector(state => state.homeCollection);
    const networkState = useAppSelector(state => state.network);
    
    useEffect(() => {
        // This function will be called only once on component mount
        verifyUser();
      }, []); // Empty dependency array
    
    const verifyUser = async () => {
    // Your method logic here
        if (!networkState.ipVerificationChecked) {
            const result = await verifyIP(dispatch, networkState);
            if (result) {
                navigate("/unverified")
                dispatch(updateVerificationStatus(true));
            }   
        }
    };

    // initial load
    useEffect(() => {
        async function fetchArtData() {
            if (!homeCollectionState.initialLoad) {
                loadHomeCollection(dispatch, "", homeCollectionState.asc, true);
            }
        }
        fetchArtData();
    }, [dispatch, homeCollectionState.asc, homeCollectionState.initialLoad])

     // additional loads on scroll
    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (bottom && !homeCollectionState.scrolledToBottom) {
            dispatch(setScrolledToBottomFlag(true));
            const lastResult = homeCollectionState.collection[homeCollectionState.collection.length - 1]
            if (lastResult) {
                const lastTimestamp = lastResult.dateCreated;
                loadHomeCollection(dispatch, lastTimestamp, homeCollectionState.asc, false);
            }
        } 
      };
      
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      },);


    const [showScollUp, setShowScrollUp] = useState(false);

      const toggleScrollUpButton = () => {
          if (window.scrollY >= window.screen.height) {
            setShowScrollUp(true);
          } else {
            setShowScrollUp(false);
          }
      }

    window.addEventListener('scroll', toggleScrollUpButton);

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    return (
        <div className="w-max-8xl min-h-screen mx-auto bg-space-gray">
            <div className="w-full pt-8 md:pb-4">
                {LucidAILogoLarge(isFirefox)}
                <div className="flex justify-center md:pt-2 pb-2 px-4">
                    <div className="text-center">
                        <h1 className="text-md md:text-xl text-white font-secondary">A community centered</h1>
                        <div className="flex justify-center">
                            <h1 className="text-md md:text-xl text-white font-secondary">AI platform accepting crypto</h1>
                        </div>
                    </div>
                </div>
                {/* Socials */ }
                <div className="w-full flex justify-center pt-4 pb-32 md:pb-24">
                    <div>
                        <div className="flex justify-center px-2 py-4">
                            <div className="flex items-center mx-2">
                                <a  href={TELEGRAM} target="_" className="flex items-center">
                                    <FontAwesomeIcon icon={faTelegram} color='#FFFFFF' className="fa-lg mx-2"/>
                                </a>
                            </div>
                            <div className="flex items-center mx-2">
                                <a href={TIKTOK} target="_" className="flex items-center">
                                    <FontAwesomeIcon icon={faTiktok} color='#FFFFFF' className="fa-lg mx-2"/>
                                </a>
                            </div> 
                            <div className="flex items-center mx-2">
                                <a href={TWITTER} target="_" className="flex items-center">
                                    <FontAwesomeIcon icon={faTwitter} color='#FFFFFF' className="fa-lg mx-2"/>
                                </a>
                            </div> 
                        </div>
                        <div className="flex justify-center px-2 py-4">
                            <div className="flex items-center mx-2">
                                <a href={WHITE_PAPER} target="_" className="flex items-center">
                                    <FontAwesomeIcon icon={faMedium} color='#FFFFFF' className="fa-lg mx-2"/>
                                </a>
                            </div>
                            <div className="flex items-center mx-2">
                                <a href={DISCORD} target="_" className="flex items-center">
                                    <FontAwesomeIcon icon={faDiscord} color='#FFFFFF' className="fa-lg mx-2"/>
                                </a>
                            </div> 
                        </div>
                    </div>
                </div>
                
                {/* Desktop Filter */}
                <Filters/>
                {/* Collection */}
                <div ref={searchBarRef} className="scroll-mt-14">
                    <HomeCollection/>     
                </div>    
            </div>
            {/* scroll up button */}
            <Transition
                show={showScollUp}
                enter="transition-opacity duration-250"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-250"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className={showScollUp ? "md:hidden fixed bottom-20 right-0 h-16 w-24" : "hidden"}>
                    <div className="flex justify-center items-center w-full h-full">
                        <button className="h-12 w-12 bg-black rounded-lg shadow-2xl mr-1 mb-1" onClick={() => scrollToSearch()}>
                            <FontAwesomeIcon icon={faAngleUp} color='#FFFFFF' size="xl" className=""/>
                        </button>
                    </div>
                </div>
            </Transition>
        </div>
    )

    function Filters() {
        if (homeCollectionState.filteredCollection.length !== 0) {
            return (
                
                
                <div className="flex items-center pb-1 mx-6 justify-end">
                    <button onClick={() => displayFilters()} className="flex mr-2">
                       <FontAwesomeIcon icon={faGrid2} color='white' size="lg"/>
                    </button>
                    <NSFWHomeSwitch/>
                    <button onClick={() => ascDescToggle()} className="flex ml-2">
                        <FontAwesomeIcon icon={faTimer} color='white' size="lg" className="mr-2"/>
                        {
                            homeCollectionState.asc ? <FontAwesomeIcon icon={faAngleUp} color='white' size="lg"/> : <FontAwesomeIcon icon={faAngleDown} color='white' size="lg"/>
                        } 
                    </button>
                </div>
                
            )
        } else {
            return (
                <></>
            )
        }
    }

    function scrollToSearch() {
        if (searchBarRef) {
            scrollIntoView(searchBarRef.current!, { behavior: "smooth", block: "start", inline: "start" });
            //searchBarRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    function displayFilters() {
        dispatch(updateModalState({type: MODAL_TYPE.TAGS, show: true, videoUrl: ""}));
    }

    async function ascDescToggle() {
        dispatch(clearSearchTags());  
        dispatch(toggleAscendingFilter(homeCollectionState.asc === false ? true : false))
        loadHomeCollection(dispatch, "", !homeCollectionState.asc, true);
    }   
}

export default HomeBody;