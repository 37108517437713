import Lottie from "lottie-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faGlobe } from "@fortawesome/pro-light-svg-icons";

import { useAppSelector } from "../../state/hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadStatistics } from "../../networking/art";

import dexview from "../../assets/svg/dextools.svg";
import dexscreener from "../../assets/svg/screener.svg";
import uniswap from "../../lottieJson/uniswap.json";

import { 
  faChartPieSimple, 
  faWhale,
  faGlassWater, 
  faFireFlame,
  faWallet,
  faCartArrowDown,
  faSquare0
} from "@fortawesome/pro-solid-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";


function TokenHeader() {

    const logo_url = "https://lucidai-prod.s3.us-east-1.amazonaws.com/model_logos/lucidai_logo.png";

    const WEB_URL = "https://lucidai.art"
    const TELEGRAM_URL = "https://t.me/lucidAIOfficial"

    const UNISWAP_URL = "https://app.uniswap.org/explore/tokens/ethereum/0xa25031d21fb78ae17c86472823f6dd94507fc6d6"

    const DEXTOOLS_URL = "https://www.dextools.io/app/en/ether/pair-explorer/0xb9622669fb966eceaed99b051157255cb635ffa8"
    const DEXSCREENER_URL = "https://dexscreener.com/ethereum/0xb9622669fb966eceaed99b051157255cb635ffa8"
    const ETHER_SCAN_URL = "https://etherscan.io/token/0xa25031d21fb78ae17c86472823f6dd94507fc6d6#tokenInfo"

    const LIQUIDITY_POOL = "https://etherscan.io/token/tokenholderchart/0xb9622669FB966ECEAeD99b051157255Cb635FFa8"
    const TOKEN_SNIFFER = "https://tokensniffer.com/token/eth/9un51x0u6f2pn36zmjqv9a6qbvksqnivn0lfz8ppkf228h3fs81xqzbk2lgk"


    const homeCollectionState = useAppSelector(state => state.homeCollection);  
    const networkState = useAppSelector(state => state.network);
    
    const dispatch = useDispatch();

    useEffect(() => {
      async function fetchArtData() {
          if (homeCollectionState.totalGenerations === 0) {
              loadStatistics(dispatch);
          } 
      }
      fetchArtData();
    }, [dispatch, homeCollectionState.totalGenerations])

    return (
        <div className="mb-8">
        <div className="bg-space-gray mx-auto max-w-6xl md:flex">
          <div className="w-full px-8 md:w-1/2 flex justify-center items-center">
            <TokenTitleCard/>
          </div>
          <div className="w-full h-full md:w-1/2 flex justify-center items-center px-8">
            <TokenHeaderInfo/>
          </div>
        </div>
        <div className="bg-space-gray mx-auto max-w-6xl md:flex">
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <TokenomicsCard/>
          </div>
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <TokenContractInfo/>
          </div>
        </div>
    </div>
    )

    function TokenomicsCard() {
      return (
        <div className="w-full my-8">
            <div className="flex w-full justify-center items-center">
              <a href={ETHER_SCAN_URL} target="_" className="rounded-lg bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate p-1 m-2 shadow-xl transform transition-all hover:scale-105">
                  <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                    <div className="w-full">
                      <div className="hidden sm:flex justify-center items-center py-2 w-full">
                        <FontAwesomeIcon icon={faChartPieSimple} size="3x" color="white" className="fa-beat slow-beat"/>
                      </div>
                      <div className="sm:hidden justify-center items-center py-2 w-full flex">
                        <FontAwesomeIcon icon={faChartPieSimple} size="2x" color="white" className="fa-beat slow-beat"/>
                      </div>
                      <div className="sm:flex items-center justify-center w-full py-2 sm:py-4">
                        <h1 className="w-full sm:w-auto h-4 my-1 sm:my-0 sm:h-auto font-secondary text-white text-xs sm:text-sm sm:mr-1 text-center sm:text-auto">1B</h1>
                        <h1 className="w-full sm:w-auto text-center sm:text-auto text-animate font-secondary bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">SUPPLY</h1>
                      </div>
                    </div>
                  </div>
              </a>
              <a href={LIQUIDITY_POOL} target="_" className="rounded-lg bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate p-1 m-2 shadow-xl cursor-pointer transform transition-all hover:scale-105">
                    <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                      <div className="w-full">
                        <div className="hidden sm:flex justify-center items-center py-2 w-full">
                          <FontAwesomeIcon icon={faGlassWater} size="3x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:hidden justify-center items-center py-2 w-full flex">
                          <FontAwesomeIcon icon={faGlassWater} size="2x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:flex items-center justify-center w-full py-2 sm:py-4">
                            <FontAwesomeIcon className="sm:hidden w-full sm:w-auto h-4 pb-[2px]" icon={faFireFlame} color="white"/>
                            <FontAwesomeIcon className="hidden sm:flex mr-2 pb-[2px]" icon={faFireFlame} size="1x" color="white"/>
                            <h1 className="w-full sm:w-auto text-center sm:text-auto font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">BURNED</h1>
                        </div>
                      </div>
                    </div>
                </a>
            </div>

            <div className="flex w-full justify-center items-center">
              <a href={LIQUIDITY_POOL} target="_" className="rounded-lg bg-gradient-to-r
                  from-white via-purple-300 to-teal-300
                  background-animate p-1 m-2 shadow-xl cursor-pointer transform transition-all hover:scale-105">
                    <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                      <div className="w-full">
                        <div className="hidden sm:flex justify-center items-center py-2 w-full">
                          <FontAwesomeIcon icon={faCartArrowDown} size="3x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:hidden justify-center items-center py-2 w-full flex">
                          <FontAwesomeIcon icon={faCartArrowDown} size="2x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:flex items-center justify-center w-full py-2 sm:py-4">
                            <FontAwesomeIcon className="sm:hidden w-full sm:w-auto h-4 pb-[2px]" icon={faSquare0} color="white"/>
                            <FontAwesomeIcon className="hidden sm:flex mr-2 pb-[2px]" icon={faSquare0} size="1x" color="white"/>
                            <h1 className="w-full sm:w-auto text-center sm:text-auto font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">FEES</h1>
                        </div>
                      </div>
                    </div>
                </a>
                <a href={TOKEN_SNIFFER} target="_" className="rounded-lg bg-gradient-to-r
                  from-white via-purple-300 to-teal-300
                  background-animate p-1 m-2 shadow-xl cursor-pointer transform transition-all hover:scale-105">
                    <div className="flex items-center justify-center bg-black rounded-lg w-32 h-32 sm:w-38 sm:h-38 shadow-xl">
                      <div className="w-full">
                        <div className="hidden sm:flex justify-center items-center py-2 w-full">
                          <FontAwesomeIcon icon={faWhale} size="3x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:hidden justify-center items-center py-2 w-full flex">
                          <FontAwesomeIcon icon={faWhale} size="2x" color="white" className="fa-beat slow-beat"/>
                        </div>
                        <div className="sm:flex items-center justify-center w-full py-2 sm:py-4">
                            <FontAwesomeIcon className="sm:hidden w-full sm:w-auto h-4 pb-[2px]" icon={faWallet} color="white"/>
                            <FontAwesomeIcon className="hidden sm:flex mr-2 pb-[2px]" icon={faWallet} size="1x" color="white"/>
                            <h1 className="w-full sm:w-auto text-center sm:text-auto font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black text-xs sm:text-sm">LIMITS</h1>
                        </div>
                      </div>
                    </div>
    
                  </a>
              </div>
        </div>
      )
    }

    function TokenTitleCard() {
      return (
        <div className="flex items-center justify-center md:justify-start w-full my-4 md:my-0 md:h-96">
          <div className="rounded-lg bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate p-1 shadow-xl">
              <h1 className="p-4 justify-start text-center md:text-left rounded-lg bg-black text-white text-2xl sm:text-2xl xl:text-4xl font-third font-bold">
              FEATURE UNLOCKS WITH <span className="
              text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">LUCIDAI</span>. SPEND TOKENS ON FUTURE <span className="
              text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">EXCLUSIVE</span> MODELS & TOOLS.
            </h1>
          </div>
        </div>
      )
    }

    function TokenHeaderInfo() {
      return (
        <>
          <a href={ETHER_SCAN_URL} target="_" className="h-96 flex items-center justify-center md:justify-end md:px-16">
              <div className="relative w-full max-w-lg">
                <div className="absolute top-16 left-4 w-48 h-48 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
                <div className="absolute top-32 right-12 w-48 h-48 bg-teal-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
                <div className="absolute bottom-8 left-16 w-48 h-48 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                <div className="m-8 relative space-y-4">
                  <div className="p-5 rounded-lg flex items-center justify-between space-x-8">
                    <div className="flex-1">
                    <div className="">
                          <div className="flex items-center cursor-pointer">
                              <div className="w-64 h-64 rounded-full bg-gradient-to-r
                                  from-white via-purple-300 to-teal-300
                                  background-animate p-1 transform transition-all hover:scale-105"
                                >
                                  <img src={logo_url} alt='' className="w-full h-full rounded-full"></img>
                                </div>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
        </>
      )
    }
    
    function TokenContractInfo() {
        return (
          <div className="">
            <div className="cursor-pointer rounded-lg bg-gradient-to-r
              from-white via-purple-300 to-teal-300
              background-animate p-1 py-1">
              <div className="flex bg-black rounded-lg justify-center py-1 pr-2 pl-1">
                  <div className="flex items-center mt-1 mr-2">
                      <a href={ETHER_SCAN_URL} target="_" className="font-primary text-white text-lg md:text-xl overflow-auto drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{formatContractAddress(networkState.ethTokenContractAddress)}</a>
                  </div>
                  <div className="flex items-center">
                    <button onClick={() => onContractCopy()} className="flex items-center h-full focus:ring-0 focus:outline-none">
                      <FontAwesomeIcon icon={faCopy} size="2x" className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] cursor-pointer" color="white"/>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center w-full pt-2">
              <button onClick={()=>window.open(DEXTOOLS_URL,'_blank', 'rel=noopener noreferrer')} className="flex items-center justify-center h-12 w-1/4 p-[4px] focus:outline-none">
                    <div className="border border-2 border-white w-10 h-10 bg-white rounded-full">
                        <img className="w-full h-full" alt="" src={dexview}></img>
                    </div>
                </button>
                <button onClick={()=>window.open(DEXSCREENER_URL,'_blank', 'rel=noopener noreferrer')} className="flex items-center justify-center h-12 w-1/4 m-[1px] focus:outline-none">
                    <div className="border border-white w-10 h-10 bg-white rounded-full">
                        <img className="w-full h-full rounded-full p-[1px]" alt="" src={dexscreener}></img>
                    </div>
                </button>
                <a href={WEB_URL} target='_' className="flex items-center justify-center h-12 w-1/4 p-[4px] focus:outline-none">
                    <div className="border border-2 border-white w-10 h-10 bg-white rounded-full">
                        <FontAwesomeIcon icon={faGlobe} className="w-full h-full" color="black" />
                    </div> 
                </a>
                <a href={TELEGRAM_URL} target='_' className="flex items-center justify-center flex h-12 w-1/4 p-[4px] focus:outline-none">
                    <div className="border border-2 border-white w-10 h-10 bg-white rounded-full">
                        <FontAwesomeIcon icon={faTelegram} className="w-full h-full" color="black" />
                    </div> 
                </a>
            </div>
            <button onClick={()=>window.open(UNISWAP_URL,'_blank', 'rel=noopener noreferrer')} className="mt-4 flex items-center justify-center w-64 h-64 bg-white rounded-full p-1">
              <Lottie animationData={uniswap} loop={true} className="h-full w-full"></Lottie>
            </button>
        </div>
        )
    }

    function formatContractAddress(address: string) {
      return address.substring(0, 4) + "..." + address.substring(address.length - 5, address.length)
    }

    function onContractCopy() {
      navigator.clipboard.writeText(networkState.ethTokenContractAddress);
    }
}

export default TokenHeader;