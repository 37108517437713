import { useDispatch } from "react-redux";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { useAppSelector } from "../../state/hooks";

function ArtCreationPreview() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal)
  
    const width = modalState.userArt.art.width.toString();
    const height = modalState.userArt.art.height.toString();
    
    const dynamicStyle = {
        width: width + "px",
        height: height + "px"
    }

    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.ART_PREVIEW} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-8 md:mx-16 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div>
                                    <div className="flex justify-center items-center w-full">
                                        <div className="">
                                            <MainImage/>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function MainImage() {
        return (
            <div className="m-2">
                <div className={`hidden md:flex rounded-lg`} style={dynamicStyle}>
                    <img src={modalState.userArt.art.s3ImageUrls[modalState.userArt.selectedImageIndex]}
                    alt="" 
                    className="w-full object-cover rounded-lg"
                    />
                </div>
                <div className={`flex md:pt-4 md:px-2 rounded-lg md:hidden`}>
                    <img src={modalState.userArt.art.s3ImageUrls[modalState.userArt.selectedImageIndex]}
                    alt="" 
                    className="w-full rounded-lg"
                    />
                </div>
            </div>
        )
    }

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.ART_PREVIEW, show: false}));
    }
}

export default ArtCreationPreview;