import { useAppSelector } from "../../../../../state/hooks";
import { MODEL_TYPE, MODEL_VISIBILITY } from "../../../../../models/art/artModel";
import { MODEL_CARD_TYPE } from "../../../../model/standardModelCard";
import StandardModelCard from "../../../../model/standardModelCard";
import RequestModelCard from "../cards/requestModelCard";

export default function BaseModelSection() {
    const modelState = useAppSelector(state => state.artModelState);
    
    return (
        <>
        {RequestModelCard(MODEL_VISIBILITY.BASE)}
        {modelState.models.map((model) =>  
            (model.modelVisibility === MODEL_VISIBILITY.BASE && model.modelType === MODEL_TYPE.BASE) ?  StandardModelCard(model, MODEL_CARD_TYPE.PROFILE) : <></>    
        )}
        </>  
    ) 
}