import { UserArt } from "../art"
import { ArtModel } from "../art/artModel"
import PromptTag from "../art/promptTag"
import { RegisteredChannel } from "../collection/registeredChannel"


export enum MODAL_TYPE {
    SIGN_IN,
    VIDEO,
    TAGS,
    ART,
    ART_PREVIEW,
    MODEL_SELECTOR,
    MODEL_COMMUNITY,
    MODEL_INFO,
    MODEL_REQUEST,
    TAG_ADJUSTMENT,
    ACCOUNT_LINK,
    REMOVE_CHANNEL,
    PURCHASE_TIER
}

export enum ACCOUNT_TYPE {
    DISCORD,
    TELEGRAM
}

export interface AccountLink {
    id: number
    username: string
    type: ACCOUNT_TYPE
}

export interface Modal {
    type: MODAL_TYPE
    signInMessage: string
    show: boolean
    videoUrl: string | undefined
    selectedChannel: RegisteredChannel,
    userArt: {
        art: UserArt,
        selectedImageIndex: number
        initialLoad: boolean,
    },
    model: ArtModel,
    accountLink: AccountLink
    promptTag: PromptTag
}

