import PricingBody from "./pricingBody";
import { useEffect } from "react";
import { useAppSelector } from "../../state/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyIP } from "../../networking/user/authentication/ipVerification";
import { updateVerificationStatus } from "../../state/reducers/network/networkReducer";

export default function PricingHomePage() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const networkState = useAppSelector(state => state.network);

    // initial load
    useEffect(() => {
      // This function will be called only once on component mount
      verifyUser();
    }, []); // Empty dependency array
  
    const verifyUser = async () => {
    // Your method logic here
        if (!networkState.ipVerificationChecked) {
            const result = await verifyIP(dispatch, networkState);
            console.log(result);
            if (result) {
                console.log("In wrong state: " + result);
                navigate("/unverified")
                dispatch(updateVerificationStatus(true));
            }   
        }
    };
    
    return (
      <div className="bg-space-gray">
        <PricingBody/>
      </div>
    )
}