import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const people = [
    {
      name: 'Trunks',
      role: 'The Intern',
      imageUrl:
        'https://lucidai-prod.s3.amazonaws.com//media/trunks.jpg',
      url: 'https://www.youtube.com/watch?v=Eqwry3FeDps',
    },
    {
        name: 'Roshi',
        role: 'founder@lucidai.art',
        imageUrl:
          'https://lucidai-prod.s3.amazonaws.com//media/roshi_logo.jpeg',
        url: 'https://www.linkedin.com/in/roshi-lucid'
      },
      {
        name: 'oolong',
        role: 'AI/ML Dev',
        imageUrl:
          'https://lucidai-prod.s3.amazonaws.com//media/oolong.jpg',
        url: 'https://www.youtube.com/watch?v=Ldis3UeiDm4'
      },
      {
        name: 'Android 17',
        role: 'Marketing Director',
        imageUrl:
          'https://lucidai-prod.s3.amazonaws.com//media/android17.jpg',
        url: 'https://www.youtube.com/watch?v=SfU_GcTgQ34'
      },
    // More people...
  ]
  
  export default function TeamSection() {
    return (
      <div className="bg-space-gray pt-8 pb-32">
        <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="w-full flex justify-center">
             <h1 className="mt-1 text-4xl font-bold font-primary tracking-tight sm:text-5xl lg:text-6xl 
                text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text 
                text-transparent font-black drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                The Team
            </h1>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
          >
            {people.map((person) => (
              <li key={person.name}>
                <img className="h-56 w-56 mx-auto rounded-full object-cover" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 font-secondary tracking-tight text-white">{person.name}</h3>
                <p className="text-sm leading-6 text-white font-secondary">{person.role}</p>
                <ul role="list" className="mt-2 flex justify-center gap-x-6">
                  <li>
                    <a href={person.url} target="_" className="text-gray-400 hover:text-white">
                      <span className="sr-only">Twitter</span>
                      <FontAwesomeIcon size="1x" icon={faLinkedin} color="white"></FontAwesomeIcon>
                    </a>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
}