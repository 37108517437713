import { useDispatch } from "react-redux";
import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { Tier, TierLevel, TierType, UserTierInfo } from "../../models/tier/tier";
import { fetchAndUpdateTiers } from "../../networking/tier/fetchTiers";
import usdc_logo from "../../assets/svg/usdc.svg";
import HandleAlert, { warningAlert } from "../../utils/handleAlert";
import { handleTierLevelPurchase, updatePurchaseStatus } from "../../networking/tier/purchaseTier";
import { TierPurchasingStatus } from "../../state/reducers/tier/tierReducer";
import metaEthLoading from "../../lottieJson/metaEthLoading.json";
import Lottie from "lottie-react";

function PurchaseTierModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const tierState = useAppSelector(state => state.tierState);
    const networkState = useAppSelector(state => state.network);
    const profileState = useAppSelector(state => state.profileCollection);

    useEffect(() => {
        async function fetchTierData() {
            if (!tierState.initialLoad) {
            await fetchAndUpdateTiers(dispatch);
            }
        }
        fetchTierData();
    }, [dispatch, tierState])

    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.PURCHASE_TIER} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-4 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 w-full md:w-3/4 mx-4 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div className="w-full">
                                   <div className="flex justify-center w-full my-4">
                                        <h1 className="text-white font-secondary text-sm md:text-xl">Purchase or Upgrade Tier Level</h1>
                                   </div>
                                   <div className="md:flex w-full justify-center items-start mb-4">
                                        <div className="w-full md:w-1/2 p-4">
                                            <div className="flex justify-center mb-4">
                                                <h1 className="text-white font-secondary text-lg">Individual</h1>
                                            </div>
                                            {IndvidualSection()}
                                        </div>
                                        <div className="w-full md:w-1/2 p-4">
                                            <div className="flex justify-center mb-4">
                                                <h1 className="text-white font-secondary text-lg">Community</h1>
                                            </div>
                                            {DisplayCommunitySection()}
                                        </div>
                                   </div>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.PURCHASE_TIER, show: false}));
    }

    function IndvidualSection() {
        return (
            <div className="flex-grow h-full flex justify-center items-center mb-2">
                <h1 className="text-white font-third text-md">Coming soon</h1>
            </div>
        )
    }

    function DisplayCommunitySection() {
        if (tierState.purchaseStatus.status === TierPurchasingStatus.STANDBY) {
            return (
                <CommunitySection/>
            )
        } else if (tierState.purchaseStatus.status === TierPurchasingStatus.ERROR) {
            return (
                <ErrorSection/>
            )
        } else if (tierState.purchaseStatus.status === TierPurchasingStatus.AWAITING_APPROVAL_CONFIRMATION || tierState.purchaseStatus.status === TierPurchasingStatus.AWAITING_PURCHASE_CONFIRMTION) {
            return (
                <ConfirmtionLoadingSection/>
            )
        } else {
            return (
                <LoadingPanel/>
            )
        }
    }

    function ConfirmtionLoadingSection() {
        return (
            <div className="flex w-full justify-center items-center h-96">
                <div className="w-full">
                    <div className="flex justify-center items-center w-full">
                        <div className="h-32 w-32 md:h-64 md:w-64">
                            <Lottie animationData={metaEthLoading} loop={true} className="h-full w-full"></Lottie>
                        </div>
                    </div>
                    <div className="w-full flex items-center text-center justify-center mt-4">
                        <h1 className="w-3/4 text-white text-lg md:text-2xl font-third">
                            {returnConfirmationMessage()}
                        </h1>
                    </div>
                </div>
            </div>
        )
    }

    function LoadingPanel() {
        return (
            <div className="flex w-full justify-center items-center h-96">
                <div className="w-full">
                    <div className="flex items-center justify-center w-full">
                        <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                            <div className="h-12 w-12 rounded-full bg-black"></div>
                        </div>
                    </div>
                    <div className="w-full flex items-center text-center justify-center mt-4">
                        <h1 className="w-3/4 text-white text-lg md:text-2xl font-third">
                            {returnStatusMessage()}
                        </h1>
                    </div>
                </div>
            </div> 
        )
    }

    function ErrorSection() {
        return (
            <div className="flex w-full justify-center items-center h-96">
                <div className="w-full">
                    <div className="w-full flex items-center text-center justify-center my-2">
                        <h1 className="w-3/4 text-white text-lg md:text-2xl font-third">
                            {tierState.purchaseStatus.errorMessage}
                        </h1>
                    </div>
                    <div className="w-full flex items-center justify-center my-4">
                        <button onClick={() => setPurchaseStateBackToStandby()} className="text-white text-2xl font-third rounded-lg border-2 border-white px-4 py-2">
                            retry
                        </button>
                    </div>
                </div>
                
            </div>
        )
    }

    function CommunitySection() {
        return (
            <div className="flex w-full h-96 justify-center items-start">
                {tierState.tiers.map((tier) => (
                    tier.type === TierType.COMMUNITY ? displayTiersGrid(tier) : <></>
                ))
            }
           </div>
        )
    }

    function displayTiersGrid(tier: Tier) {
        return (
            <div className="flex grid grid-cols-2 xl:grid-cols-3 gap-2">
                {
                    tier.tierLevels.map((tierLevel) =>
                        <div onClick={() => handleTierPurchase(tier, tierLevel)} className={displayTierGridCellContainerClass(tier.tierLevels, profileState.userTierInfo, tierLevel, TierType.COMMUNITY)}>
                            <div className={displayTierGridCellClass(tier.tierLevels, profileState.userTierInfo, tierLevel, TierType.COMMUNITY)}>
                                <div className="flex items-center justify-center w-full h-1/2">
                                    <div>
                                        <div className="flex justify-center items-center h-1/2 text-center">
                                            <h1 className="text-black font-third text-lg mt-4">{tierLevel.generations} gens</h1>
                                        </div>
                                        <div className="flex justify-center items-end h-1/2">
                                            <h1 className={displayTierGridTextClass(tier.tierLevels, profileState.userTierInfo, tierLevel, TierType.COMMUNITY)}>{tierLevel.name}</h1>
                                        </div> 
                                    </div>
                                </div>
                                <div className="flex items-center justify-center w-full h-1/2">
                                    <div className="flex justify-center text-center">
                                        <img className="rounded-full h-8 w-8 mr-1" alt="" src={usdc_logo}></img>
                                        <h1 className="text-black font-bold text-md font-secondary mt-1">{tierLevel.price}.0</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    function handleTierPurchase(tier: Tier, level: TierLevel) {
        if (tier.type === TierType.COMMUNITY) {
            if (networkState.selectedAccount === "" || networkState.jwtToken === "") {
                HandleAlert(warningAlert("Not Signed in", ""), dispatch)
            } else {  
                handleTierLevelPurchase(level, networkState, dispatch);
            }   
        } else {
            // individual coming later
        }
    }

    function setPurchaseStateBackToStandby() {
        updatePurchaseStatus(TierPurchasingStatus.STANDBY, "", dispatch);
    }

    function returnStatusMessage() {
        if (tierState.purchaseStatus.status === TierPurchasingStatus.ESTABLISHING_CONNECTION) {
            return "establishing connection..."
        } else if (tierState.purchaseStatus.status === TierPurchasingStatus.CHECKING_BALANCE) {
            return "checking USDC allowance..."
        } else if (tierState.purchaseStatus.status === TierPurchasingStatus.ESTIMATING_PURCHASE_GAS) {
            return "estimating gas..."
        } else if (tierState.purchaseStatus.status === TierPurchasingStatus.AWAITING_APPROVAL_RECEIPT) {
            return "awaiting approval transaction to be mined... DO NOT REFRESH"
        } else if (tierState.purchaseStatus.status === TierPurchasingStatus.AWAITING_PURCHASE_RECEIPT) {
            return "awaiting tier purchase transaction to be mined... DO NOT REFRESH"
        }
    }

    function returnConfirmationMessage() {
        if (tierState.purchaseStatus.status === TierPurchasingStatus.AWAITING_APPROVAL_CONFIRMATION) {
            return "awaiting approval confirmation"
        } else {
            return "awaiting purchase confirmation"
        }
    }

    function displayTierGridCellContainerClass(tierLevels: TierLevel[], userTierLevel: UserTierInfo, gridTierLevel: TierLevel, tierType: TierType) {
        if (tierType === TierType.COMMUNITY) {
           let userCurrentTierLevel = tierLevels.find(tierLevel => tierLevel.name.toLowerCase() === userTierLevel.currentTier.toLowerCase());
           // level user currently has
           if (userCurrentTierLevel && gridTierLevel.name.toLowerCase() === userCurrentTierLevel.name.toLowerCase()) {
            return "flex w-32 h-32 bg-gradient-to-r from-green-500 via-teal-300 to-blue-500 background-animate p-[2px] rounded-lg shadow-xl"
          // levels user cannot downgrade to
           } else if ((userCurrentTierLevel && gridTierLevel.generations < userCurrentTierLevel.generations)) {
            return "flex h-32 bg-black p-[2px] rounded-lg shadow-xl"
           } else {
            // levels user can upgrade to
            return "cursor-pointer flex w-32 h-32 bg-gradient-to-r from-blue-200 via-purple-300 to-teal-300 hover:from-green-500 hover:via-teal-300 hover:to-blue-500 background-animate p-[2px] rounded-lg shadow-xl"
           }
        } else {
            return "cursor-pointer flex w-32 h-32 bg-gradient-to-r from-blue-200 via-purple-300 to-teal-300 hover:from-green-500 hover:via-teal-300 hover:to-blue-500 background-animate p-[2px] rounded-lg shadow-xl"
        }
    }

    function displayTierGridCellClass(tierLevels: TierLevel[], userTierLevel: UserTierInfo, gridTierLevel: TierLevel, tierType: TierType) {
        if (tierType === TierType.COMMUNITY) {
           let userCurrentTierLevel = tierLevels.find(tierLevel => tierLevel.name.toLowerCase() === userTierLevel.currentTier.toLowerCase());
           if ((userCurrentTierLevel && gridTierLevel.generations < userCurrentTierLevel.generations)) {
            return "w-full h-full bg-gray-500 rounded-lg"
           } else {
            return "w-full h-full bg-white rounded-lg"
           }
        } else {
            return "w-full h-full bg-white rounded-lg"
        }
    }

    function displayTierGridTextClass(tierLevels: TierLevel[], userTierLevel: UserTierInfo, gridTierLevel: TierLevel, tierType: TierType) {
        if (tierType === TierType.COMMUNITY) {
            let userCurrentTierLevel = tierLevels.find(tierLevel => tierLevel.name.toLowerCase() === userTierLevel.currentTier.toLowerCase());
            if (userCurrentTierLevel && gridTierLevel.name.toLowerCase() === userCurrentTierLevel.name.toLowerCase()) {
                return "font-bold text-md font-secondary tracking-tight text-animate bg-gradient-to-r from-green-500 via-teal-300 to-blue-500 bg-clip-text text-transparent font-black"
            } else {
                return "text-black font-bold text-md font-secondary"
            }
        } else {
            return "text-black font-bold text-md font-secondary"
        }
        
    }
}

export default PurchaseTierModal;