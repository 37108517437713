export enum TierType {
    INDIVIDUAL,
    COMMUNITY
}

export interface UserTierInfo {
    currentTier: string,
    lastDepositTime: string,
    lastDepositValue: number,
    totalDepositValue: number,
    generationsLeft: number
}

export interface TierLevel {
    name: string;
    price: number,
    months: number
    generations: number
}

// trial
// base
// artist
// artisan
// legendary
export interface Tier {
    type: TierType;
    href: string;
    tierLevels: TierLevel[];
    description: string;
    includedFeatures: string[];
}