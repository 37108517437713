import { createSlice } from "@reduxjs/toolkit";
import { UserArt } from "../../../models";

interface ArtSelectionState {
    selectedImageIndex: number
    selectedArt: UserArt | undefined
}

const initialArtSelectionState: ArtSelectionState = {
    selectedImageIndex: 0,
    selectedArt: undefined
}

const modalArtSelectionReducer = createSlice({
    name: "selectedArt",
    initialState: initialArtSelectionState,
    reducers: {
        updateModalArtSelection: (state, action) => {
            return {...action.payload}
        }
    }
})

export default modalArtSelectionReducer.reducer;
export const { updateModalArtSelection } = modalArtSelectionReducer.actions;