import HandleAlert, { errorAlert, successAlert, warningAlert } from '../../../utils/handleAlert';
import { CHAIN_ID } from "../../../models/network";
import { prodNetworkHelper, testNetworkHelper, switchNetworkChain } from "../../../state/reducers/network/networkReducer";
import { addPolygonNetwork } from '../crypto/metaNetwork';
import { updateModalState } from '../../../state/reducers/modal/modalReducer';
import { MODAL_TYPE } from '../../../models/modal';
import { clearNsfwIds, updateNsfwFlag } from '../../../state/reducers/collection/homeCollectionReducer';
import axios from 'axios';
import syncUserTier from '../../tier/syncUserTier';
import { fetchAndUpdateUserArtState } from '../../art';
import { signMessageString } from './signMessage';


const METAMASK_URL = "https://metamask.io/";
const USER_ROUTE = "https://webhook.lucidai.art/user";
const SIGNUP_ROUTE = "https://webhook.lucidai.art/signup";
const AUTH_ROUTE = "https://webhook.lucidai.art/authenticate";

export const login = async (dispatch: any) => {

    // @ts-ignore
    if (window.ethereum === undefined) {
        handleMissingMetamask(dispatch);
        return;
    }
    // @ts-ignore
    if (window.ethereum._state && window.ethereum._state.accounts && window.ethereum._state.accounts.length !== 0) {
        // @ts-ignore
        let chainID = window.ethereum._state.accounts[0].chainId;
        // @ts-ignore
        authenticateUser(dispatch, window.ethereum._state.accounts[0], chainID);
    } else {
        try {
            // @ts-ignore
            let selectedAccount = await window.ethereum.request({method: "eth_requestAccounts" });
            // @ts-ignore
            let chainId = await window.ethereum.request({ method: 'eth_chainId'});
            authenticateUser(dispatch, selectedAccount[0], chainId);
        } catch (e) {
            console.log(e);
            // @ts-ignore
            handleAuthError(dispatch, e.code);
        }
    }  
}

export const authenticateUser = async (dispatch: any, publicAddress: string, chainId: any) => {    
    ////////////////////////////////////////////////////
    // Step 1: Generate a new nonce for the user
    ////////////////////////////////////////////////////
    const currentNonce = await getCurrentNonceOrSignupUser(publicAddress);
    
    ////////////////////////////////////////////////////
    // Step 2: Create signature with nonce
    ////////////////////////////////////////////////////
    const message = signMessageString + currentNonce;
    const signature = await signMessage(message, publicAddress.toLowerCase(), dispatch);

    ////////////////////////////////////////////////////
    // Step 3: Verify signature and update state
    ////////////////////////////////////////////////////
    if (signature) {
        const token = await authenticate(publicAddress, signature, dispatch);
        // JWT TOKEN RECEIVED HERE (sync user / update state / save to cookies)
        if (token && token !== "") {
            //dispatch(switchNetworkChain(prodNetworkHelper(CHAIN_ID.ETH, publicAddress, token)));
            dispatch(switchNetworkChain(testNetworkHelper(CHAIN_ID.ETH_SEPOLIA, publicAddress, token)));
            await syncUser(publicAddress.toLowerCase(), token, dispatch);
            dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: false}));
            fetchAndUpdateUserArtState(dispatch, publicAddress, token);
        } 
    }
}

export async function getCurrentNonceOrSignupUser(publicAddress: string): Promise<string | undefined> {
    // CHECK FOR EXISTING USER
    let currentNonce = undefined;
    currentNonce = await fetchUser(publicAddress);
    // IF USER DOESS NOT EXIST: SIGN USER UP AND CREATE NONCE BACKEND SIDE
    if (currentNonce === undefined) {
        currentNonce = await signupUserAndGenerateNonce(publicAddress);
    }
    return currentNonce;
}

export async function signupUserAndGenerateNonce(userAddress: string): Promise<string | undefined> {
    try {
        const response = await axios.post(SIGNUP_ROUTE, { publicAddress: userAddress });
        return response.data.nonce as string;
    } catch (error) {
        return undefined;
    }
}

export async function fetchUser(userAddress: string) {
    const requestData = {
        params: {
            publicAddress: userAddress
        }
    };
    try {
        return await (await axios.get(USER_ROUTE, requestData)).data.nonce;
    } catch (error) {
        return undefined;
    }
}

export async function authenticate(publicAddress: string, signature: string, dispatch: any) {
    try {
        const response = await axios.post(AUTH_ROUTE, { publicAddress: publicAddress, signature: signature });
        return response.data.authToken;
    } catch (error) {  
        HandleAlert(errorAlert("Error verifying signature, please try again."), dispatch)
        return undefined;
    }
}

export async function syncUser(publicAddress: string, token: string, dispatch: any) {
    try {
        const syncObject = await syncUserTier(publicAddress, token);
        if (syncObject === null || syncObject.errorMessage !== "") {
            HandleAlert(errorAlert("Error syncing user with blockchain. Log back in to sync again."), dispatch);
        } else {
            HandleAlert(successAlert("Synced user with on chain data."), dispatch);
        }
    } catch (error) {  
        console.log("Error syncing user: " + error)
    }
}

async function signMessage(message: string, publicAddress: string, dispatch: any): Promise<string | undefined> {
// @ts-ignore
    return await window.ethereum.request({
        method: 'personal_sign',
        params: [message, publicAddress],
    })
    .then((signature: string) => {
        return signature;
    })
    .catch((error: any) => {
        HandleAlert(errorAlert("Signature not received"), dispatch)
        return undefined
    });
};

// Switch account network
export const switchToDefaultNetwork = async (dispatch: any, chainId: CHAIN_ID) => {
    
    try {
        const chainHex = "0x" + chainId.toString(16);
        // @ts-ignore
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainHex }],
        })
        dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: false}));
        
    } catch (e) {
        console.log(e);
        // @ts-ignore
        if (e.code === 4902) { // If network does not exist, ask to add it
            await addDefaultNetwork(dispatch);
        } else {
            // @ts-ignore
            if (e.code === 4001) {
                HandleAlert(warningAlert("Rejected sign in, please try again", ""), dispatch)
            } else {
                console.log(e);
                HandleAlert(errorAlert("Something went wrong trying to connect Metamask."), dispatch)
            }
        }
    }
}

export const addDefaultNetwork = async (dispatch: any) => {
    try {
        addPolygonNetwork().then(() =>{
            // @ts-ignore
            if (window.ethereum._state && window.ethereum._state.accounts && window.ethereum._state.accounts.length !== 0) {
                console.log("error here?")
                // @ts-ignore
                dispatch(switchNetworkChain(networkHelper(CHAIN_ID.ETH, window.ethereum._state.accounts[0])));
                dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: false}));
            }
        });
    } catch (e) {
        HandleAlert(errorAlert("Rejected adding default network"), dispatch)
    }
}

export const logout = async (dispatch: any) => {
    dispatch(updateNsfwFlag(false));
    dispatch(clearNsfwIds());
    dispatch(switchNetworkChain(prodNetworkHelper(CHAIN_ID.ETH, "", "")));
}

export function handleAuthError(dispatch: any, error: number) {
    if (error === 4001) {
        HandleAlert(warningAlert("Rejceted Metamask sign in, please try again.", ""), dispatch)
    } else {
        HandleAlert(errorAlert("Something went wrong trying to connect Metamask. Refresh and try again."), dispatch)
    }
}

export function handleMissingMetamask(dispatch: any) {
    HandleAlert(warningAlert("Add Metamask to your browser: ", METAMASK_URL), dispatch)
}

