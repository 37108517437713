import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT_TYPE, Modal, MODAL_TYPE } from "../../../models/modal";
import { TagBoxType } from "../tag/tagSearchReducer";
import { GENERATIVE_TYPE, MODEL_TYPE, MODEL_VISIBILITY } from "../../../models/art/artModel";
import { CHANNEL_TYPE } from "../../../models/collection/registeredChannel";

const initialModalState: Modal = {
    type: MODAL_TYPE.SIGN_IN,
    signInMessage: "",
    show: false,
    videoUrl: undefined,
    userArt: {
        art: {
            jobId: "",
            dateCreated: "",
            userAddress: "",
            tokenPromoAddress: "",
            s3ImageUrls: [],
            ipfsImageUrls: [],
            advertisementAddress: "", 
            minted: false,
            model: "",
            loras: [],
            nsfw: false,
            generationType: "prompt",
            prompt: [],
            numImages: 0,
            negativePrompt: [],
            promptStrength: 0,
            steps: 0,
            width: 0,
            height: 0,
            chainId: 1
        },
        selectedImageIndex: 0,
        initialLoad: false,
    },
    selectedChannel: {
        chatId: 0,
        name: "",
        chatUsername: "",
        blacklistedModels: [],
        type: CHANNEL_TYPE.TELEGRAM,
        totalNumGenerations: 0,
        currentNumGenerationsLeft: 0,
        usedTrial: false,
        isModifying: false
    },
    accountLink: {
        id: 0,
        username: "",
        type: ACCOUNT_TYPE.TELEGRAM
    },
    model: {
        owner: "",
        name: "",
        ticker: "",
        nsfw: false,
        description: "",
        communityUrl: "",
        telegramUrl: "",
        dextoolsUrl: "",
        imageUrl: "",
        modelVisibility: MODEL_VISIBILITY.PUBLIC,
        modelType: MODEL_TYPE.BASE,
        generativeType: GENERATIVE_TYPE.IMAGE
    },
    promptTag: {
        id: "",
        strength: 0,
        displayText: "",
        rawText: "",
        type: TagBoxType.PROMPT
    }
}

const modalSlice = createSlice({
    name: "modal",
    initialState: initialModalState,
    reducers: {
        updateModalState: (state, action) => {
            state.show = action.payload.show
            state.type = action.payload.type
            state.videoUrl = action.payload.videoUrl
            state.signInMessage = action.payload.signInMessage
        },
        updateArtModalState: (state, action) => {
            state.show = action.payload.show
            state.type = action.payload.type
            state.userArt.art = action.payload.userArt
        },
        updateModelInfoModalState: (state, action) => {
            state.show = action.payload.show
            state.type = action.payload.type
            state.model = action.payload.model
        },
        updateAccountLinkModalState: (state, action) => {
            state.show = action.payload.show
            state.type = action.payload.type
            state.accountLink = action.payload.accountLink
        },
        updateRemoveChannelModalState: (state, action) => {
            state.show = action.payload.show
            state.type = action.payload.type
            state.selectedChannel = action.payload.channel
        },
        updatePromptTagModalState: (state, action) => {
            state.show = action.payload.show
            state.type = action.payload.type
            state.promptTag = action.payload.promptTag
        },
        updateArtModalSelection: (state, action) => {
            state.userArt.selectedImageIndex = action.payload
        },
        updateArtModalInitialLoad: (state, action) => {
            state.userArt.initialLoad = action.payload
        }      
    }
})

export default modalSlice.reducer;
export const { 
    updateModalState, 
    updateArtModalState, 
    updateArtModalSelection,
    updateArtModalInitialLoad,
    updatePromptTagModalState,
    updateAccountLinkModalState,
    updateRemoveChannelModalState,
    updateModelInfoModalState
} = modalSlice.actions;