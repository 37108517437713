import { setSelectedInputIndex, toggleModifyingNameState, updateChannelNameState } from "../../../../state/reducers/user/userProfileReducer";
import { faSquareXmark  } from "@fortawesome/pro-light-svg-icons";
import { RegisteredChannel } from "../../../../models/collection/registeredChannel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { useAppSelector } from "../../../../state/hooks";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import HandleAlert, { errorAlert, successAlert } from "../../../../utils/handleAlert";
import { formatTelegramChannelName } from "../../../../utils/formatChannelName";
import { updateRemoveChannelModalState } from "../../../../state/reducers/modal/modalReducer";
import { MODAL_TYPE } from "../../../../models/modal";

export default function WhitelistTable() {
    const profileState = useAppSelector(state => state.profileCollection);

    const dispatch = useDispatch();
  
    const textInputRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [inputValues, setInputValues] = useState<string[]>([]);

    const onClickFocus = (channelNameKey: string) => {
        if (profileState.registeredTgChats.some(channel => channel.isModifying)) {
            HandleAlert(errorAlert("Save changes first."), dispatch);
        } else {
            dispatch(toggleModifyingNameState({channelNameKey: channelNameKey, isModifying: true}));  
        }
             
    };

    const onClickSave = (channelNameKey: string, index: number) => {
        //@ts-ignore
        const newChannelName = textInputRefs.current[index]?.value;
        console.log(newChannelName);
        
        if (newChannelName === undefined || newChannelName === null || newChannelName.replace(/ /g, "") === '') {
            HandleAlert(errorAlert("No channel entered."), dispatch);
            return;
        }
        if (newChannelName && profileState.registeredTgChats.some((channel) => channel.name.toLowerCase() === newChannelName.toLowerCase()) && profileState.selectedChannelName === "") {
            HandleAlert(errorAlert("This channel is already registered by you."), dispatch);
            return;
        }

        let formattedChannelName = "";

        if (newChannelName && !profileState.registeredTgChats.some((channel) => channel.name.toLowerCase() === newChannelName.toLowerCase())) {
            // perform network update
            formattedChannelName = formatTelegramChannelName(newChannelName);
            if (formattedChannelName.length > 0) {
                // handle network call
                dispatch(updateChannelNameState({channelNameKey: channelNameKey, isModifying: false, newChannelName: formattedChannelName}));
                HandleAlert(successAlert("Registered " + formattedChannelName), dispatch);
            }
        } else {
            // same channel name
            dispatch(updateChannelNameState({channelNameKey: channelNameKey, isModifying: false, newChannelName: newChannelName.toLowerCase()}));
        }
    };

    useEffect(() => {
        if (profileState.registeredTgChats.length > 0 && textInputRefs.current.length > 0) {
          // If no input was explicitly selected, focus on the last input
          profileState.registeredTgChats.forEach((channel, index) => {
            if (channel.isModifying) {
                textInputRefs.current[index]?.focus();
            }
          })
        }
      }, [profileState.registeredTgChats]);

    useEffect(() => {
        // Initialize inputValues with the names from the profileState
        setInputValues(profileState.registeredTgChats.map(channel => channel.name));
    }, [profileState.registeredTgChats]);

    if (profileState.initialProfileLoad && profileState.registeredTgChats.length > 0) {
        return (
            <div className="flex w-full overflow-y-auto grid grid-cols-1 gap-2">
               {profileState.registeredTgChats.map((channel, index) => WhitelistCell(channel, index))}
            </div>
        )
    } else if (profileState.initialProfileLoad && profileState.registeredTgChats.length === 0) {
        return (
            <RegisterNewChannelPane/>
        )
    } else {
        return (
            <LoadingPanel/>
        )
    }
    
    function WhitelistCell(channel: RegisteredChannel, index: number) {
        return (
            <div className="flex w-full h-12">
                <div className="flex items-end justify-center w-12">
                    <FontAwesomeIcon icon={faTelegram} color="white" size="2x"/>
                </div>
                <div
                onClick={() => toggleModiyingCellIndex(channel.name)}
                className={
                    channel.isModifying ? 
                    "flex-grow flex items-end h-full border-b-2 border-white mx-2 text-blue-400" : 
                    "cursor-pointer flex-grow flex text-white items-end h-full border-b-2 border-white hover:border-blue-400 mx-2"
                    }>@{channel.name}</div>
                <div className="flex w-10">
                    {removeButton(channel)}
                </div>
            </div>
        )
    }

    function removeButton(channel: RegisteredChannel) {
        return (
            <button onClick={() => onClickRemove(channel)} className="flex justify-center items-end w-full h-full">
                <FontAwesomeIcon icon={faSquareXmark} color="#ffab91" size="2x"/>
            </button>
        )
    }

    function onClickRemove(channel: RegisteredChannel) {
        dispatch(updateRemoveChannelModalState({type: MODAL_TYPE.REMOVE_CHANNEL, show: true, channel: channel}));
    }

    function toggleModiyingCellIndex(channelNameKey: string) {
        if (!profileState.registeredTgChats.some(channel => channel.isModifying)) {
            dispatch(setSelectedInputIndex(channelNameKey));
        }
    }

    function LoadingPanel() {
        return (
            <div className="flex w-full h-full justify-center items-center">
                <div className="animate-spin flex items-center justify-center rounded-full w-14 h-14 bg-gradient-to-tr from-white via-purple-300 to-teal-300">
                    <div className="h-12 w-12 rounded-full bg-black"></div>
                </div>
            </div> 
        )
    }

    function RegisterNewChannelPane() {
        return (
            <div className="flex w-full h-full justify-center items-center">
                <div>
                    <div className="">
                        <h1 className="text-white text-md font-third">No Registered Channels</h1>
                    </div>
                    <div className="flex w-full justify-center my-1"></div>
                </div>
            </div>
        )
    }
}