import { useDispatch } from "react-redux";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { MODEL_VISIBILITY } from "../../models/art/artModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import dexview from "../../assets/svg/dextools.svg";

export default function ModelInfoModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const profileState = useAppSelector(state => state.profileCollection);

    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.MODEL_INFO} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-8 md:mx-16 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 mx-4 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div className="flex justify-center items-center p-4">
                                    <div className="mx-4">
                                        <div className="flex w-64 justify-center py-4 px-8">
                                            <h1 
                                            className="font-secondary text-center font-bold text-xl md:text-2xl
                                            text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 
                                            bg-clip-text text-transparent font-black">
                                            {modalState.model.name}</h1>
                                        </div>
                                        <div className="my-2 bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px] rounded-lg">
                                            <div className="w-64 h-64 bg-black rounded-lg">
                                                <img className="w-full h-full" alt="" src={modalState.model.imageUrl}></img>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center w-64">
                                            <div className="flex justify-center items-center w-48">
                                                <button onClick={()=>window.open(modalState.model.dextoolsUrl,'_blank', 'rel=noopener noreferrer')} className="flex items-center justify-center h-12 w-1/3 p-1 focus:outline-none">
                                                    <div className="border border-white w-8 h-8 bg-white rounded-full">
                                                        <img className="w-full h-full" alt="" src={dexview}></img>
                                                    </div>
                                                </button>
                                                <a href={modalState.model.communityUrl} target='_' className="flex items-center justify-center h-12 w-1/3 p-1 focus:outline-none">
                                                    <div className="border border-white w-8 h-8 bg-black rounded-full p-1">
                                                        <FontAwesomeIcon icon={faGlobe} className="w-full h-full" color="white" />
                                                    </div> 
                                                </a>
                                                <a href={modalState.model.telegramUrl} target='_' className="flex items-center justify-center flex h-12 w-1/3 p-1 focus:outline-none">
                                                    <div className="border border-white w-8 h-8 bg-white rounded-full">
                                                        <FontAwesomeIcon icon={faTelegram} className="w-full h-full" color="black" />
                                                    </div> 
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    
    function communityURL() {

    }

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.MODEL_INFO, show: false}));
    }
}