import { createSlice } from "@reduxjs/toolkit";
import { UserAuthFlow, UserAuthType } from "../../../models/user/userAuthFlow";


const initialUserAuthFlowState: UserAuthFlow = {
    flow: UserAuthType.SIGN_IN
}

const userAuthSlice = createSlice({
    name: "userAuthFlow",
    initialState: initialUserAuthFlowState,
    reducers: {
        updateUserAuthFlow: (state, action) => {
            return {...action.payload}
        }
    }
})

export default userAuthSlice.reducer;
export const { updateUserAuthFlow } = userAuthSlice.actions;