import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../state/hooks";
import { toggleSelectedGenerationTab, SELECTED_PROFILE_IMAGE_TYPE } from "../../../state/reducers/user/userProfileReducer";
import RecentCollection from "./profileRecentCollection";
import SavedCollection from "./profileSavedCollection";

export default function GenerationCollectionSection() {
    
    const profileState = useAppSelector(state => state.profileCollection);
    const dispatch = useDispatch();

    return (
        <>
            <div className="flex max-w-8xl pt-4 pb-2 justify-center md:justify-start">
                <h1 className="text-2xl font-secondary 
                text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">Generations</h1>
            </div>
            <div className="flex max-w-8xl justify-center md:justify-start">
                <button onClick={() => toggleCreatedSelected(SELECTED_PROFILE_IMAGE_TYPE.RECENT)} className={returnCollectionSelectorButton(SELECTED_PROFILE_IMAGE_TYPE.RECENT)}>Recent</button>
                <button onClick={() => toggleCreatedSelected(SELECTED_PROFILE_IMAGE_TYPE.SAVED)} className={returnCollectionSelectorButton(SELECTED_PROFILE_IMAGE_TYPE.SAVED)}>Saved</button>
            </div>
            <div className="flex max-w-8xl mx-auto justify-center items-center p-2">
                <ReturnUserImageData/>
            </div>
        </>
    )

    function ReturnUserImageData() {
        if (profileState.selectedImageSectionType === SELECTED_PROFILE_IMAGE_TYPE.RECENT) {
            return (
                <div className="w-full flex justify-center grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4">
                    {RecentCollection()}
                </div>
            )
        } else {
            return (
                <div className="w-full flex justify-center grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4">
                    {SavedCollection()}
                </div>
            )
        } 
    }

    function toggleCreatedSelected(selectedImageSectionType : SELECTED_PROFILE_IMAGE_TYPE) {
        dispatch(toggleSelectedGenerationTab({selectedImageSectionType : selectedImageSectionType }));
    }

    function returnCollectionSelectorButton(selectedPage: SELECTED_PROFILE_IMAGE_TYPE) {
        if (profileState.selectedImageSectionType === selectedPage) {
            return "font-secondary mx-2 h-12 md:text-2xl text-center md:text-left font-bold underline underline-offset-8 underline-white text-white"
        } else {
            return "font-secondary mx-2 h-12 md:text-2xl text-center md:text-left font-bold hover:underline underline-offset-8 underline-white text-white"
        }
    }
}