import { Disclosure, Transition } from '@headlessui/react'
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state/hooks';
import { pushToSearchTags, removeSearchTag } from '../../../state/reducers/collection/homeCollectionReducer';

export enum CATEGORY_TYPE {
    ADULT,
    ANIME,
    VIDEOGAMES,
    MOVIES,
    NOUNS,
}

function CategoryDisclosure(type: CATEGORY_TYPE, myArray: string[]) {

    const dispatch = useDispatch();
    const collectionState = useAppSelector(state => state.homeCollection);
    
    if (myArray.length > 0) {
        return (
            <div className="w-full px-2 py-1">
                <div className="mx-auto w-full rounded-lg bg-black border-2 border-white p-2">
                <Disclosure>
                <Disclosure.Button className="flex w-full text-md md:text-lg font-secondary justify-start mx-2">{Title()}</Disclosure.Button>
                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <Disclosure.Panel>
                        <div className="flex w-full">
                            <ul className="flex flex-wrap w-full my-1">
                                {myArray.map((tag) => {
                                        return Tag(tag)
                                    })
                                }
                            </ul>
                        </div>
                    </Disclosure.Panel>
                </Transition>
                </Disclosure>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }

    function Tag(tag: string) {
        if (collectionState.searchTags.includes(tag)) {
            return (
                <li className="cursor-pointer bg-gradient-to-r
                from-white via-purple-300 to-teal-300 background-animate p-[1px] mx-1 my-1 rounded-lg text-sm md:text-lg font-third">
                <button onClick={() => removeTag(tag)} className="w-full h-full px-2 py-1 rounded-lg bg-transparent text-black focus:outline-none">{displayTag(tag)}</button>
                </li>
            )
        } else {
            return (
                <li className="cursor-pointer mx-1 my-1 rounded-lg text-sm md:text-lg font-third">
                    <button onClick={() => pushTag(tag)} className="w-full h-full px-2 py-1 rounded-lg bg-black border border-2-white text-white focus:outline-none">{displayTag(tag)}</button>
                </li>
            )
        }
    }

    function Title() {
        if (type === CATEGORY_TYPE.ANIME) {
            return (
                <h1 className="font-secondary text-lg text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">{`anime (${myArray.length.toString()})`}</h1>
            )
        } else if (type === CATEGORY_TYPE.NOUNS) {
            return (
                <h1 className="font-secondary text-lg text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">{`nouns (${myArray.length.toString()})`}</h1>
            )
        } else if (type === CATEGORY_TYPE.ADULT) {
            return (
                <>
                    <h1 className="
                        font-secondary 
                        text-lg text-animate 
                        bg-gradient-to-r from-white via-purple-300 to-teal-300 
                        bg-clip-text text-transparent font-black">{`adult (${myArray.length.toString()}) `}
                    </h1>
                </>
            )
        } else if (type === CATEGORY_TYPE.VIDEOGAMES) {
            return (
                <h1 className="font-secondary text-lg text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">{`videogames (${myArray.length.toString()})`}</h1>
            )
        } else if (type === CATEGORY_TYPE.MOVIES) {
            return (
                <h1 className="font-secondary text-lg text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">{`movies (${myArray.length.toString()})`}</h1>
            )
        }
    }

    function pushTag(tag: string) {
        dispatch(pushToSearchTags(tag));
    }

    function removeTag(tag: string) {
        dispatch(removeSearchTag(tag))
    }

    function displayTag(tagText: string) {
        let str = tagText;
        str = str.replace(/_/g, " ");
        return str
    }
}

export default CategoryDisclosure;