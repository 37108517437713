import TokenHeader from "./tokenHeader";
import RoadmapBody from "../roadmap/roadmapBody";
import TeamView from "../pricing/aboutTeamSection"

export default function TokenHomePage() {
  return (
    <div className="bg-space-gray pb-16 md:pb-0 pt-8">
      <TokenHeader/>
      <RoadmapBody/>
      <TeamView/>
    </div>
  )
}