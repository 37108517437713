import { useAppSelector } from "../../../../../state/hooks";
import { MODEL_TYPE, MODEL_VISIBILITY } from "../../../../../models/art/artModel";
import { MODEL_CARD_TYPE } from "../../../../model/standardModelCard";
import StandardModelCard from "../../../../model/standardModelCard";
import RequestModelCard from "../cards/requestModelCard";

export default function PrivateModelSection() {
    const modelState = useAppSelector(state => state.artModelState);
    const profileState = useAppSelector(state => state.profileCollection);

    return (
        <>
            {RequestModelCard(MODEL_VISIBILITY.PRIVATE)}
            <DisplayModels/>
        </>
    )

    function DisplayModels() {
        if (profileState.registeredTgChats.length > 0) {
            return (
                <>
                    {modelState.models.map((model) =>  
                        (model.modelVisibility === MODEL_VISIBILITY.PRIVATE && model.modelType === MODEL_TYPE.LORA) ?  StandardModelCard(model, MODEL_CARD_TYPE.PROFILE) : <></>    
                    )}
                </>
            )
        }
    }
}