
import { createSlice } from "@reduxjs/toolkit";
import { Scatter } from "recharts";

export enum PresalePurchasingStatus {
    STANDBY,
    ERROR,
    ALREADY_PARTICIPATED,
    ESTABLISHING_CONNECTION,
    ESTIMATING_PURCHASE_GAS,
    AWAITING_PURCHASE_CONFIRMTION,
    AWAITING_PURCHASE_RECEIPT
}

export interface PresaleStatus {
    errorMessage: string
    status: PresalePurchasingStatus
}

export interface PresaleState {
    presaleStatus: PresaleStatus
    airdroptokenAmount: number,
    totalRemainingAmount: number,
    totalParticipants: number,
    initialLoad: boolean
}


export const initialPresaleState: PresaleState = { 
    initialLoad: false,
    airdroptokenAmount: 0,
    totalRemainingAmount: 0,
    totalParticipants: 0,
    presaleStatus: {
        errorMessage: "",
        status: PresalePurchasingStatus.STANDBY,
    },
}

const presaleStateReducer = createSlice({
    name: "presale",
    initialState: initialPresaleState,
    reducers: {
        updatePresaleStatus: (state, action) => {
            state.presaleStatus = action.payload
        },
        updatePresaleUserData: (state, action) => {
            state.initialLoad = true;
            state.airdroptokenAmount = action.payload.airdroptokenAmount;
            state.totalParticipants = action.payload.totalParticipants;
            state.totalRemainingAmount = action.payload.totalRemainingAmount;
        }
    }
});

export default presaleStateReducer.reducer;
export const { 
    updatePresaleStatus,
    updatePresaleUserData
} = presaleStateReducer.actions;