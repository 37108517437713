import RoadmapCell, { RoadmapCellData } from "./roadmapCell";

export enum POSITION {
    LEFT,
    RIGHT
}

const roadmapData: RoadmapCellData[] = [
    {
        timeframe: "PHASE 1",
        items: [
            "PARTNERSHIP SCOUTING [JAN/2023 - NOV/2024]",
            "TOKEN PRESALE [APRIL/2024 - NOV/2024]",
            "CUSTOM MODELS [DOBO, HOBA, SKIBIDI]",
            "LAUNCH TOKEN [NOV/2024]",
        ],
        position: POSITION.LEFT,
        videoUrl: "https://www.youtube.com/watch?v=qQOmnRvz1i8",
        thumbnail: "https://lucidai-prod.s3.us-east-1.amazonaws.com//media/floating_dobos_thumbnail.png",
        chosen: true
    },
    {
        timeframe: "PHASE 2",
        items: [
            "MOTION [JAN/2025 - FEB/2025]",
            "WEB2 Payments [JAN/2025 - FEB/2025]",
            "PARTNERSHIP APPLICATION FORMS",
            "SOCIAL MEDIA CAMPAIGN BEGINS",
        ],
        position: POSITION.RIGHT,
        videoUrl: "",
        thumbnail: "",
        chosen: false
    },
    {
        timeframe: "PHASE 3",
        items: [
            "SCALE PARTNERSHIPS",
            "NEW AI FEATURES AND AGENTS",
            "MOVE TOWARDS FULL DECENTRALIZATION",
        ],
        position: POSITION.LEFT,
        videoUrl: "",
        thumbnail: "",
        chosen: false
    },
    {
        timeframe: "PHASE 4",
        items: [
            "TBA"
        ],
        position: POSITION.RIGHT,
        videoUrl: "",
        thumbnail: "",
        chosen: false
    }
]


function RoadmapBody() {
    return (
        <div className="bg-space-gray">
            <div className="mx-auto max-w-8xl pt-8 md:pt-16 pb-8 md:pb-12 px-6 lg:px-8">
                <div className="text-center">
                    <h1 className="mt-1 text-4xl font-bold font-primary tracking-tight sm:text-5xl lg:text-6xl 
                    text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">
                        ROADMAP
                    </h1>
                </div>
            </div>
            <div className="flex justify-center items-end mx-auto max-w-6xl">
                <div className="flex items-center justify-center px-2 h-16 rounded-lg bg-white ">
                   <h1 className="text-md xl:text-lg font-bold font-third">START</h1>
                </div>
            </div>
            <div className="mx-auto max-w-6xl">
                {roadmapData.map((cell) => (<RoadmapCell{...cell}/>))}
            </div>
            <div className="flex justify-center items-start mx-auto max-w-6xl pb-12">
                <div className="flex items-center justify-center p-2 rounded-lg bg-white ">
                    <h1 className="text-md xl:text-lg font-bold font-third">TBA</h1>
                </div>
            </div>
        </div>
    )
}

export default RoadmapBody;