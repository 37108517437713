import { Network, UserArt } from "../../../models";
import { pushToSavedCollection, removeFromSavedCollection } from "../../../state/reducers/user/userProfileReducer";
import axios from "axios";
import HandleAlert, { errorAlert, successAlert } from "../../../utils/handleAlert";

const SAVE_ART_ROUTE = "https://webhook.lucidai.art/saveArt";

export async function toggleArtSave(isSaving: boolean, dispatch: any, art: UserArt, network: Network) {

    let body = {
        publicAddress: network.selectedAccount,
        removeSavedArt: !isSaving,
        jobId: art.jobId
    }

    const headers = {
        'Authorization': `Bearer ${network.jwtToken}`
    };

    let response = null;

    try {
        response = await axios.post(SAVE_ART_ROUTE, body, { headers: headers });
    } catch (error) {
        console.log(body);
        console.log(error);
        let errorMessage = isSaving ? "Issue saving art to profile" : "Issue removing art from profile"
        HandleAlert(errorAlert(errorMessage), dispatch);
        return;
    }   

    if (isSaving && response !== null && response.data.success) {
        dispatch(pushToSavedCollection(art))
        HandleAlert(successAlert("Saved art to profile"), dispatch)
    } else {
        dispatch(removeFromSavedCollection(art.jobId))
        HandleAlert(successAlert("Removed art from profile"), dispatch)
    }
}