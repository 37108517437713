import Web3 from "web3";
import { Network } from "../../../models";
import { AbiItem } from "web3-utils";
import ContractAbi from "../../../abis/test_LucidMaticHandler.json";
import { updateUserRewards } from "../../../state/reducers/user/userProfileReducer";

export default async function updateClaimableTokensState(dispatch: any, networkState: Network) {
    
    const web3Connection = new Web3(networkState.web3Host);
    let contractInstance = new web3Connection.eth.Contract(ContractAbi.abi as AbiItem[], networkState.contractAddress);
    try {
        const rewards = await contractInstance.methods.tokensEarned(networkState.selectedAccount).call({from: networkState.selectedAccount})
        //@ts-ignore
        if (rewards > 0) {
            dispatch(updateUserRewards(rewards));
        }
    } catch (error) {
        console.log("error fetching rewards: " + error)
    }
}