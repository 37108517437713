import { useDispatch } from "react-redux";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { MODEL_VISIBILITY } from "../../models/art/artModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/pro-solid-svg-icons";
import SelectorModelCard from "../model/selectorModelCard";

function ModelSelectorModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const artFilterState = useAppSelector(state => state.artCreationFilter);
    const artModelState = useAppSelector(state => state.artModelState);
    const networkState = useAppSelector(state => state.network);

    const [modelVisibility, setModelVisibility] = useState(MODEL_VISIBILITY.BASE);
    
    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.MODEL_SELECTOR} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity"/>
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div className="flex pt-4 px-2 justify-center items-center md:justify-start">
                                    <div className="border-2 border-white rounded-lg p-1">
                                        <button onClick={() => toggleBaseSelected(MODEL_VISIBILITY.BASE)} className={modelVisibility === MODEL_VISIBILITY.BASE ? returnModelSelectorButton(true) : returnModelSelectorButton(false)}>Base</button>
                                    </div>
                                    <div className="flex items-center justify-center mx-3">
                                        <FontAwesomeIcon icon={faPlusSquare} color="white" size="2x"/>
                                    </div>
                                    <div className="border-2 border-white rounded-lg p-1">
                                        <div className="flex">
                                            <button onClick={() => toggleBaseSelected(MODEL_VISIBILITY.PUBLIC)} className={modelVisibility === MODEL_VISIBILITY.PUBLIC ? returnModelSelectorButton(true) : returnModelSelectorButton(false)}>Public</button>
                                            <button onClick={() => toggleBaseSelected(MODEL_VISIBILITY.PRIVATE)} className={modelVisibility === MODEL_VISIBILITY.PRIVATE ? returnModelSelectorButton(true) : returnModelSelectorButton(false)}>Private</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex pt-2 md:pt-4 px-2 items-center justify-start">
                                    <h1 className="text-white font-secondary text-sm sm:text-lg md:text-2xl font-bold">Models Selected: {artFilterState.loras.length + 1}</h1>
                                </div>
                                <div className="flex justify-center p-2">
                                    <DisplayModels/>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.MODEL_SELECTOR, show: false}));
    }

    function DisplayModels() {
        if (modelVisibility === MODEL_VISIBILITY.BASE) {
            return (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 md:p-2">
                    {artModelState.models.map((model) =>  
                        (model.modelVisibility === MODEL_VISIBILITY.BASE) ?  <SelectorModelCard{...model}/> : <></>    
                    )}
                </div>
            )
        } else if (modelVisibility === MODEL_VISIBILITY.PUBLIC) {
            return (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 md:p-2">
                    {artModelState.models.map((model) => 
                        (model.modelVisibility === MODEL_VISIBILITY.PUBLIC) ?  <SelectorModelCard{...model}/> : <></>    
                    )}
                </div> 
            )
        } else {
            return (
                <DisplayPrivateModels/>
            )
        }
    }

    function DisplayPrivateModels() {
        if (networkState.selectedAccount === "") {
            return (
                <div className="flex-grow flex justify-center items-center w-64 h-64 md:w-96">
                    <h1 className="w-1/2 text-white text-md md:text-xl font-secondary text-center">Sign in to View Private Models</h1>
                </div>
            )
        } else {
            return (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 md:p-2">
                    {artModelState.models.map((model) => 
                        (model.modelVisibility === MODEL_VISIBILITY.PRIVATE) ?  <SelectorModelCard{...model}/> : <></>    
                    )}
                </div> 
            )
        }
    }

    function returnModelSelectorButton(underline: boolean) {
        if (underline) {
            return "font-secondary mx-2 h-12 text-sm sm:text-lg md:text-2xl font-bold underline underline-offset-8 underline-white text-white"
        } else {
            return "font-secondary mx-2 h-12 text-sm sm:text-lg md:text-2xl font-bold hover:underline underline-offset-8 underline-white text-white"
        }
    }

    function toggleBaseSelected(selectedModelVisibility: MODEL_VISIBILITY) {
        setModelVisibility(selectedModelVisibility);
    }
}

export default ModelSelectorModal;