import { useDispatch } from "react-redux";
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import ReactPlayer from 'react-player';
import useOutsideClick from "../../utils/useOutsideClick";

function VideoModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const videoRef = useRef(null);
    const modalRef = useRef(null);

    useOutsideClick(modalRef, () => {        
    //@ts-ignore
    if (videoRef !== null && videoRef.current !== null 
        //@ts-ignore
        && videoRef.current.player !== null 
        //@ts-ignore
        && videoRef.current.player.player != null
        //@ts-ignore
        && videoRef.current.player.player.player != null) {
            //@ts-ignore
            videoRef.current.player.player.player.stopVideo()
        } 
    });
  
    return (
        <div ref={modalRef}>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.VIDEO} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center min-h-full justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="transform transition-all relative w-5/6 md:w-3/6 px-4 overflow-hidden rounded-lg bg-black text-left shadow-xl">
                                <div>
                                    <div className="flex justify-center items-center w-full">
                                        <ReactPlayer ref={videoRef} height="400px" width="100%" url={modalState.videoUrl}></ReactPlayer>
                                    </div>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: false}));
    }
}

export default VideoModal;