import axios from "axios";
import { Network } from "../../../models";

const RESTRICTED_REGIONS = ['VA', 'UT']

export async function verifyIP(dispatch: any, network: Network) {
    const regionCode = await getRegionCode();
    if (regionCode !== null && RESTRICTED_REGIONS.includes(regionCode)) {
        return true;
    }
    return false;
}

async function getRegionCode(): Promise<string | null> {
    try {
        const response = await axios.get("https://ipapi.co/json/");
        return response.data.region_code;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getAddress(): Promise<string | null> {
    try {
        const response = await axios.get("https://ipapi.co/json/");
        return response.data.ip;
    } catch (error) {
        console.log(error);
        return null;
    }
}