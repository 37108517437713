import { createSlice } from "@reduxjs/toolkit";
import { ArtModel } from "../../../models/art/artModel";

export interface ArtModelState {
    models: ArtModel[]
    isLoaded: boolean
    selectedCommunityModel: ArtModel
}

const initialArtModelState: ArtModelState = {
    models: [],
    isLoaded: false,
    selectedCommunityModel: null
}

const artModelReducer = createSlice({
    name: "artModels",
    initialState: initialArtModelState,
    reducers: {
        updateArtModelState: (state, action) => {
            state.models = action.payload;
            state.isLoaded = true;
        },
    }
})

export default artModelReducer.reducer;
export const { updateArtModelState } = artModelReducer.actions;