import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ProfileState } from "../../../models/collection"
import { ACCOUNT_TYPE, MODAL_TYPE } from "../../../models/modal"
import { faSquareXmark } from "@fortawesome/pro-light-svg-icons";
import DiscordAuth from "../oauth/discordOAuth"
import TelegramAuth from "../oauth/telegramOauth"
import { updateAccountLinkModalState } from "../../../state/reducers/modal/modalReducer";
import { useAppDispatch } from "../../../state/hooks";


function ProfileLinkSection(profileCollectionState: ProfileState) {

    const dispatch = useAppDispatch();
    
    return (
        <>
            <div className="flex pt-2 justify-center items-center">
                <h1 className="font-secondary text-white text-xl">1. Link Telegram Admin Account</h1>
            </div>
            <div className="w-full pt-1 pb-2 bg-space-gray">
                <div className="flex w-full justify-center items-center mt-3">
                    <div className="flex items-center">
                        {Username(ACCOUNT_TYPE.TELEGRAM)}
                        {UnlinkButton(ACCOUNT_TYPE.TELEGRAM)}
                    </div>
                </div>
                <div className="flex w-full justify-center items-center my-2">
                    <div>
                        <TelegramAuth/>
                    </div>
                </div>
            </div>  
        </>
    )

    function UnlinkButton(type: ACCOUNT_TYPE) {
        if (profileCollectionState.linkedTelegramUsername !== "" && type === ACCOUNT_TYPE.TELEGRAM) {
            return (
                
                <FontAwesomeIcon onClick={() => unlinkUser(ACCOUNT_TYPE.TELEGRAM)} icon={faSquareXmark} color="white" size="lg" className="ml-2 cursor-pointer"/>
            )
        } else if (profileCollectionState.linkedDiscordUsername !== "" && type === ACCOUNT_TYPE.DISCORD) {
            return (
                <FontAwesomeIcon onClick={() => unlinkUser(ACCOUNT_TYPE.DISCORD)} icon={faSquareXmark} color="white" size="lg" className="ml-2 cursor-pointer"/>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function Username(type: ACCOUNT_TYPE) {
       if (type === ACCOUNT_TYPE.TELEGRAM && profileCollectionState.linkedTelegramUsername !== "") {
        return (
            <h1 className="select-none text-white text-sm">{"@" + profileCollectionState.linkedTelegramUsername}</h1>
        )
       
       } else if (type === ACCOUNT_TYPE.DISCORD && profileCollectionState.linkedDiscordUsername !== "") {
        return (
            <h1 className="select-none text-white text-sm">{"@" + profileCollectionState.linkedDiscordUsername}</h1>
        )
       } else if (type === ACCOUNT_TYPE.DISCORD) {
        return (
            <h1 className="select-none text-sm text-gray-400">Discord not linked yet</h1>
        )
       } else {
        return (
            <h1 className="select-none text-sm text-gray-400">Telegram not registered yet</h1>
        )
       }
    }

    function unlinkUser(type: ACCOUNT_TYPE) {
        let accountLink = type === ACCOUNT_TYPE.DISCORD ? { username: profileCollectionState.linkedDiscordUsername, type: ACCOUNT_TYPE.DISCORD } : { username: profileCollectionState.linkedTelegramUsername, type: ACCOUNT_TYPE.TELEGRAM}
        dispatch(updateAccountLinkModalState({type: MODAL_TYPE.ACCOUNT_LINK, show: true, accountLink: accountLink}));
    }
    
}

export default ProfileLinkSection;