

export const testAddress = "";


export const addEthNetwork = async () => {
    //@ts-ignore
    window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
            chainId: '0x1',
            chainName: 'Ethereum',
            nativeCurrency: {
                name: 'Ethereum',
                symbol: 'ETH',
                decimals: 18
            },
            rpcUrls: ['https://mainnet.infura.io/v3/'],
            blockExplorerUrls: ['https://etherscan.io']
        }]
    });
}

export const addPolygonNetwork = async () => {
    //@ts-ignore
    window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
            chainId: '0x89',
            chainName: 'Polygon',
            nativeCurrency: {
                name: 'Polygon',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com/']
        }]
    });
}