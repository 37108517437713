import { useAppSelector } from '../../state/hooks';
import { AlertType } from "../../models/alert/alert";
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { faCheckToSlot, faCircleExclamation, faTriangleExclamation, faStamp  } from '@fortawesome/pro-regular-svg-icons';
import { CHAIN_ID } from '../../models';
import HandleAlert from '../../utils/handleAlert';
import { useDispatch } from 'react-redux';
import { faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons';

function AlertView() {
   
    const alert = useAppSelector(state => state.alert);
    const networkState = useAppSelector(state => state.network);
    const dispatch = useDispatch();

    return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none z-50 fixed inset-0 flex px-4 py-6 items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={alert.show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-black border border-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    {alertTitle()}
                    {alertMessageBody()}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-black"
                      onClick={() => dismissAlert()}
                    >
                      <FontAwesomeIcon icon={faXmark} size="sm" color="white"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )

  function alertTitle() {
    if (alert.type === AlertType.ERROR) {
      return (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faCircleExclamation} size="lg" color="white" className=""/>
          <p className="text-xl ml-2 mt-[1px] font-bold text-animate 
            bg-gradient-to-r from-red-700 via-red-300 to-red-600 bg-clip-text 
            text-transparent font-black drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
            ERROR
          </p>
        </div>
      )
    }
    if (alert.type === AlertType.SUCCESS) {
      return (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faCheckToSlot} size="1x" color="white"/>
          <p className="text-xl ml-2 font-bold text-animate 
            bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text 
            text-transparent font-black drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
            SUCCESS
          </p>
        </div>
      )
    }
    if (alert.type === AlertType.LINK_SUCCESS) {
      return (
        <div className="flex items-center">
          <AccountLinkIcon/>
          <p className="text-xl ml-2 font-bold text-animate 
            bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text 
            text-transparent font-black drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
            SUCCESS
          </p>
        </div>
      )
    }
    if (alert.type === AlertType.TRX_MINTED) {
      return (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faStamp} size="1x" color="white"/>
          <p className="text-xl ml-2 font-bold text-animate 
            bg-gradient-to-r from-blue-100 via-teal-300 to-blue-700 bg-clip-text 
            text-transparent font-black drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
            MINTED
          </p>
        </div>
      )
    }
    if (alert.type === AlertType.WARNING) {
      return (
        <div className="flex items-center">
          <FontAwesomeIcon icon={faTriangleExclamation} size="lg" color="white" className="pb-[1px]"/>
          <p className="text-xl ml-2 font-bold text-animate 
            bg-gradient-to-r from-red-800 via-orange-300 to-red-300 bg-clip-text 
            text-transparent font-black drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
            WARNING
          </p>
        </div>
      )
    }
  }

  function AccountLinkIcon() {
    return (
      <FontAwesomeIcon icon={faTelegram} size="1x" color="white"/>
    )
  }

  function alertMessageBody() {
    if (alert.type === AlertType.TRX_MINTED) {
      return (
        <p className="mt-1 text-md text-white">TRADE OR VIEW:  
          <a className="ml-1
            font-bold 
            text-animate 
            bg-gradient-to-r from-blue-100 via-teal-300 to-blue-700 bg-clip-text 
            text-transparent font-black" 
              href={alert.url} target="_">
              opensea.io
            </a>
        </p>
      )
    }
    if (alert.url === "" && (AlertType.ERROR || AlertType.WARNING || AlertType.SUCCESS)) {
      return (
        <p className="mt-1 text-md text-gray-500">{alert.message}</p>
      )
    }
    if (alert.url !== "" && (AlertType.ERROR || AlertType.WARNING)) {
      return (
        <p className="mt-1 text-md text-white">{alert.message} 
        <a className="ml-1
          font-bold 
          text-animate 
          bg-gradient-to-r from-red-800 via-orange-300 to-red-300 bg-clip-text 
          text-transparent font-black" 
            href={alert.url} target="_">
            here
          </a>
      </p>
      )
    }
  }

  function dismissAlert() {
    HandleAlert({type: alert.type, show: false, message: alert.message, url: alert.url }, dispatch)
  }
}

export default AlertView;