import axios from "axios";
import { Network } from "../../../models"
import HandleAlert, { accountLinkSuccess, errorAlert } from "../../../utils/handleAlert";
import { RegisteredChannel } from "../../../models/collection/registeredChannel";
import { removeChannelAtIndex } from "../../../state/reducers/user/userProfileReducer";

const UNREGISER_LINK_ROUOTE = "https://webhook.lucidai.art/removeCommunityRegistration"

export default async function removeChannel(channel: RegisteredChannel, telegramUsername: string, network: Network, dispatch: any): Promise<boolean> {
    const headers = {
        'Authorization': `Bearer ${network.jwtToken}`
    };

    let body = {
        chatId: channel.chatId,
        telegramUsername: telegramUsername,
    }

    let response = undefined;
    try {
        response = await axios.post(UNREGISER_LINK_ROUOTE, body, { headers: headers });
        if (response.status === 200) {
            dispatch(removeChannelAtIndex({ channelToRemove: channel.chatId }))
            successAlert(channel.name, dispatch);
            return true;
        }
    } catch (error) {
        if (error.response.data.status) {
            HandleAlert(errorAlert(error.response.data.status), dispatch)
        } else {
            HandleAlert(errorAlert("Error removing channel from registration list."), dispatch)
        }
        return false;
    }
    
    return false;
}

function successAlert(channelName: string, dispatch: any) {
    let alertBody = "Removed " + channelName + " Registration";
    HandleAlert(accountLinkSuccess(alertBody), dispatch);
}