import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    IconDefinition, 
    faGithub 
} from "@fortawesome/free-brands-svg-icons";
import { 
    faMemoCircleInfo, 
    faMagnifyingGlass, 
    faScrewdriverWrench 
} from "@fortawesome/pro-regular-svg-icons";

export interface DocObject {
    icon: IconDefinition
    url: string
    title: string
}

export default function DocsBody() {
    
    const docs: DocObject[] = [
        { 
            title: "Full Docs",
            icon: faMagnifyingGlass,
            url: "https://lucidai.gitbook.io/docs"
        },
        {
            title: "Whitepaper", 
            icon: faMemoCircleInfo,
            url: "https://lucidai.gitbook.io/docs/whitepaper"
        },
        {
            title: "Tutorials", 
            icon: faScrewdriverWrench,
            url: "https://lucidai.gitbook.io/docs/tutorials"
        },
        {
            title: "Public Repos", 
            icon: faGithub,
            url: "https://github.com/LucidFounder/LucidAIOfficial"
        }
    ] 

    return (
        <div className="w-max-8xl min-h-screen mx-auto bg-space-gray pt-6">
            <DisplayDocButtons/>
        </div>
    )

    function DisplayDocButtons() {
        return (
            <div className="flex justify-center p-2">
                <div className="bg-black grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 md:p-4 rounded-lg">
                    {docs.map((doc) =>  
                        (DocLinkCard(doc))   
                    )}
                </div>
            </div>
        )
    }

    function DocLinkCard(doc: DocObject) {
        return (
            <a href={doc.url} target="_" className="cursor-pointer w-32 h-32 md:w-48 md:h-48 bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px] rounded-lg transform transition-all hover:scale-105">
                <div className="flex w-full h-1/2 pb-2 justify-center items-end bg-white rounded-t-lg">
                    <FontAwesomeIcon icon={doc.icon} size="2x"/>
                </div>
                <div className="flex w-full h-1/2 pt-2 justify-center items-start bg-white rounded-b-lg">
                    <div className="flex justify-center w-3/4 h-full">
                        <h1 className="font-secondary text-black text-center text-xs md:text-lg">{doc.title}</h1>
                    </div> 
                </div>
            </a>
        )
    }

}