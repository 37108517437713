import { Switch } from '@headlessui/react';
import { useDispatch } from "react-redux";
import { useAppSelector } from '../../../state/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faShield } from '@fortawesome/pro-regular-svg-icons';
import { updateModalState } from '../../../state/reducers/modal/modalReducer';
import { MODAL_TYPE } from '../../../models/modal';
import { updateNsfwFlag, clearNsfwIds } from '../../../state/reducers/collection/homeCollectionReducer';

export default function NSFWHomeSwitch() {

    const networkState = useAppSelector(state => state.network);
    const homeState = useAppSelector(state => state.homeCollection);
    const dispatch = useDispatch();

    return (
        <div className="flex items-center">
            <Switch
                checked={homeState.nfswFlag}
                onChange={() => toggleNSFW()}
                className="relative inline-flex h-6 w-12 items-center rounded-full bg-black border-2 border-white"
                >
                <span>
                    <FontAwesomeIcon icon={faCircleExclamation} color='#FFFFFF' size="xs" className="absolute inset-y-0 left-0 mt-[4px] ml-[7px]"/>
                    <FontAwesomeIcon icon={faShield} color='#FFFFFF' size="xs" className="absolute inset-y-0 right-0 mt-[4px] mr-[7px]"/>
                </span>
                <span className="inline-block h-4 w-4 transform rounded-full bg-white transition ui-checked:translate-x-6 ui-not-checked:translate-x-1" />
            </Switch> 
        </div> 
    )

    function toggleNSFW() {
        if (networkState.selectedAccount === "" || networkState.jwtToken === "") {
            dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: true, signInMessage: "Sign in to generate and view NSFW content"}));
        } else {
            dispatch(updateNsfwFlag(homeState.nfswFlag ? false : true));
            dispatch(clearNsfwIds());
        }
    }
}