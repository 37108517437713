export interface Alert {
    type: AlertType
    show: Boolean
    message: String
    url: string
}

export enum AlertType {
    LINK_SUCCESS,
    TRX_MINTED,
    SUCCESS,
    WARNING,
    ERROR
}