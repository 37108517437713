import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { getPresaleChartData } from '../../../networking/presale/presaleData';

const COLORS = ["#0d0d0d","#0088FE"]

interface DataPoint {
  name: string;
  value: number;
}

export default class PresaleTokenChart extends PureComponent {
  
  state = {
    loading: true,
    data: [] as DataPoint[],
  };

  componentDidMount() {
    // Simulate fetching data after a delay
    setTimeout(async () => {
      if (this.state.loading) {
        let totalRemainingAirdropSupply = await getPresaleChartData();
        if (totalRemainingAirdropSupply) {
          let filled = 400000000 - parseInt(totalRemainingAirdropSupply)
          const data = [
            { name: 'spacing', value: parseInt(totalRemainingAirdropSupply) },
            { name: 'filled', value: filled },
          ];
          this.setState({ loading: false, data });
        }
      }
    }, 2000);
  }

  render() {

    const { loading, data } = this.state;
    if (!loading) {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              innerRadius={70}
              outerRadius={85}
              stroke='#0d0d0d'
              paddingAngle={2}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    }
  }
}


