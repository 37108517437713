import { createSlice } from "@reduxjs/toolkit";
import { HomeCollection } from "../../../models/collection/homeCollection";

const initialHomeCollectionState: HomeCollection  = {
    initialLoad: false,
    scrolledToBottom: false,
    nfswFlag: false,
    asc: false,
    loading: true,
    totalGenerations: 0,
    maxTagLength: 30,
    collection: [],
    nsfwIds: [],
    filteredCollection: [],
    searchTags: [],
}

const profileSlice = createSlice({
    name: "homeCollection",
    initialState: initialHomeCollectionState,
    reducers: {
        updateHomeCollection: (state, action) => {
            return {...action.payload}
        },
        pushToHomeCollection: (state, action) => {
            state.collection.push(...action.payload);
            if (state.searchTags.length > 0) {
                state.filteredCollection = state.collection.filter(art => state.searchTags.some(value => art.prompt.some(promptTag => promptTag.rawText.includes(value))))
            } else {
                state.filteredCollection.push(...action.payload)
            }
            state.initialLoad = true;
            state.loading = false;
            state.scrolledToBottom = false;
        },
        pushToSearchTags: (state, action) => {
            state.filteredCollection = []
            state.searchTags.push(action.payload);
            state.filteredCollection = state.collection.filter(art => state.searchTags.some(value => art.prompt.some(promptTag => promptTag.displayText.includes(value))))
        },
        removeSearchTag: (state, action) => {
            state.filteredCollection = []
            state.searchTags = state.searchTags.filter(value => value !== action.payload);
            state.filteredCollection = state.collection.filter(art => state.searchTags.some(value => art.prompt.some(promptTag => promptTag.displayText.includes(value))))
            if (state.searchTags.length === 0) {
                state.filteredCollection = state.collection;
            }
        },
        clearSearchTags: (state) => {
            state.searchTags = [];
            state.filteredCollection = state.collection;
        },
        removeLastSearchTag: (state) => {
            if (state.searchTags.length > 0) {
                state.searchTags.pop();
            }
        },
        toggleAscendingFilter: (state, action) => {
            state.asc = action.payload;
        },
        setScrolledToBottomFlag: (state, action) => {
            state.scrolledToBottom = action.payload;
        },
        updateGenerationStatistic: (state, action) => {
            state.totalGenerations = action.payload;
        },
        clearHomeCollection: (state) => {
            state.collection = []
            state.filteredCollection = [] 
        },
        clearNsfwIds: (state) => {
            state.nsfwIds = []
        },
        pushToNsfwIds: (state, action) => {
            state.nsfwIds.push(action.payload);
        },
        updateNsfwFlag: (state, action) => {
            state.nfswFlag = action.payload;
        }
    }
})

export default profileSlice.reducer;
export const { 
    updateHomeCollection, 
    pushToSearchTags, 
    clearSearchTags,
    removeSearchTag,
    removeLastSearchTag,
    pushToHomeCollection,
    toggleAscendingFilter,
    clearHomeCollection,
    setScrolledToBottomFlag,
    updateGenerationStatistic,
    updateNsfwFlag,
    pushToNsfwIds,
    clearNsfwIds
} = profileSlice.actions;