import { useDispatch } from "react-redux";
import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppSelector } from "../../state/hooks";
import { MODAL_TYPE } from "../../models/modal";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import CategoryDisclosure, { CATEGORY_TYPE } from "../art/prompt/tagCategoryDisclosure";
import { existsInCategory } from "../../utils/dictionary";
import { clearSearchTags } from "../../state/reducers/collection/homeCollectionReducer";


function SearchTagModal() {

    const dispatch = useDispatch();
    const modalState = useAppSelector(state => state.modal);
    const collectionState = useAppSelector(state => state.homeCollection);

    const adult: string[] = [];
    const anime: string[] = [];
    const movies: string[] = [];
    const nouns: string[] = [];
    const videogames:string[] = [];

    function tagAlreadyLoaded(tag: string) {
        return adult.includes(tag) || anime.includes(tag) || movies.includes(tag) || nouns.includes(tag) || videogames.includes(tag)
    }

    function buildCategories() {
        collectionState.collection.forEach((art) => {
            art.prompt.map((tag) => {
                if (existsInCategory(tag.displayText, CATEGORY_TYPE.ADULT) && !tagAlreadyLoaded(tag.displayText))  {
                    adult.push(tag.displayText)
                }
                if (existsInCategory(tag.displayText, CATEGORY_TYPE.ANIME) && !tagAlreadyLoaded(tag.displayText))  {
                    anime.push(tag.displayText)
                }
                if (existsInCategory(tag.displayText, CATEGORY_TYPE.MOVIES) && !tagAlreadyLoaded(tag.displayText))  {
                    movies.push(tag.displayText)
                }
                if (existsInCategory(tag.displayText, CATEGORY_TYPE.NOUNS) && !tagAlreadyLoaded(tag.displayText))  {
                    nouns.push(tag.displayText)
                }
                if (existsInCategory(tag.displayText, CATEGORY_TYPE.VIDEOGAMES) && !tagAlreadyLoaded(tag.displayText))  {
                    videogames.push(tag.displayText)
                }
            })
        })
    }

    buildCategories();

    return (
        <div>
            <Transition.Root show={modalState.show && modalState.type === MODAL_TYPE.TAGS} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-center mb-32 md:mb-0 mx-8 md:mx-16 mt-4 md:mt-8 justify-center text-center">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className={`relative transform h-screen-1/2 w-full md:w-3/4 mx-4 transition-all overflow-y-auto rounded-lg bg-black text-left shadow-xl`}>
                                <div className="flex w-full">
                                   <div className="w-full my-1">
                                        {CategoryDisclosure(CATEGORY_TYPE.NOUNS, nouns)}
                                        {CategoryDisclosure(CATEGORY_TYPE.ADULT, adult)}
                                        {CategoryDisclosure(CATEGORY_TYPE.ANIME, anime)}
                                        {CategoryDisclosure(CATEGORY_TYPE.VIDEOGAMES, videogames)}
                                        {CategoryDisclosure(CATEGORY_TYPE.MOVIES, movies)}
                                   </div>
                                </div>
                                <div className="flex justify-between mx-4 mb-2">
                                    <div className="flex items-center">
                                        <h1 className="text-white font-secondary">{`filters: ${collectionState.searchTags.length}`}</h1>
                                    </div>
                                    <div className="flex items-center">
                                        <button onClick={() => clearSearchTagFilters()}>
                                            <h1 className="font-secondary text-white">clear</h1>
                                        </button>
                                    </div>

                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

    function closeModal() {
        dispatch(updateModalState({type: MODAL_TYPE.TAGS, show: false}));
    }

    function clearSearchTagFilters() {
        dispatch(clearSearchTags())
    }
}

export default SearchTagModal;