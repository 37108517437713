import { createSlice } from "@reduxjs/toolkit";
import { ProfileState } from "../../../models/collection";
import { MODEL_VISIBILITY } from "../../../models/art/artModel";

export enum SELECTED_PROFILE_IMAGE_TYPE {
    RECENT,
    SAVED,
}

const initialProfileCollectionState: ProfileState  = {
    initialProfileLoad: false,
    initialNftLoad: false,
    selectedImageSectionType: SELECTED_PROFILE_IMAGE_TYPE.RECENT,
    selectedModelSectionType: MODEL_VISIBILITY.BASE,
    profileStatus: true,
    initialRewardLoad: false,
    linkedDiscordUsername: "",
    linkedTelegramUsername: "",
    rewards: 0,
    nfts: [],
    savedCollection: [],
    userTierInfo: {
        currentTier: "",
        lastDepositTime: "",
        lastDepositValue: 0,
        totalDepositValue: 0,
        generationsLeft: 0
    },
    customModelApplications: [],
    customModelApplicationNetworkStatus: "",
    collection: [],
    registeredTgChats: [],
    ownedModels: [],
    baseModdels: [],
    communityModels: [],
    selectedChannelName: ""
}

const profileCollectionReducer = createSlice({
    name: "profileCollection",
    initialState: initialProfileCollectionState,
    reducers: {
        updateProfileCollection: (state, action) => {
            state.collection = action.payload.collection
            state.savedCollection = action.payload.savedCollection
            state.registeredTgChats = action.payload.registeredTgChats
            state.profileStatus = action.payload.profileStatus
            state.initialProfileLoad = action.payload.initialLoad
            state.userTierInfo = action.payload.userTierInfo
            state.customModelApplications = action.payload.customModelApplications
        },
        toggleSelectedGenerationTab: (state, action) => {
            state.selectedImageSectionType = action.payload.selectedImageSectionType;
        },
        toggleSelectedModelTab: (state, action) => {
            state.selectedModelSectionType = action.payload.selectedModelSectionType;
        },
        pushToSavedCollection: (state, action) => {
            state.savedCollection.push(action.payload)
        },
        removeFromSavedCollection: (state, action) => {
            state.savedCollection = state.savedCollection.filter(art => action.payload !== art.jobId);
        },
        updateProfileInfo: (state, action) => {
            state.linkedDiscordUsername = action.payload.discordUsername
            state.linkedTelegramUsername = action.payload.telegramUsername
        },
        updateUserRewards: (state, action) => {
            state.rewards = action.payload
            state.initialRewardLoad = true
        },
        updateTelegramLink: (state, action) => {
            state.linkedTelegramUsername = action.payload;
        },
        updateDiscordLink: (state, action) => {
            state.linkedDiscordUsername = action.payload;
        },
        setSelectedInputIndex: (state, action) => {
            state.selectedChannelName = action.payload;
        },
        updateChannelNameState: (state, action) => {
            let index = state.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === action.payload.channelNameKey.toLowerCase());
            // registering new channel update name
            if (!action.payload.isModifyingName) {
                state.registeredTgChats[index].name = action.payload.newChannelName
            }
            state.registeredTgChats[index].isModifying = action.payload.isModifyingName;
            state.selectedChannelName = action.payload.newChannelName.toLowerCase();
        },
        toggleModifyingNameState: (state, action) => {
            let index = state.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === action.payload.channelNameKey.toLowerCase());
            state.registeredTgChats[index].isModifying = action.payload.isModifying;
        },
        removeChannelAtIndex: (state, action) => {
            state.registeredTgChats = state.registeredTgChats.filter((channel) => channel.chatId !== action.payload.channelToRemove);
        },
        addModelToBlackList: (state, action) => {
            let index = state.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === action.payload.channelNameKey);
            state.registeredTgChats[index].blacklistedModels.push(action.payload.modelName.toLowerCase());
        },
        removeModelFromBlackList: (state, action) => {
            let index = state.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === action.payload.channelNameKey);
            state.registeredTgChats[index].blacklistedModels =  state.registeredTgChats[index].blacklistedModels.filter((modelName) => action.payload.modelName !== modelName.toLowerCase());
        },
        updateUserTierNameAfterPurchase: (state, action) => {
            state.userTierInfo.currentTier = action.payload;
        },
        pushCustomModelApplication: (state, action) => {
            state.customModelApplications.push(action.payload);
        },
        updateApplicationNetworkStatus: (state, action) => {
            state.customModelApplicationNetworkStatus = action.payload
        }
    }
});

export default profileCollectionReducer.reducer;
export const { 
    updateProfileCollection, 
    updateUserRewards,
    updateTelegramLink,
    updateDiscordLink,
    updateProfileInfo,
    pushToSavedCollection,
    removeFromSavedCollection,
    toggleSelectedGenerationTab,
    toggleSelectedModelTab,
    updateChannelNameState,
    setSelectedInputIndex,
    toggleModifyingNameState,
    removeChannelAtIndex,
    addModelToBlackList,
    removeModelFromBlackList,
    updateUserTierNameAfterPurchase,
    pushCustomModelApplication,
    updateApplicationNetworkStatus
} = profileCollectionReducer.actions;