import { faImage } from "@fortawesome/pro-light-svg-icons";
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../state/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    ArtModel, 
    GENERATIVE_TYPE, 
    MODEL_TYPE 
} from "../../models/art/artModel";
import { 
    faCircleExclamation, 
    faShield, 
    faCheck, 
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { 
    addModelToBlackList, 
    removeModelFromBlackList, 
} from "../../state/reducers/user/userProfileReducer";
import { updateModelInfoModalState } from "../../state/reducers/modal/modalReducer";
import { MODAL_TYPE } from "../../models/modal";
import HandleAlert, { successAlert } from "../../utils/handleAlert";
import { disableNetworkCooldown, enableNetworkCooldown } from "../../state/reducers/network/networkReducer";

export enum MODEL_CARD_TYPE {
    COMMUNITY,
    PROFILE
}

export default function StandardModelCard(model: ArtModel, type: MODEL_CARD_TYPE) {

    const profileState = useAppSelector(state => state.profileCollection);
    const networkState = useAppSelector(state => state.network);
    const dispatch = useDispatch();

    return (
        <div className={cardClass()}>
            <div className="flex items-center justify-center w-full h-full rounded-lg bg-black">
                <div className="w-full h-full">
                
                    <div className="flex h-3/4 w-full p-1">
                        <div className="relative w-full h-full rounded-lg">
                            
                            <div onClick={() => displayModelInfoModal()} className="absolute inset-0 rounded-lg">
                                <img className="w-full h-full rounded-lg object-cover" alt="" src={model.imageUrl}></img>
                            </div>
                            {selectedModelOvercast()}
                            {checkBox()}
                            <div className="absolute inset-x-0 bottom-0 h-8">
                                <div className="flex w-full h-full items-center justify-between"> 
                                    {NSFWIcon()}
                                    {modelGenerativeTypeIcon()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => displayModelInfoModal()} className="flex justify-center items-center h-1/4 w-full p-1">
                        <h1 className={textClass()}>
                            {model.name}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )

    function NSFWIcon() {
        if (model.nsfw) {
            return (
                <div className="flex items-center bg-black rounded-md py-[1px] px-[4px] ml-1 shadow-xl">
                    <FontAwesomeIcon icon={faCircleExclamation} className="mr-1 mb-[1px]" size="xs" color="white"/>
                    <h1 className="text-white font-third text-sm">NSFW</h1>
                </div>
            )
        } else {
            return (
                <div className="flex items-center bg-black rounded-md py-[1px] px-[4px] ml-1 shadow-xl">
                    <FontAwesomeIcon icon={faShield} className="mr-1 mb-[1px]" size="xs" color="white"/>
                    <h1 className="text-white font-third text-sm">SFW</h1>
                </div>
            )
        }
    }

    function modelGenerativeTypeIcon() {
        if (model.generativeType === GENERATIVE_TYPE.IMAGE) {
            return (
                <div className="flex items-center mr-[5px] md:mr-[6px] mb-[6px] shadow-xl">
                    <FontAwesomeIcon icon={faImage} className="bg-black rounded-lg px-[3px] py-[1px]" size="2x" color="white"/>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
      
    function checkBox() {
        if (type === MODEL_CARD_TYPE.PROFILE && model.modelType === MODEL_TYPE.LORA) {
            if (!modelIsBlackListed()) {
                return (
                    <button onClick={() => addToTgChannelBlacklist()} disabled={networkState.isNetworkCoolingDown} className="absolute left-0 h-8 w-8 md:h-10 md:w-10 p-1">
                        <div className="md:hidden flex w-full h-full justify-center items-center rounded-lg border-2 border-white">
                            <FontAwesomeIcon icon={faXmark} color='white' size='1x'/>
                        </div>
                        <div className="hidden md:flex w-full h-full justify-center items-center rounded-lg border-2 border-white">
                            <FontAwesomeIcon icon={faXmark} color='white' size='xl'/>
                        </div>
                    </button>
                )
            // unselected channel models
            } else {
                return (
                    <button onClick={() => removeFromTgChannelBlacklist()} disabled={networkState.isNetworkCoolingDown} className="absolute left-0 h-8 w-8 md:h-10 md:w-10 p-1">
                        <div className="md:hidden flex w-full h-full justify-center items-center rounded-lg border-2 border-white">
                            <FontAwesomeIcon icon={faCheck} color='white' size='1x'/>
                        </div>
                        <div className="hidden md:flex w-full h-full justify-center items-center rounded-lg border-2 border-white">
                            <FontAwesomeIcon icon={faCheck} color='white' size='xl'/>
                        </div>
                    </button>
                )
            }
        }
    }

    function selectedModelOvercast() {
         // selected channel models green overcast
        if (type === MODEL_CARD_TYPE.PROFILE && !modelIsBlackListed()) {
            return (
                <div onClick={() => displayModelInfoModal()} className="absolute inset-0 bg-green-500 opacity-50 rounded-lg"></div>
            )
        }
    }

    function cardClass() {
        if (type === MODEL_CARD_TYPE.PROFILE) {
             // unselected channel models 
            if (modelIsBlackListed()) {
                return "w-32 h-48 sm:h-48 sm:w-36 md:w-48 md:h-64 lg:h-72 lg:w-64 bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px] rounded-lg"
            } else {
             // selected channel models 
                return "w-32 h-48 sm:h-48 sm:w-36 md:w-48 md:h-64 lg:h-72 lg:w-64 bg-gradient-to-r from-green-500 via-teal-300 to-blue-500 background-animate p-[2px] rounded-lg"
            }
        } else {
            // community models
            return "cursor-pointer w-32 h-48 sm:h-48 sm:w-36 md:w-48 md:h-64 lg:h-72 lg:w-64 bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px] rounded-lg transform transition-all hover:scale-105"
        }
        
    }

    function modelIsBlackListed() {
        let index = profileState.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === profileState.selectedChannelName.toLowerCase());
        return index !== -1 &&
               profileState.registeredTgChats.length > 0 && 
               profileState.registeredTgChats[index].blacklistedModels !== undefined && 
               profileState.registeredTgChats[index].blacklistedModels.includes(model.name.toLowerCase())
    }

    function textClass() {
        return "text-xs md:text-lg font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black"
    }

    async function addToTgChannelBlacklist() {
        let index = profileState.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === profileState.selectedChannelName.toLowerCase());
        if (index !== -1 && !profileState.registeredTgChats[index].isModifying) {
            dispatch(enableNetworkCooldown());
             //TODO: NETWORK CALL HERE
            await sleep(2000)
            dispatch(addModelToBlackList({ modelName: model.name.toLowerCase(), channelNameKey: profileState.selectedChannelName }))
            HandleAlert(successAlert(profileState.selectedChannelName + ": " + model.name + " removed."), dispatch)
            dispatch(disableNetworkCooldown());
        }
       
    }

    async function removeFromTgChannelBlacklist() {
        let index = profileState.registeredTgChats.findIndex((channel) => channel.name.toLowerCase() === profileState.selectedChannelName.toLowerCase());
        if (index !== -1 && !profileState.registeredTgChats[index].isModifying) {
            dispatch(enableNetworkCooldown());
            //TODO: NETWORK CALL HERE
            await sleep(2000)
            dispatch(removeModelFromBlackList({ modelName: model.name.toLowerCase(), channelNameKey: profileState.selectedChannelName}))
            HandleAlert(successAlert(profileState.selectedChannelName + ": " + model.name + " added."), dispatch);
            dispatch(disableNetworkCooldown());
        } 
    }

    function displayModelInfoModal() {
        dispatch(updateModelInfoModalState({type: MODAL_TYPE.MODEL_INFO, show: true, model: model}))
    }
}