import { faFaceDisappointed } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function VerificationPage() {

    return (
        <div className="min-h-screen">
            <div className="flex items-center justify-center w-full bg-space-gray pt-64">
                <h1 className="w-3/4 lg:w-1/4 text-white text-xl font-third text-center">
                    This app contains NSFW content, your state does not allow usage without age verification.
                </h1>
            </div>
            <div className="flex items-center justify-center w-full bg-space-gray pt-8">
                <FontAwesomeIcon icon={faFaceDisappointed} size='4x' color="white"/>
            </div>

        </div>
        
    )
}