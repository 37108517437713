import axios from "axios";
import { UserSyncResult } from "./purchaseTier";

const USER_SYNC_ROUTE = "https://webhook.lucidai.art/syncUser";

export default async function syncUserTier(publicAddresss: string, jwtToken: string): Promise<UserSyncResult | null> {
    let result = null;
    let body = {
        userAddress: publicAddresss,
    }
    const headers = {
        'Authorization': `Bearer ${jwtToken}`
    };

    let response = null;
    try {
        response = await axios.post(USER_SYNC_ROUTE, body, { headers: headers });
    } catch (error) {
        result = {
            status: "ERROR",
            errorMessage: "There was a connection error, please try again."
        }
    }
    if (response !== null && response.data && response.data.status) {
        result = {
            status: response.data.status,
            errorMessage: response.data.error
        }
    }
    return result;
}