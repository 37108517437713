import { createSlice } from "@reduxjs/toolkit";
import { CHAIN_ID, Network } from "../../../models/network";

const maticContract = "";

const ethProvider = "https://eth.drpc.org"
const tierContract = "";
const presaleContract = "";

const maticNFTContract = ""
const tokenContract =""

const ethTokenContract = "0xA25031d21FB78aE17c86472823F6dd94507Fc6D6";

const initialNetworkChain: Network = {
    selectedChain: CHAIN_ID.ETH,
    selectedAccount: "",
    jwtToken: "",
    web3Host: ethProvider,
    contractAddress: maticContract,
    nftContractAddress: maticNFTContract,
    tokenContractAddress: tokenContract,
    tierContractAddress: tierContract,
    presaleContractAddress: presaleContract,
    ethTokenContractAddress: ethTokenContract,
    ipVerificationChecked: false,
    isNetworkCoolingDown: false
}

const networkSlice = createSlice({
    name: "network",
    initialState: initialNetworkChain,
    reducers: {
        switchNetworkChain: (state, action) => {
            return {...action.payload}
        },
        switchNetworkAccount: (state, action) => {
            state.selectedAccount = action.payload;
        },
        updateVerificationStatus: (state, action) => {
            state.ipVerificationChecked = action.payload
        },
        enableNetworkCooldown: (state) => {
            state.isNetworkCoolingDown = true;
        },
        disableNetworkCooldown: (state) => {
            state.isNetworkCoolingDown = false;
        }
    },
})

export function prodNetworkHelper(chainId: CHAIN_ID, account: string, token: string): Network {
    return {
        selectedChain: chainId,
        selectedAccount: account,
        jwtToken: token,
        web3Host: ethProvider,
        contractAddress: maticContract,
        nftContractAddress: maticNFTContract,
        tokenContractAddress: tokenContract,
        tierContractAddress: tierContract,
        presaleContractAddress: presaleContract,
        ethTokenContractAddress: ethTokenContract,
        ipVerificationChecked: true,
        isNetworkCoolingDown: false
    }
}


export function testNetworkHelper(chainId: CHAIN_ID, account: string, token: string): Network {
    return {
        selectedChain: chainId,
        selectedAccount: account,
        jwtToken: token,
        web3Host: ethProvider,
        contractAddress: maticContract,
        nftContractAddress: maticNFTContract,
        tokenContractAddress: tokenContract,
        tierContractAddress: tierContract,
        presaleContractAddress: presaleContract,
        ethTokenContractAddress: ethTokenContract,
        ipVerificationChecked: true,
        isNetworkCoolingDown: false
    }
}

export default networkSlice.reducer;
export const { 
    switchNetworkChain, 
    switchNetworkAccount, 
    updateVerificationStatus, 
    enableNetworkCooldown, 
    disableNetworkCooldown 
} = networkSlice.actions;