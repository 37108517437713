import { createSlice } from "@reduxjs/toolkit";

export enum TagBoxType {
    SEARCH,
    PROMPT,
    NEGATIVE_PROMPT
}

export interface TagHomeProps {
    title: string
    type: TagBoxType
}

export interface TagSearchState {
    tagSearchModalType: TagBoxType,
    promptPrefix: string,
    negativePromptPrefix: string,
    homeSearchPrefix: string,
    promptSuggestion: string,
    negativePromptSuggestion: string,
    homeSearchSuggestion: string,
    promptSearchResults: string[] 
    negativePromptSearchResults: string[]
    homeSearchResults: string[]
}

const initialTagSearchState: TagSearchState = {
   tagSearchModalType: TagBoxType.SEARCH,
   promptPrefix: "",
   negativePromptPrefix: "",
   homeSearchPrefix: "",
   promptSuggestion: "",
   negativePromptSuggestion: "",
   homeSearchSuggestion: "",
   promptSearchResults: [],
   negativePromptSearchResults: [],
   homeSearchResults: []
}

const tagSearchSlice = createSlice({
    name: "tagSearch",
    initialState: initialTagSearchState,
    reducers: {
        // modal viewing state
        updateTagSearchModalType: (state, action) => {
            state.tagSearchModalType = action.payload;
        },
        // input prefixes
        updatePromptPrefix : (state, action) => {
            state.promptPrefix = action.payload;
        },
        updateNegativePromptPrefix : (state, action) => {
            state.negativePromptPrefix = action.payload;
        },
        updateHomeSearchPrefix: (state, action) => {
            state.homeSearchPrefix = action.payload;
        },
        // input suggestions
        updatePromptSuggestion: (state, action) => {
            state.promptSuggestion = action.payload;
        },
        updateNegativePromptSuggestion: (state, action) => {
            state.negativePromptSuggestion = action.payload;
        },
        updateHomeSearchSuggestion: (state, action) => {
            state.homeSearchSuggestion = action.payload;
        },
        // search result tags
        updateHomeSearchResults: (state, action) => {
            state.homeSearchResults.push(action.payload)
        },
        updatePromptSearchResults: (state, action) => {
            state.promptSearchResults.push(action.payload)
        },
        updateNegativePromptSearchResults: (state, action) => {
            state.negativePromptSearchResults.push(action.payload)
        },
        clearAllPromptSearchResults: (state) => {
            state.promptSearchResults = []
            state.negativePromptSearchResults = []
        },
        clearHomeSearchResults: (state) => {
            state.homeSearchResults = []
        },
        clearAllSearchResults: (state) => {
            state.promptSearchResults = []
            state.negativePromptSearchResults = []
            state.homeSearchResults = []
        },
    }
})

export default tagSearchSlice.reducer;
export const { 
updateTagSearchModalType,
updateHomeSearchResults, 
updatePromptSearchResults, 
updateNegativePromptSearchResults,
clearAllPromptSearchResults,
updatePromptPrefix,
updateNegativePromptPrefix,
updateHomeSearchPrefix,
updatePromptSuggestion,
updateNegativePromptSuggestion,
updateHomeSearchSuggestion,
clearAllSearchResults } = tagSearchSlice.actions;