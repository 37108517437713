import { useDispatch } from "react-redux";
import { MODEL_VISIBILITY } from "../../../../../models/art/artModel"
import { updateModalState } from "../../../../../state/reducers/modal/modalReducer";
import { MODAL_TYPE } from "../../../../../models/modal";

export default function RequestModelCard(type: MODEL_VISIBILITY) {

    const dispatch = useDispatch();

    return (
        <div onClick={() => displayArtModelRequestModal()} 
             className="w-32 h-48 sm:h-48 sm:w-36 md:w-48 md:h-64 lg:h-72 lg:w-64 rounded-lg bg-gradient-to-r
                        from-white via-purple-300 to-teal-300
                        background-animate p-[2px] transform transition-all hover:scale-105">
            <div className="cursor-pointer flex w-full h-full bg-black justify-center items-center rounded-lg">
                <h1 className="text-white font-third text-center text-xs md:text-lg p-4">{DisplayPaneText()}</h1>
            </div>
        </div>
    )

    function displayArtModelRequestModal() {
        if (type === MODEL_VISIBILITY.BASE || type === MODEL_VISIBILITY.PUBLIC) {
            dispatch(updateModalState({type: MODAL_TYPE.MODEL_REQUEST, show: true, videoUrl: ""}));
        }
    }

    function DisplayPaneText() {
        if (type === MODEL_VISIBILITY.BASE) {
            return "Request Base Model"
        } else if (type === MODEL_VISIBILITY.PRIVATE) {
            return "Coming Soon"
        } else {
            return "Request Community Model"
        }
    }
}