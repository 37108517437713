import { POSITION } from "./roadmapBody"
import { useDispatch } from "react-redux";
import { updateModalState } from "../../state/reducers/modal/modalReducer";
import { MODAL_TYPE } from "../../models/modal";
import Lottie from "lottie-react";
import codeWindow from "../../lottieJson/codeWindow.json";
import play from "../../lottieJson/play.json";


export interface RoadmapCellData {
    timeframe: string,
    items: string[],
    position: POSITION,
    videoUrl: string,
    thumbnail: string
    chosen: boolean
}

function RoadmapCell(props: RoadmapCellData) {
    return (
        <>
        
            {props.position === POSITION.LEFT ? 
                <div className="md:flex w-full">
                    <RoadmapInfoSide{...props}/> 
                    <RoadmapGraphicSide{...props}/>
                </div>
                : 
                <>
                <div className="hidden md:flex w-full">
                    <RoadmapGraphicSide{...props}/>
                    <RoadmapInfoSide{...props}/> 
                </div>
                <div className="md:hidden">
                    <RoadmapInfoSide{...props}/> 
                    <RoadmapGraphicSide{...props}/>
                </div>
                </>

            }
        </>
    )
}

function RoadmapInfoSide(props: RoadmapCellData) {
    return (
        <>
        {props.position === POSITION.LEFT ?
            <div className="w-full md:w-1/2 md:border-r-2 border-white h-72 md:h-96">
                
                <div className="relative h-full w-full">
                    {/* Desktop cell to roadmap line connector */}
                    <div className="absolute top-0 right-0 w-1/2 h-1/2 md:border-b-4 md:border-white"></div>
                    {/* Mobile cell to roadmap line connector */}
                    <div className="absolute inset-y-0 left-0 w-1/2 border-r-4 border-white md:border-none"></div>

                    <div className="absolute inset-0 w-full h-full p-4 px-8 md:pt-16 md:pb-8">
                        <LeftInfoSide{...props}/>
                    </div>
                </div>
            </div> 
            : 
            <div className="w-full md:w-1/2 md:border-l-2 border-white h-72 md:h-96">
                <div className="relative h-full w-full">
                    {/* Desktop cell to roadmap line connector */}
                    <div className="absolute top-0 left-0 w-1/2 h-1/2 md:border-b-4 md:border-white"></div>
                    {/* Mobile cell to roadmap line connector */}
                    <div className="absolute inset-y-0 left-0 w-1/2 border-r-4 border-white md:border-none"></div>

                    <div className="absolute inset-0 w-full h-full py-4 px-8 md:pt-16 md:pb-8">
                        <RightInfoSide{...props}/>
                    </div>
                </div>

            </div>
        }
        </>
    )
}

function LeftInfoSide(props: RoadmapCellData) {
    if (props.chosen) {
        return (
            <div className="flex border-2 md:border-4 border-white rounded-lg w-full h-full bg-gradient-to-r
                            from-white via-purple-300 to-teal-300
                            background-animate p-1 shadow-xl">
                <div className="bg-black rounded-lg w-full h-full p-3">
                    <CellText{...props}/>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex md:justify-end w-full h-full border-2 md:border-4 border-white rounded-lg bg-black p-4">
                <CellText{...props}/>
            </div>
        )
    }
}

function RightInfoSide(props: RoadmapCellData) {
    if (props.chosen) {
        return (
            <div className="flex md:justify-end border-2 md:border-4 border-white rounded-lg w-full h-full bg-gradient-to-r
                            from-white via-purple-300 to-teal-300
                            background-animate p-1 shadow-xl">
                <div className="bg-black rounded-lg w-full h-full p-3">
                    <CellText{...props}/>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex border-2 md:border-4 border-white rounded-lg bg-black w-full h-full p-4">
                <CellText{...props}/>
            </div>
        )
    }

}

function RoadmapGraphicSide(props: RoadmapCellData) {
    return (
        <>
        {/*  */}
        {props.position === POSITION.LEFT ?
        <div className="md:flex md:w-1/2 md:border-l-2 border-white h-64 md:h-96">
            <div className="relative h-full w-full">

                {/* Desktop cell to roadmap line connector */}
                <div className="absolute top-0 left-0 w-1/2 h-1/2 md:border-b-4 md:border-white mt-14"></div>
                {/* Mobile cell to roadmap line connector */}
                <div className="absolute inset-y-0 left-0 w-1/2 border-r-4 border-white md:border-none"></div>

                <div className="absolute inset-0 w-full h-full py-4 px-16 md:pl-8 md:pr-28 md:pt-32 md:pb-8">
                    <CellGraphic{...props}/>
                </div>
            </div>
            
        </div> 
        : 
        <div className="md:flex md:w-1/2 md:border-r-2 border-white h-64 md:h-96">
            <div className="relative h-full w-full">
                {/* Desktop cell to roadmap line connector */}
                <div className="absolute top-0 right-0 w-1/2 h-1/2 md:border-b-4 md:border-white mt-14"></div>
                {/* Mobile cell to roadmap line connector */}
                <div className="absolute inset-y-0 left-0 w-1/2 border-r-4 border-white md:border-none"></div>

                <div className="absolute inset-0 w-full h-full py-4 px-16 md:pr-8 md:pl-28 md:pt-32 md:pb-8">
                    <CellGraphic{...props}/>
                </div>
            </div>
        </div>
        }
        </>
    )
}

function CellText(props: RoadmapCellData) {
    if (props.chosen) {
        return (
            <div className="w-full">
                <h1 className="font-primary text-lg md:text-2xl font-bold pb-1 text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">{props.timeframe}</h1>
                {props.items.map(bullet => 
                    <p className="flex font-third text-md xl:text-lg py-1 md:py-2 text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black"><span className="font-bold mr-2"></span>{bullet}</p>
                )}
            </div>
        )
    } else {
        return (
            <div className="w-full">
                <h1 className="font-primary text-lg md:text-2xl font-bold pb-1 text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">{props.timeframe}</h1>
                {props.items.map(bullet => 
                    <p className="flex text-white font-third text-md xl:text-lg py-1 md:py-2"><span className="font-bold mr-2"></span>{bullet}</p>
                )}
            </div>
        )
    }
}

function CellGraphic(props: RoadmapCellData) {
    const dispatch = useDispatch();
    return (
        <>
        {props.videoUrl === "" ?
            <div className="w-full h-full bg-space-gray rounded-lg border-2 border-white">
                <div className="flex w-full h-full justify-center items-center">
                    <div className="">
                        <h1 className="font-bold font-primary text-center text-white text-1xl -mb-4">In Progress</h1>
                        <Lottie animationData={codeWindow} loop={true} className="h-36 w-36"></Lottie>
                    </div> 
                </div>
            </div>            
        :
            <button onClick={() => handleVideoClick(dispatch, props.videoUrl)} className="flex justify-center items-center w-full h-full bg-black rounded-lg border-2 border-white transform transition-all hover:scale-105">
                <div  className="flex justify-center items-center w-full h-full bg-black rounded-lg">
                    <img className="flex w-full h-full object-cover items-center justify-center rounded-lg" src={props.thumbnail} alt=""></img>  
                </div>
                <div className="absolute inset-x-0 bottom-0 left-0 right-0 top-0">
                    <div className="flex w-full h-full justify-center items-center">
                        <div className="w-32 h-32">
                        <Lottie animationData={play} loop={true} className="h-full w-full"></Lottie>
                        </div>
                    </div>
                </div>
            </button>
        }
        </>
    )
}

function handleVideoClick(dispatch: any, videoUrl: string) {
    dispatch(updateModalState({type: MODAL_TYPE.VIDEO, show: true, videoUrl: videoUrl}));
}

export default RoadmapCell;

