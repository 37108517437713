import axios from "axios";
import Web3 from "web3";
import { AbiItem, toWei, toHex } from "web3-utils";
import { PRESALE_SLOT } from "../../components/token/presale/tokenPresaleHome";
import HandleAlert, { errorAlert, successAlert } from "../../utils/handleAlert";
import { PresalePurchasingStatus } from "../../state/reducers/presale/presaleReducer";
import { updatePresaleStatus } from "../../state/reducers/presale/presaleReducer";
import presaleABI from "../../abis/test_Presale.json"
import { GasEstimate, PurchaseReceipt } from "../tier/purchaseTier";
import { Network } from "../../models";
import { getReceipt } from "../tier/purchaseTier";
import { validatePresaleDataForUser } from "./presaleData";

const VALIDATE_USER_ROUTE = "https://webhook.lucidai.art/validatePresaleUser";
const CACHE_USER_ROUTE = "https://webhook.lucidai.art/cachePresaleUser";
export const PRESALE_CONTRACT_ADDRESS = "0x5BbC03aBa6Ca8B87C67cD1e78420447f5D0A2832"


export async function purchaseSlot(slot: PRESALE_SLOT, user: string, network: Network, dispatch: any) {
    if (await alreadyParticipated(user, network.jwtToken)) {
        updatePresaleStatusHelper(PresalePurchasingStatus.ALREADY_PARTICIPATED, "You've already participated. Changing wallets will not work.", dispatch);
        HandleAlert(errorAlert("You've already participated. Changing wallets will not work."), dispatch);
        return;
    }

    let presaleContractInstance = null;
    let web3Connection = null;

    updatePresaleStatusHelper(PresalePurchasingStatus.ESTABLISHING_CONNECTION, "", dispatch);
    try {
        //@ts-ignore
        web3Connection = new Web3((network.web3Host));
        presaleContractInstance = new web3Connection.eth.Contract(presaleABI as AbiItem[], PRESALE_CONTRACT_ADDRESS);
    } catch (e) {
        console.log(e);
        updatePresaleStatusHelper(PresalePurchasingStatus.ERROR, "Refresh, check selected MetaMask network, log back in, and re-try.", dispatch);
        HandleAlert(errorAlert("An issue went wrong establishing Web3 connection."), dispatch);
        return;
    }

    let gasEstimateObject = await estimateSlotPurchaseGas(slot, presaleContractInstance, web3Connection, network.selectedAccount);
    if (gasEstimateObject.estimate === null || gasEstimateObject.errorMessage !== null) {
        if (gasEstimateObject.errorMessage !== null) {
            let errorMessage = gasEstimateObject.errorMessage.includes("insufficient funds for gas") ? "Insufficient amount of ETH in your wallet. Refresh & log back in with another account." : gasEstimateObject.errorMessage;
            updatePresaleStatusHelper(PresalePurchasingStatus.ERROR, errorMessage, dispatch);
            HandleAlert(errorAlert("Issue with your wallet."), dispatch);
            return;
        } else {
            updatePresaleStatusHelper(PresalePurchasingStatus.ERROR, "An issue went wrong estimating gas. Refresh, log in, and re-try.", dispatch);
            HandleAlert(errorAlert("An issue went wrong estimating gas"), dispatch);
            return;
        } 
    }

    let slotReceipt = await purchaseSlotWeb3(slot, network, presaleContractInstance, web3Connection, gasEstimateObject.estimate, dispatch);
    if (slotReceipt.receipt !== null) {
        updatePresaleStatusHelper(PresalePurchasingStatus.ALREADY_PARTICIPATED, "", dispatch);
        HandleAlert(successAlert("You are now a participant."), dispatch);
        await cachePresaleUser(user, network.jwtToken, network.selectedAccount);
        await validatePresaleDataForUser(network, dispatch);
        return;
    }

    if (slotReceipt.errorMessage !== null) {
        updatePresaleStatusHelper(PresalePurchasingStatus.STANDBY, slotReceipt.errorMessage, dispatch);
        HandleAlert(errorAlert("Something went wrong. Please refresh, log in, and try again."), dispatch);
        return;
    }
}

export async function purchaseSlotWeb3(slot: PRESALE_SLOT, networkState: Network, presaleContractInstance: any, web3Connection: any, gasEstimate: any, dispatch: any): Promise<PurchaseReceipt> {
    let errorMessage = null;
    let purchaseHash = null;
    let purchaseReceipt = null;

    let ethValueString = getEthAmount(slot);

    try {
        const gasEstimateNumber = parseInt(gasEstimate.toString());
        gasEstimate = Math.round(gasEstimateNumber * 1.2);
    } catch (error) {
        console.log("Error estimating gas:" + error);
        return;
    }

    if (gasEstimate === null || gasEstimate === null) {
        console.log("Error estimating gas: some value is null");
        return;
    }
    
    console.log("gas: " + gasEstimate);

    const purchaseSlotTransactionObject = presaleContractInstance.methods.participate(slot).encodeABI();
    let transactionParameters = {
        from: networkState.selectedAccount,
        to: PRESALE_CONTRACT_ADDRESS,
        value: toHex(ethValueString),
        data: purchaseSlotTransactionObject,
        gas: toHex(gasEstimate)
    };

    // PURCHASING TIER
    updatePresaleStatusHelper(PresalePurchasingStatus.AWAITING_PURCHASE_CONFIRMTION, "", dispatch);
    try {
        //@ts-ignore
        purchaseHash = await window.ethereum.request({
            //@ts-ignore
            method: 'eth_sendTransaction',
            //@ts-ignore
            params: [transactionParameters],
        })
    } catch (error) {
        console.log(error);
        return {
            receipt: null,
            errorMessage: error.message
        }
    }

    if (purchaseHash === null || errorMessage !== null) {
        return {
            receipt: null,
            errorMessage: "Error handling purchase request. Please try again."
         } 
    } 

    // PURCHSAE RECEIPT WAITING
    updatePresaleStatusHelper(PresalePurchasingStatus.AWAITING_PURCHASE_RECEIPT, "", dispatch);
    purchaseReceipt = await getReceipt(purchaseHash);
    if (purchaseReceipt === null) {
        return {
            receipt: null,
            errorMessage: "Error fetching receipt. Please log back in to check tier status."
        }
    }
    return {
        receipt: purchaseReceipt,
        errorMessage: null
    };
}

export async function estimateSlotPurchaseGas(slot: PRESALE_SLOT, presaleContractInstance: any, web3Connection: any, spenderAddress: string): Promise<GasEstimate> {
    const purchaseSlotTransactionObject = presaleContractInstance.methods.participate(slot).encodeABI();
    const ethValue = getEthAmount(slot);
    let gasEstimate = null;
    let errorMessage = null;
    try {
        gasEstimate = await web3Connection.eth.estimateGas({
            from: spenderAddress,
            to: PRESALE_CONTRACT_ADDRESS,
            value: toHex(ethValue),
            data: purchaseSlotTransactionObject,
        });
    } catch (error) {
        errorMessage = error.message
    }
    return {
        errorMessage: errorMessage,
        estimate: gasEstimate
    };
}

export async function alreadyParticipated(user: string, jwtToken: string): Promise<boolean> {
    let response = null;
    let params = {
        user: user
    }
    const headers = {
        'Authorization': `Bearer ${jwtToken}`
    };

    try {
        response = await axios.get(VALIDATE_USER_ROUTE, {params: params, headers: headers});
    } catch (error) {
        return false;
    }
    // means the user is not in our database yet
    return response && response.data && response.data.status !== "SUCCESS";
}

export async function cachePresaleUser(user: string, jwtToken: string, userAddress: string): Promise<boolean> {
    console.log(user);
    let response = null;
    let body = {
        user: user,
        userAddress: userAddress
    }
    const headers = {
        'Authorization': `Bearer ${jwtToken}`
    };
    try {
        response = await axios.post(CACHE_USER_ROUTE, body, {headers: headers});
    } catch (error) {
        return false;
    }
    return response && response.data && response.data.status === "SUCCESS";
}

export function updatePresaleStatusHelper(statusType: PresalePurchasingStatus, errorMessage: string, dispatch: any) {
    dispatch(updatePresaleStatus({status: statusType, errorMessage: errorMessage}));
}

function getEthAmount(slot: PRESALE_SLOT): string {
    if (slot === PRESALE_SLOT.ONE) {
        return Number(0.05 * 1e18).toString(16)
    } else if (slot === PRESALE_SLOT.TWO) {
        return Number(0.1 * 1e18).toString(16)
    } else if (slot === PRESALE_SLOT.THREE) {
        return Number(0.2 * 1e18).toString(16)
    } else {
        return "";
    }
}