import { createSlice } from "@reduxjs/toolkit";
import { AlertType } from "../../../models/alert/alert"

const initialAlertState = {
    type: AlertType.SUCCESS,
    show: false,
    message: "",
    url: ""
}

const alertSlice = createSlice({
    name: "alert",
    initialState: initialAlertState,
    reducers: {
        updateAlertStatus: (state, action) => {
            return {...action.payload}
        }
    }
})

export default alertSlice.reducer;
export const { updateAlertStatus } = alertSlice.actions;