import { useDispatch } from "react-redux"
import { useAppSelector } from "../../state/hooks";
import { ArtModel, GENERATIVE_TYPE, MODEL_TYPE } from "../../models/art/artModel";
import { 
    updateLoraSelection, 
    updateModelSelection 
} from "../../state/reducers/artCreation/artCreationFilterReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faShield, faImage } from '@fortawesome/pro-regular-svg-icons';

export default function SelectorModelCard(model: ArtModel) {

    const dispatch = useDispatch();
    const artFilterState = useAppSelector(state => state.artCreationFilter);

    return (
        <button onClick={() => updateModelSelections(model)} className={modelClassName(model)}>
            <div className="flex items-center justify-center w-full h-full rounded-lg bg-black">
                <div className="w-full h-full">
                
                    <div className="flex h-3/4 w-full p-1">
                        <div className="relative w-full h-full rounded-lg">
                            <div className="absolute inset-0 rounded-lg">
                                <img className="w-full h-full rounded-lg object-cover" alt="" src={model.imageUrl}></img>
                            </div>
                            <div className="absolute inset-x-0 bottom-0 h-8">
                                <div className="flex w-full h-full items-center justify-between"> 
                                    {NSFWIcon(model)}
                                    {modelGenerativeTypeIcon(model)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center h-1/4 w-full p-1">
                        <h1 className={modelTextClass(model)}>
                            {model.name}
                        </h1>
                    </div>

                </div>
            </div>
        </button>
    )

    function NSFWIcon(model: ArtModel) {
        if (model.nsfw) {
            return (
                <div className="flex items-center bg-black rounded-md py-[1px] px-[4px] ml-1">
                    <FontAwesomeIcon icon={faCircleExclamation} className="mr-1 mb-[1px]" size="xs" color="white"/>
                    <h1 className="text-white font-third text-sm">NSFW</h1>
                </div>
            )
        } else {
            return (
                <div className="flex items-center bg-black rounded-md py-[1px] px-[4px] ml-1">
                    <FontAwesomeIcon icon={faShield} className="mr-1 mb-[1px]" size="xs" color="white"/>
                    <h1 className="text-white font-third text-sm">SFW</h1>
                </div>
            )
        }
    }

    function modelGenerativeTypeIcon(model: ArtModel) {
        if (model.generativeType === GENERATIVE_TYPE.IMAGE) {
            return (
                <div className="flex items-center mr-[5px] md:mr-[6px] mb-[6px]">
                    <FontAwesomeIcon icon={faImage} className="bg-black rounded-lg px-[3px] py-[1px]" size="2x" color="white"/>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function updateModelSelections(model: ArtModel) {
        // BASE MODELS
        if (model.modelType === MODEL_TYPE.BASE) {
            dispatch(updateModelSelection(model.name.toLowerCase()));
        // LORA MODELS
        } else {
            if ((model.nsfw && artFilterState.nsfw) || (!model.nsfw && !artFilterState.nsfw)) {
                dispatch(updateLoraSelection(model.name.toLowerCase()));
            }
        }
    }

    function modelClassName(model: ArtModel) {
         if (model.modelType === MODEL_TYPE.BASE) {
            return selectedBaseModelClassName(model.name)
         } else {
            return loraCardClass(model)
         }
    }

    function modelTextClass(model: ArtModel) {
        if (model.modelType === MODEL_TYPE.BASE) {
            return selectedBaseModelTextClass(model.name);
        } else {
            return loraTextClass(model);
        }
    }

    function selectedBaseModelClassName(modelName: string) {
        if (artFilterState.model.toLowerCase() === modelName.toLowerCase()) {
            return "w-32 h-48 md:w-48 md:h-64 bg-gradient-to-r from-pink-500 via-red-300 to-red-600 background-animate p-[2px] rounded-lg"
        } else {
            return "w-32 h-48 md:w-48 md:h-64 bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px] rounded-lg"
        }
    }

    function selectedBaseModelTextClass(modelName: string) {
        if (artFilterState.model.toLowerCase() === modelName.toLowerCase()) {
            return "text-xs md:text-md font-secondary text-animate bg-gradient-to-r from-pink-500 via-red-300 to-red-600 bg-clip-text text-transparent font-black"
        } else {
            return "text-xs md:text-md font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black"
        }
    }

    function loraCardClass(lora : ArtModel) {
        if (lora.nsfw && artFilterState.nsfw && artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "w-32 h-48 md:w-48 md:h-64 bg-gradient-to-r from-pink-500 via-red-300 to-red-600 background-animate p-[2px] rounded-lg"
        }
        if (lora.nsfw && artFilterState.nsfw && !artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "w-32 h-48 md:w-48 md:h-64 bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px] rounded-lg"
        }
        if (lora.nsfw && !artFilterState.nsfw) {
            return "cursor-not-allowed w-32 h-48 md:w-48 md:h-64 bg-gray-500 p-[2px] rounded-lg"
        }
        if (!lora.nsfw && !artFilterState.nsfw && artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "w-32 h-48 md:w-48 md:h-64 bg-gradient-to-r from-pink-500 via-red-300 to-red-600 background-animate p-[2px] rounded-lg"
        }
        if (!lora.nsfw && !artFilterState.nsfw && !artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "w-32 h-48 md:w-48 md:h-64 bg-gradient-to-r from-white via-purple-300 to-teal-300 background-animate p-[2px] rounded-lg"
        }
        if (!lora.nsfw && artFilterState.nsfw) {
            return "cursor-not-allowed w-32 h-48 md:w-48 md:h-64 bg-gray-500 p-[2px] rounded-lg"
        }
    }

    function loraTextClass(lora : ArtModel) {
        if (lora.nsfw && artFilterState.nsfw && artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "text-xs md:text-md font-secondary text-animate bg-gradient-to-r from-pink-500 via-red-300 to-red-600 bg-clip-text text-transparent font-black"
        }
        if (lora.nsfw && artFilterState.nsfw && !artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "text-xs md:text-md font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black"
        }
        if (lora.nsfw && !artFilterState.nsfw) {
            return "text-xs md:text-md font-secondary text-gray-500"
        }
        if (!lora.nsfw && !artFilterState.nsfw && artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "text-xs md:text-md font-secondary text-animate bg-gradient-to-r from-pink-500 via-red-300 to-red-600 bg-clip-text text-transparent font-black"
        }
        if (!lora.nsfw && !artFilterState.nsfw && !artFilterState.loras.includes(lora.name.toLowerCase())) {
            return "text-xs md:text-md font-secondary text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black"
        }
        if (!lora.nsfw && artFilterState.nsfw) {
            return "text-xs md:text-md font-secondary text-gray-500"
        }
    }
}