import { CHAIN_ID, Network } from "../models";
import { Alert, AlertType } from "../models/alert/alert";
import { updateAlertStatus } from "../state/reducers/alert/alertReducer";

function HandleAlert(alert: Alert, dispatch: any) {

    dispatch(updateAlertStatus(alert));

    var counter = 2;

    let intervalId = setInterval(() => {
        counter = counter - 1;
        
        if (counter === 0) {
            
            const resetAlert = {
                type: alert.type,
                show: false,
                message: alert.message,
                url: ""
            }
            dispatch(updateAlertStatus(resetAlert));
            clearInterval(intervalId);
        } 
    }, 2000)
}

export function accountLinkSuccess(body: string): Alert {
    return {
        type: AlertType.LINK_SUCCESS,
        show: true,
        message: body,
        url: ""
    }
}

export function successAlert(body: string): Alert {
    return {
        type: AlertType.SUCCESS,
        show: true,
        message: body,
        url: ""
    }
}

export function trxMintAlert(body: string, url: string, networkState: Network): Alert {
    return {
        type: AlertType.TRX_MINTED,
        show: true,
        message: body,
        url: url
    }
}


export function errorAlert(body: string): Alert {
    return {
        type: AlertType.ERROR,
        show: true,
        message: body,
        url: ""
    }
}

export function warningAlert(body : string, url: string): Alert {
    return {
        type: AlertType.WARNING,
        show: true,
        message: body,
        url: url
    }
}

function urlScanHelper(hash: string, network: Network) {
    // TODO: Update to prod scanners 
    if (network.selectedChain === CHAIN_ID.MATIC) {
        return "https://polygonscan.com/tx/" + hash;
    } else {
        return "https://polygonscan.com/tx/" + hash;
    }
    
}

export default HandleAlert;