import {useState } from "react";
import { NavLink } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../state/hooks";
import { updateModalState } from "../../../state/reducers/modal/modalReducer";
import { MODAL_TYPE } from "../../../models/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router";
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons';
import { faTimer, faAngleUp, faAngleDown } from '@fortawesome/pro-solid-svg-icons'
import { Transition } from "@headlessui/react";
import { handleMissingMetamask, logout } from "../../../networking/user";
import SolidHouse from "./mobileButtons/solidHouse";
import LightHouse from "./mobileButtons/lightHouse";
import LightProfile from "./mobileButtons/lightProfile";
import SolidProfile from "./mobileButtons/solidProfile";
import SolidToken from "./mobileButtons/solidToken";
import LightToken from "./mobileButtons/lightToken";
import SolidAbout from "./mobileButtons/solidAbout";
import LightAbout from "./mobileButtons/lightAbout";
import LightGroup from "./mobileButtons/lightGroup";
import SolidGroup from "./mobileButtons/solidGroup";
import SolidEth from "./mobileButtons/solidEth";
import LightEth from "./mobileButtons/lightEth";
import { clearSearchTags, toggleAscendingFilter } from "../../../state/reducers/collection/homeCollectionReducer";
import { loadHomeCollection } from "../../../networking/art";

function NavigationBar() {

    const dispatch = useDispatch();
    const location = useLocation();

    const [navigation, setNavigation] = useState(false);

    const networkState = useAppSelector(state => state.network);
    const modalState = useAppSelector(state => state.modal);
    const homeCollectionState = useAppSelector(state => state.homeCollection);
 
    const toggleNav = () => {
        if (window.scrollY >= 250) {
          setNavigation(true);
        } else {
          setNavigation(false);
        }
    }

    window.addEventListener('scroll', toggleNav);

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    
    let AIlogoClass = "";
    let lucidLogoClass = "";
    if (isFirefox) {
        lucidLogoClass = "text-white mr-1 pt-[3px]"
        AIlogoClass = "font-primary text-white pl-1 pr-[6px] align-text-bottom pt-[3px] rounded-lg bg-black"
    } else {
        lucidLogoClass ="text-white mr-1"
        AIlogoClass = "font-primary text-white pl-1 pr-[6px] rounded-lg bg-black"
    }

    return (
      <>
      <nav className={(location.pathname !== "/unverified" && location.pathname !== "/verifying") ? 'hidden lg:flex justify-between py-6 px-8 z-50 bg-black' : 'hidden'} >
        <div className="flex items-center space-x-4">
            <NavLink to="/" className="font-primary flex items-center text-center text-xl mb-[3px]">
              <h1 className={lucidLogoClass}>LUCID</h1>
              <div className="text-gray-300 p-[1px] rounded-lg 
                bg-gradient-to-r
                from-white via-purple-300 to-teal-300
                background-animate"
              >
                <h1 className={AIlogoClass}>AI</h1>
              </div>
            </NavLink>
            <NavLink to="/profile" className="font-primary text-gray-300 hover:text-white text-sm relative before:content-[''] before:absolute before:block before:w-full before:h-[1px] 
              before:bottom-0 before:left-0 before:bg-white
              before:hover:scale-x-100 before:scale-x-0 before:origin-top-left
              before:transition before:ease-in-out before:duration-300">Profile
            </NavLink>
            <NavLink to="/community" className="font-primary text-gray-300 hover:text-white text-sm relative before:content-[''] before:absolute before:block before:w-full before:h-[1px] 
              before:bottom-0 before:left-0 before:bg-white
              before:hover:scale-x-100 before:scale-x-0 before:origin-top-left
              before:transition before:ease-in-out before:duration-300">
                Communities
            </NavLink>
            <NavLink to="/pricing" className="font-primary text-gray-300 hover:text-white text-sm relative before:content-[''] before:absolute before:block before:w-full before:h-[1px] 
              before:bottom-0 before:left-0 before:bg-white
              before:hover:scale-x-100 before:scale-x-0 before:origin-top-left
              before:transition before:ease-in-out before:duration-300">
                Pricing
            </NavLink>
            <NavLink to="/token" className="font-primary text-gray-300 hover:text-white text-sm relative before:content-[''] before:absolute before:block before:w-full before:h-[1px] 
              before:bottom-0 before:left-0 before:bg-white
              before:hover:scale-x-100 before:scale-x-0 before:origin-top-left
              before:transition before:ease-in-out before:duration-300">
                Token
            </NavLink>
            <NavLink to="/about" className="font-primary text-gray-300 hover:text-white text-sm relative before:content-[''] before:absolute before:block before:w-full before:h-[1px] 
              before:bottom-0 before:left-0 before:bg-white
              before:hover:scale-x-100 before:scale-x-0 before:origin-top-left
              before:transition before:ease-in-out before:duration-300">
                About
            </NavLink>
        </div>
        <div className="flex items-center">
          <button onClick={() => handleSignInClick()} className="
              relative rounded 
              w-32 px-2 py-2
              overflow-hidden 
              group bg-space-gray
              relative hover:bg-gradient-to-r 
              hover:from-gray-800 hover:to-gray-900 
              text-white
              font-primary 
              hover:ring-2 
              hover:ring-offset-2 
              hover:ring-gray-900 
              transition-all 
              ease-out 
              duration-300"
              data-bs-toggle="modal" data-bs-target="#eventQueueCenter"
              >
              <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
              <span className="text-sm">{(networkState.selectedAccount !== "") ? <>{shortenAddress(networkState.selectedAccount)}</> : <>Sign in</>}</span>
          </button>
        </div>
      </nav>

      {/* Mobile Top */}
      <Transition
        show={navigation}
        enter="transition-opacity duration-250"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-250"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >      
        <nav className={navigation && !modalState.show && location.pathname === "/" ?  "lg:hidden fixed top-0 w-full py-4 px-6 z-50 backdrop-blur-2xl bg-black/70" : "hidden"}>
          <div className="flex justify-end mx-auto">
            <button onClick={() => displayFilters()} className="flex">
                <FontAwesomeIcon icon={faGrid2} color='white' size="lg"/>
            </button>
            <button onClick={() => ascDescToggle()} className="flex ml-2">
                <FontAwesomeIcon icon={faTimer} color='white' size="lg" className="mr-2"/>
                {
                    homeCollectionState.asc ? <FontAwesomeIcon icon={faAngleUp} color='white' size="lg"/> : <FontAwesomeIcon icon={faAngleDown} color='white' size="lg"/>
                } 
            </button>
          </div>
        </nav>
      </Transition>

      {/* Mobile bottom */}
      <div className={(location.pathname !== "/unverified" && location.pathname !== "/verifying") ? 'lg:hidden fixed inset-x-0 bottom-0 z-50 h-20 backdrop-blur-2xl bg-black/70' : 'hidden'}>
        <div className="flex w-full h-full">
            <div className="w-1/5 h-full">
              <NavLink to="/" className="flex justify-center items-center w-full h-full">
                {location.pathname === '/' ? <SolidHouse/> : <LightHouse/>}
              </NavLink>
            </div>
            <div className="w-1/5 h-full">
              <NavLink to="/profile" className="flex justify-center items-center w-full h-full">
                {location.pathname === '/profile' ? <SolidProfile/> : <LightProfile/>}
              </NavLink>
            </div>
            
            <div className="w-1/5 h-full">
              <NavLink to="/community" className="flex justify-center items-center w-full h-full">
                {location.pathname === '/community' ? <SolidGroup/> : <LightGroup/>}
              </NavLink>
            </div>
            <div className="w-1/5 h-full">
              <NavLink to="/token" className="flex justify-center items-center w-full h-full">
                {location.pathname === '/token' ? <SolidEth/> : <LightEth/>}
              </NavLink>
            </div>
            <div className="w-1/5 h-full">
              <NavLink to="/pricing" className="flex justify-center items-center w-full h-full">
                {location.pathname === '/pricing' ? <SolidToken/> : <LightToken/>}
              </NavLink>
            </div>
            <div className="w-1/5 h-full">
              <NavLink to="/about" className="flex justify-center items-center w-full h-full">
                {location.pathname === '/about' ? <SolidAbout/> : <LightAbout/>}
              </NavLink>
            </div>
        </div>
      </div>
    
      </>
    );

    function handleSignInClick() {
      // @ts-ignore
      if (window.ethereum === undefined) {
        handleMissingMetamask(dispatch);
      // @ts-ignore
      } else if (window.ethereum !== undefined && networkState.selectedAccount === "") {
        dispatch(updateModalState({type: MODAL_TYPE.SIGN_IN, show: true}));
      } else {
        logout(dispatch);
      }
    }

    function displayFilters() {
      dispatch(updateModalState({type: MODAL_TYPE.TAGS, show: true, videoUrl: ""}));
    }

    function ascDescToggle() {
      dispatch(clearSearchTags());
      dispatch(toggleAscendingFilter(homeCollectionState.asc === false ? true : false))
      loadHomeCollection(dispatch, "", !homeCollectionState.asc, true);
    }

    function shortenAddress(account: string) {
      return account.substring(0,3) + "..." + account.substring(account.length - 3, account.length);
    }


}

export default NavigationBar;