import axios from "axios";
import { Network } from "../../models";
import { TierLevel } from "../../models/tier/tier";
import { UserVerificationResult } from "./purchaseTier";

const TIER_PURCHASE_ROUTE = "https://webhook.lucidai.art/purchaseTier";

export async function verifyAndUpdateUserTier(hash: string, network: Network, tierLevel: TierLevel): Promise<UserVerificationResult> {
    let result = {
        status: "",
        errorMessage: ""
    };
    let body = {
        userAddress: network.selectedAccount,
        tierName: tierLevel.name,
        hash: hash
    }
    console.log("attempting to update API");
    const headers = {
        'Authorization': `Bearer ${network.jwtToken}`
    };

    let response = null;
    try {
        response = await axios.post(TIER_PURCHASE_ROUTE, body, { headers: headers });
    } catch (error) {
        result = {
            status: "ERROR",
            errorMessage: "There was a connection error, please try again."
        }
    }
    console.log(response);
    if (response !== null && response.data && response.data.status) {
        result = {
            status: response.data.status,
            errorMessage: response.data.error
        }
    }
    return result;
}

