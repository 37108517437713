import { Tier, TierType } from "../../../models/tier/tier";
import { createSlice } from "@reduxjs/toolkit";


export enum TierPurchasingStatus {
    STANDBY,
    ERROR,
    ESTABLISHING_CONNECTION,
    CHECKING_BALANCE,
    AWAITING_APPROVAL_CONFIRMATION,
    AWAITING_APPROVAL_RECEIPT,
    ESTIMATING_PURCHASE_GAS,
    PURCHASING_TIER,
    AWAITING_PURCHASE_CONFIRMTION,
    AWAITING_PURCHASE_RECEIPT
}

export interface PurchaseStatus {
    errorMessage: string
    status: TierPurchasingStatus
}

export interface TierState {
    tiers: Tier[]
    purchaseStatus: PurchaseStatus
    initialLoad: boolean
}


export const initialTierState: TierState = { 
    initialLoad: false,
    purchaseStatus: {
        errorMessage: "",
        status: TierPurchasingStatus.STANDBY,
    },
    tiers:
    [
        {
            type: TierType.INDIVIDUAL,
            href: '#',
            tierLevels: [],
            description: 'Users who want to generate art in DMs',
            includedFeatures: ['All generative service features', 'Request custom models'],
        },
        {
            type: TierType.COMMUNITY,
            href: '#',
            tierLevels: [],
            description: 'Users managing public or private communities',
            includedFeatures: [
            'All generative service features',
            'Request custom models',
            ],
        }
    ]
}

const tierStateReducer = createSlice({
    name: "tiers",
    initialState: initialTierState,
    reducers: {
        updateTierCommunityLevels: (state, action) => {
            state.initialLoad = true
            let communityFilter = (tier: Tier) => tier.type === TierType.COMMUNITY;
            const communityTier = state.tiers.find(communityFilter);
            if (communityTier) {
                communityTier.tierLevels = action.payload.communnityTierLevels;
            }
        },
        updateTierPurchasingStatus: (state, action) => {
            state.purchaseStatus = action.payload
        }
    }
});

export default tierStateReducer.reducer;
export const { 
    updateTierCommunityLevels,
    updateTierPurchasingStatus
} = tierStateReducer.actions;